import { useCallback, useEffect, useState, useRef } from 'react';
import {
  Button,
  FormGroup,
  Intent,
  Checkbox,
  Alignment,
  EditableText,
  Tree,
  TreeNodeInfo,
} from '@blueprintjs/core';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { get, find } from 'lodash';
import {
  useBlocker,      // eslint-disable-line camelcase
  BlockerFunction, // eslint-disable-line camelcase
} from 'react-router-dom';

import {
  Sim,
  useUpdateSimMutation,
  UpdateSimInput,
  useSimStatesQuery,
  SimStates,
} from 'graphql/generated/graphql';
import RHFNumericInput from 'components/RHFInputs/NumericInput';
import RHFTextInput from 'components/RHFInputs/TextInput';
import RHFSelect from 'components/RHFInputs/Select';
import Accordion from 'components/Accordion';
import AppToaster from 'helpers/toaster';
import styles from '../index.module.css';
import { SimulatorSim } from '../types';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';

interface Props {
  sim: Sim;
}

const nodes: TreeNodeInfo[] = [
  {
    id: 0,
    hasCaret: false,
    label: 'Config',
  },
  {
    id: 1,
    hasCaret: false,
    label: 'Sim States',
  },
  {
    id: 2,
    hasCaret: false,
    label: 'Output',
  },
];

export default (props: Props) => {
  const defaultSimulatorSim: SimulatorSim = props.sim;
  const [simStateOptions, setSimStateOptions] = useState<{ label: string, value: number }[]>([]);
  const [simStates, setSimStates] = useState<SimStates[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const form = useForm<Partial<SimulatorSim>>({
    defaultValues: {
      ...defaultSimulatorSim,
    },
  });
  const { control, watch, setValue, formState: { errors, isDirty }, reset } = form;
  const simStateId = watch('sim_states_id', props.sim.sim_states_id);
  const stateOptionId = watch('state_option_id', props.sim.state_option_id);
  const overrideEnvTrafficWind = watch('data.enable_traffic_wind');

  const [updateSim] = useUpdateSimMutation();

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setValue('state_option_id', null, { shouldDirty: true });
    }
  }, [simStateId]);

  useEffect(() => {
    // Reset the form with default values
    reset({ ...defaultSimulatorSim });
  }, [defaultSimulatorSim]);

  useEffect(() => {
    if (isDirty) {
      addWarningListener();
    } else {
      removeWarningListener();
    }
  }, [isDirty]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  useBlocker(blockerFunc);

  useSimStatesQuery({
    onCompleted: data => {
      setSimStates(data.simStates);
      const stateOptions = data.simStates.map(item => ({ label: item.desc, value: item.id }));
      setSimStateOptions(stateOptions);
    },
  });

  useEffect(() => {
    const currentSimStates = find(simStates, (o) => (o.id === simStateId));
    const selectedState = find(currentSimStates?.states, (o) => (o.id === stateOptionId));

    if (selectedState) {
      const tireData = selectedState.tire;
      const brakeData = selectedState.brake;
      const fuelData = selectedState.fuel;

      setValue('data.global_mu', tireData?.global_mu, { shouldDirty: true });
      setValue('data.build_lf', tireData?.pressure_build_lf, { shouldDirty: true });
      setValue('data.build_rf', tireData?.pressure_build_rf, { shouldDirty: true });
      setValue('data.build_lr', tireData?.pressure_build_lr, { shouldDirty: true });
      setValue('data.build_rr', tireData?.pressure_build_rr, { shouldDirty: true });
      setValue('data.temps_lf', tireData?.temperature_lf, { shouldDirty: true });
      setValue('data.temps_rf', tireData?.temperature_rf, { shouldDirty: true });
      setValue('data.temps_lr', tireData?.temperature_lr, { shouldDirty: true });
      setValue('data.temps_rr', tireData?.temperature_rr, { shouldDirty: true });
      setValue('data.fuel_burn', fuelData?.burn, { shouldDirty: true });
      setValue('data.water_temp', selectedState.engine?.water_temp, { shouldDirty: true });
      setValue('data.alternator_current', selectedState.engine?.alternator_current, { shouldDirty: true });
      setValue('data.rotor_temperature_lf', brakeData?.rotor_temperature_lf, { shouldDirty: true });
      setValue('data.rotor_temperature_rf', brakeData?.rotor_temperature_rf, { shouldDirty: true });
      setValue('data.rotor_temperature_lr', brakeData?.rotor_temperature_lr, { shouldDirty: true });
      setValue('data.rotor_temperature_rr', brakeData?.rotor_temperature_rr, { shouldDirty: true });
      setValue('data.rotor_temperature_filtered_lf', brakeData?.rotor_temperature_filtered_lf, { shouldDirty: true });
      setValue('data.rotor_temperature_filtered_rf', brakeData?.rotor_temperature_filtered_rf, { shouldDirty: true });
      setValue('data.rotor_temperature_filtered_lr', brakeData?.rotor_temperature_filtered_lr, { shouldDirty: true });
      setValue('data.rotor_temperature_filtered_rr', brakeData?.rotor_temperature_filtered_rr, { shouldDirty: true });
      setValue('data.lat_peak_mu_lf', tireData?.lmuy_lf, { shouldDirty: true });
      setValue('data.lat_peak_mu_rf', tireData?.lmuy_rf, { shouldDirty: true });
      setValue('data.lat_peak_mu_lr', tireData?.lmuy_lr, { shouldDirty: true });
      setValue('data.lat_peak_mu_rr', tireData?.lmuy_rr, { shouldDirty: true });
      setValue('data.long_peak_mu_lf', tireData?.lmux_lf, { shouldDirty: true });
      setValue('data.long_peak_mu_rf', tireData?.lmux_rf, { shouldDirty: true });
      setValue('data.long_peak_mu_lr', tireData?.lmux_lr, { shouldDirty: true });
      setValue('data.long_peak_mu_rr', tireData?.lmux_rr, { shouldDirty: true });
      setValue('data.corner_stiffness_lf', tireData?.lky_lf, { shouldDirty: true });
      setValue('data.corner_stiffness_rf', tireData?.lky_rf, { shouldDirty: true });
      setValue('data.corner_stiffness_lr', tireData?.lky_lr, { shouldDirty: true });
      setValue('data.corner_stiffness_rr', tireData?.lky_rr, { shouldDirty: true });
      setValue('data.slip_stiffness_lf', tireData?.lkx_lf, { shouldDirty: true });
      setValue('data.slip_stiffness_rf', tireData?.lkx_rf, { shouldDirty: true });
      setValue('data.slip_stiffness_lr', tireData?.lkx_lr, { shouldDirty: true });
      setValue('data.slip_stiffness_rr', tireData?.lkx_rr, { shouldDirty: true });
      setValue('data.lat_fall_off_peak_lf', tireData?.lyay_lf, { shouldDirty: true });
      setValue('data.lat_fall_off_peak_rf', tireData?.lyay_rf, { shouldDirty: true });
      setValue('data.lat_fall_off_peak_lr', tireData?.lyay_lr, { shouldDirty: true });
      setValue('data.lat_fall_off_peak_rr', tireData?.lyay_rr, { shouldDirty: true });
      setValue('data.long_fall_off_peak_lf', tireData?.lyax_lf, { shouldDirty: true });
      setValue('data.long_fall_off_peak_rf', tireData?.lyax_rf, { shouldDirty: true });
      setValue('data.long_fall_off_peak_lr', tireData?.lyax_lr, { shouldDirty: true });
      setValue('data.long_fall_off_peak_rr', tireData?.lyax_rr, { shouldDirty: true });
      setValue('data.squareness_combined_lf', tireData?.squareness_combined_lf, { shouldDirty: true });
      setValue('data.squareness_combined_rf', tireData?.squareness_combined_rf, { shouldDirty: true });
      setValue('data.squareness_combined_lr', tireData?.squareness_combined_lr, { shouldDirty: true });
      setValue('data.squareness_combined_rr', tireData?.squareness_combined_rr, { shouldDirty: true });
    }
  }, [stateOptionId]);

  const onSetupNameChange = (value: string) => {
    setValue('name', value, { shouldDirty: true });
  };

  const onSubmit = (input: Partial<SimulatorSim>) => {
    const processSimData = (data: SimulatorSim['data']): SimulatorSim['data'] => {
      // Process and validate the data
      const processedData = { ...data };

      // Ensure all number fields are actually numbers
      Object.keys(processedData).forEach(key => {
        if (typeof processedData[key] === 'string' && !Number.isNaN(Number(processedData[key]))) {
          processedData[key] = Number(processedData[key]);
        }
      });

      return processedData;
    };

    const handleSubmit = async () => {
      setIsSaving(true);

      try {
        // eslint-disable-next-line camelcase, @typescript-eslint/no-unused-vars
        const { drive_file, sim_states, driver, lap_time_data, eight_post, ...simulatorSim } = input;

        const processedData = processSimData(simulatorSim.data);
        const processedSimulatorSim = {
          ...simulatorSim,
          data: processedData,
        } as SimulatorSim;

        const updatedSimInput: UpdateSimInput = {
          ...processedSimulatorSim as SimulatorSim,
        };

        const result = await updateSim({
          variables: {
            input: {
              ...updatedSimInput,
            },
          },
        });

        if (result.data) {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Sim successfully updated',
          });

          form.reset({ ...input });
        } else {
          throw new Error('Update failed');
        }
      } catch (error) {
        let errorMessage = 'An unknown error occurred';
        if (error instanceof Error) {
          errorMessage = error.message;
        } else if (typeof error === 'string') {
          errorMessage = error;
        }
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating Sim: ${errorMessage}`,
        });
      } finally {
        setIsSaving(false);
      }
    };

    handleSubmit();
  };

  const handleNodeClick = useCallback(
    (node: TreeNodeInfo) => {
      const sectionElement = document.getElementById(`section-${node.id}`);

      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [],
  );

  const renderQuadGridInputs = (label: string, fieldName: string) => {
    return (
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label={label}
          inline
        >
          <div className={styles.gridInputs}>
            <RHFNumericInput
              controllerProps={{
                control,
                name: `data.${fieldName}_lf`,
              }}
              inputProps={{
                fill: true,
                placeholder: 'LF',
                value: watch(`data.${fieldName}_lf`),
              }}
              useStringValue
            />
            <RHFNumericInput
              controllerProps={{
                control,
                name: `data.${fieldName}_rf`,
              }}
              inputProps={{
                fill: true,
                placeholder: 'RF',
                value: watch(`data.${fieldName}_rf`),
              }}
              useStringValue
            />
            <RHFNumericInput
              controllerProps={{
                control,
                name: `data.${fieldName}_lr`,
              }}
              inputProps={{
                fill: true,
                placeholder: 'LR',
                value: watch(`data.${fieldName}_lr`),
              }}
              useStringValue
            />
            <RHFNumericInput
              controllerProps={{
                control,
                name: `data.${fieldName}_rr`,
              }}
              inputProps={{
                fill: true,
                placeholder: 'RR',
                value: watch(`data.${fieldName}_rr`),
              }}
              useStringValue
            />
          </div>
        </FormGroup>
      </div>
    );
  };

  return (
    <FormProvider {...form}>
      <form className={styles.mainForm} onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.titleBar}>
          <div className={styles.titleColumn}>
            <div className={styles.titleLabel}>Simulator</div>
            <EditableText
              className={styles.titleValue}
              defaultValue={props.sim.name}
              onChange={value => onSetupNameChange(value)}
              placeholder="Simulator"
            />
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.nav}>
            <Button
              className={styles.saveButton}
              intent={Intent.PRIMARY}
              type="submit"
              text="Save"
              fill
              disabled={!isDirty || isSaving}
            />
            <div className={styles.sideNavContainer}>
              <Tree
                contents={nodes}
                onNodeClick={handleNodeClick}
              />
            </div>
          </div>
          <div className={styles.simContainer}>
            <Accordion
              className={styles.itemContainer}
              id="section-0"
              initialOpen
              key="config"
              title="Config"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Track Surface Temp"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.track_surface_temp',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Thermal Model Reset Lap"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.thermal_model_reset_lap',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.switch_enable_fuel_mass_consumption"
                    render={({ field }) => (
                      <Checkbox
                        label="Enable Fuel Mass Consumption"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.enable_power_scaling_override"
                    render={({ field }) => (
                      <Checkbox
                        label="Override Setup Power Scaling"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <FormGroup
                    className={styles.formGroup}
                    contentClassName={styles.simInput}
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.power_scaling',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.enable_traffic_wind"
                    render={({ field }) => (
                      <Checkbox
                        labelElement={(<>Traffic Wind<span className={styles.labelInfo}>(mph @ deg)</span></>)}
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <FormGroup
                    className={styles.formGroup}
                    contentClassName={styles.simInput}
                    inline
                  >
                    <div className={styles.gridInputs}>
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'data.traffic_wind',
                        }}
                        inputProps={{
                          fill: true,
                          disabled: !overrideEnvTrafficWind,
                        }}
                      />
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'data.traffic_wind_angle',
                        }}
                        inputProps={{
                          fill: true,
                          disabled: !overrideEnvTrafficWind,
                        }}
                      />
                    </div>
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.switch_enable_ground_contact"
                    render={({ field }) => (
                      <Checkbox
                        label="Enable Ground Contact"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </Accordion>
            <Accordion
              className={styles.itemContainer}
              id="section-1"
              initialOpen
              key="simStates"
              title="Sim States"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    helperText={<ErrorMessage errors={errors} name="sim_states_id" />}
                    label="Simulation States"
                    labelInfo="(required)"
                    inline
                  >
                    <RHFSelect
                      controllerProps={{
                        control,
                        name: 'sim_states_id',
                        rules: { required: 'Simulation States are required' },
                      }}
                      intent={get(errors, 'sim_states_id') && Intent.DANGER}
                      items={simStateOptions}
                      fill
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    helperText={<ErrorMessage errors={errors} name="sim_state_label" />}
                    label="State Option"
                    inline
                  >
                    <RHFSelect
                      controllerProps={{
                        control,
                        name: 'state_option_id',
                      }}
                      intent={get(errors, 'state_option_id') && Intent.DANGER}
                      items={simStates.find(state => state.id === simStateId)?.states.map(item => ({ label: item.label, value: item.id })) ?? []}
                      fill
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Global Mu"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.global_mu',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: watch('data.global_mu'),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                {renderQuadGridInputs('Build', 'build')}
                {renderQuadGridInputs('Temps', 'temps')}
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Fuel Burn"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.fuel_burn',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: watch('data.fuel_burn'),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Water Temp"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.water_temp',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: watch('data.water_temp'),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Alternator Current"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.alternator_current',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: watch('data.alternator_current'),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                {renderQuadGridInputs('Rotor Temps', 'rotor_temperature')}
                {renderQuadGridInputs('Rotor Temps Filtered', 'rotor_temperature_filtered')}
                {renderQuadGridInputs('Lat Peak Mu', 'lat_peak_mu')}
                {renderQuadGridInputs('Long Peak Mu', 'long_peak_mu')}
                {renderQuadGridInputs('Corner Stiffness', 'corner_stiffness')}
                {renderQuadGridInputs('Slip Stiffness', 'slip_stiffness')}
                {renderQuadGridInputs('Lat Fall Off Peak', 'lat_fall_off_peak')}
                {renderQuadGridInputs('Long Fall Off Peak', 'long_fall_off_peak')}
                {renderQuadGridInputs('Squareness Combined', 'squareness_combined')}
              </div>
            </Accordion>
            <Accordion
              className={styles.itemContainer}
              id="section-2"
              initialOpen
              key="output"
              title="Output"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Output Directory"
                    inline
                  >
                    <RHFTextInput
                      controllerProps={{
                        control,
                        name: 'output_directory',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <div className={styles.outputCheckboxContainer}>
                    <div className={styles.outputCheckboxName}>VDF</div>
                    <Controller
                      control={control}
                      name="download_vdf"
                      render={({ field }) => (
                        <Checkbox
                          label="Download"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={styles.inputRow}>
                  <div className={styles.outputCheckboxContainer}>
                    <div className={styles.outputCheckboxName}>EDF</div>
                    <Controller
                      control={control}
                      name="download_edf"
                      render={({ field }) => (
                        <Checkbox
                          label="Download"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
