import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { H3, H4 } from '@blueprintjs/core';

import PartNavigationMenu from 'components/PartNavigationMenu';
import { usePartManagementQuery } from 'graphql/generated/graphql';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

interface PartCategory {
  id: number;
  name: string;
  display_name: string;
  description?: string | null | undefined;
}

interface PartConfig {
  id: number;
  category: PartCategory;
  name: string;
  display_name: string;
  mileage_limit?: number | null | undefined;
  expires: boolean;
  organization_id: string;
  organization_name: string;
  team_id?: string | null | undefined;
  team_name?: string | null | undefined;
  series: string;
}

interface PartsData {
  partCategories?: PartCategory[];
  partConfigs?: {
    rows: PartConfig[]
  }
}

type OrganizedPartCategory = [string, PartConfig[]];
type OrganizedParts = OrganizedPartCategory[];

export default () => {
  useDocumentTitle('Parts');
  const [organizedParts, setOrganizedParts] = useState<OrganizedParts>([]);
  // Does not use a react-router loader because queries performed in a loader cannot be
  // refetched from another component, and this one is refetched from the part nav
  const { data } = usePartManagementQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data) {
      const organizePartsByCategory = (inputData: PartsData) => {
        const partCategories = inputData?.partCategories || [];
        const partConfigRows = inputData?.partConfigs?.rows || [];

        return partCategories.map<OrganizedPartCategory>(category => {
          const categoryParts = partConfigRows.filter(config => (config.category.name === category.name));
          return [category.name, categoryParts];
        });
      };

      setOrganizedParts(organizePartsByCategory(data));
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <PartNavigationMenu />
      <div className={styles.categoriesListContainer}>
        <H3>All Categories</H3>
        {organizedParts.map(([categoryName, configs]) => {
          const category = data?.partCategories.find(c => c.name === categoryName);
          if (!category) return null;
          return (
            <div key={categoryName}>
              <H4 className={styles.categoryListHeading}>{category.display_name}</H4>
              <ul className={styles.categoryList}>
                {configs.map(config => (
                  <li key={`config-${config.name}-${config.id}`}>
                    <Link
                      className={styles.configLink}
                      to={`category/${category.name}/${config.name}`}
                    >
                      {config.display_name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};
