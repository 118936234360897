import { Button, Intent, InputGroup } from '@blueprintjs/core';
import { useGetLineCrossingLazyQuery, RunLapTime } from 'graphql/generated/graphql';
import { useRef, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './index.module.css';
import { GQLSetup } from 'types';
import AppToaster from 'helpers/toaster';
import classNames from 'classnames';

enum Flags {
  GREEN = 1,
  YELLOW = 2,
  RED = 3,
  CHECKER = 4,
  STOP = 6,
  WARM = 8
}

interface LapTimeTableProps {
  runIndex: number;
  setup?: GQLSetup;
  readOnly?: boolean;
}

export default (props: LapTimeTableProps) => {
  const { runIndex, setup, readOnly } = props;
  const [getLineCrossing] = useGetLineCrossingLazyQuery();
  const { getValues, setValue } = useFormContext();

  const runLapTimes = getValues(`runs.${runIndex}.lap_times`) as RunLapTime[];
  const startLap = useRef<HTMLInputElement>(null);
  const endLap = useRef<HTMLInputElement>(null);

  const [laps, setLaps] = useState<RunLapTime[]>([]);

  useEffect(() => {
    if (runLapTimes && runLapTimes.length > 0) {
      setLaps(runLapTimes.map((lap: RunLapTime) => ({
        lap: lap.lap,
        time: lap.time || '',
        lapTime: lap.lapTime || '',
        flag: lap.flag,
      })));
    } else {
      setLaps([{ lap: 1, time: '', lapTime: '' }]);
    }
  }, [runLapTimes]);

  const getFastestLapIndex = () => {
    let fastestIndex = -1;
    let fastestTime = Infinity;

    laps.forEach((lap: RunLapTime, i: number) => {
      const lapTime = parseFloat(lap.lapTime || '');
      if (!Number.isNaN(lapTime) && (lapTime < fastestTime)) {
        fastestTime = lapTime;
        fastestIndex = i;
      }
    });

    return fastestIndex;
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    setLaps((prevLaps: RunLapTime[]) => {
      const newLaps = [...prevLaps];
      const newLapData = {
        ...newLaps[index],
        [field]: value,
      };
      newLaps[index] = newLapData;

      // Automatically add a new lap if this is the last row
      if (index === laps.length - 1 && (field === 'time' || field === 'lapTime') && value) {
        newLaps.push({ lap: newLaps.length + 1, time: '', lapTime: '' });
      }
      setValue(`runs.${runIndex}.lap_times`, newLaps, { shouldDirty: true });
      return newLaps;
    });
  };

  const handleDeleteLap = (index: number) => {
    setLaps((prevLaps: RunLapTime[]) => {
      const newLaps = prevLaps.filter((_, i) => i !== index);
      const orderedNewLaps = newLaps.map((lap, i) => ({ ...lap, lap: i + 1 }));

      setValue(`runs.${runIndex}.lap_times`, orderedNewLaps, { shouldDirty: true });
      return orderedNewLaps;
    });
  };

  const handleFetchLapData = async () => {
    const start = Number(startLap.current?.value);
    const end = Number(endLap.current?.value);
    if (start && end && setup) {
      await getLineCrossing({
        variables: {
          event: setup.event,
          year: setup.year,
          series: setup.series,
          session: getValues(`runs.${runIndex}.session`),
          vehicleId: setup?.team_name || '',
          lapStart: start,
          lapEnd: end,
        },
        onCompleted: ({ getLineCrossing }) => {
          const trackedFlags = [Flags.GREEN, Flags.YELLOW, Flags.CHECKER];
          const fetchedLaps = getLineCrossing.map(lc => ({
            lap: lc.lap_number,
            time: lc.time ? new Date(lc.time).toLocaleTimeString() : '',
            lapTime: lc.lap_time ? (lc.lap_time / 1000).toFixed(3).toString() : '',
            flag: lc.flag,
          } as RunLapTime))
            .filter(l => l.flag && trackedFlags.includes(l.flag))
            .reverse();
          setValue(`runs.${runIndex}.lap_times`, fetchedLaps, { shouldDirty: true });
          setLaps(fetchedLaps);
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error fetching lap data: ${JSON.stringify(e.message)}`,
          });
        },
      });
    }
  };

  const fastestLapIndex = getFastestLapIndex();

  return (
    <div key="lap-time-table" className={styles.inputRow}>
      {getValues(`runs.${runIndex}.session`) !== 'Sim' && !readOnly
        && (
          <div className={styles.lapTimeTableRow}>
            <Button
              className={styles.lapTimeTableFetchButton}
              icon="import"
              text="Fetch"
              intent={Intent.PRIMARY}
              small
              onClick={handleFetchLapData}
            />
            <InputGroup
              className={styles.lapTimTableLapRangeInput}
              placeholder="Start Lap"
              inputRef={startLap}
            />
            <InputGroup
              className={styles.lapTimTableLapRangeInput}
              placeholder="End Lap"
              inputRef={endLap}
            />
          </div>
        )}
      <div className={styles.lapTimeTableRow}>
        <div className={styles.lapTimeTableLapCell}>Lap</div>
        <div className={styles.lapTimeTableHeader}>Time</div>
        <div className={styles.lapTimeTableHeader}>Lap Time</div>
        <div className={styles.lapTimeTableEmptyHeader} />
      </div>
      <div className={styles.lapTimeTableContainer}>
        {laps.map((lap: RunLapTime, index: number) => (
          <div key={index} className={styles.lapTimeTableRow}>
            <div className={styles.lapTimeTableLapCell}>{lap.lap}</div>
            <InputGroup
              value={lap.time || ''}
              onChange={(e) => handleInputChange(index, 'time', e.target.value)}
              fill
              readOnly={readOnly}
            />
            <InputGroup
              value={lap.lapTime || ''}
              onChange={(e) => handleInputChange(index, 'lapTime', e.target.value)}
              readOnly={readOnly}
              fill
              className={classNames(
                index === fastestLapIndex ? styles.fastestLap : undefined,
                lap.flag && (lap.flag === Flags.YELLOW ? styles.yellowFlag : undefined)
              )}
            />
            <Button
              icon="cross"
              intent={Intent.DANGER}
              minimal
              onClick={() => handleDeleteLap(index)}
              small
              disabled={(index === 0 || readOnly)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
