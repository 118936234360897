import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useRouteLoaderData, NavLink } from 'react-router-dom';

import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';
import { hasPermission } from '../../helpers/permissions';
import { Drawer, DrawerSize, Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface GlobalNavigationMenuProps {
  isOpen: boolean,
  setIsOpen: (openState: boolean) => void;
}

export default (props: GlobalNavigationMenuProps) => {
  const { isOpen, setIsOpen } = props;
  const darkMode = useSelector(selectDarkMode);
  const classes = classNames({ 'bp4-dark': darkMode });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { permissions } } = useRouteLoaderData('root') as any;

  const containerClasses = classNames(styles.container, {
    [styles.dark]: darkMode,
    [styles.collapsed]: !isOpen,
  });

  const handleNavLinkClick = (event: React.MouseEvent) => {
    if (!event.ctrlKey && !event.metaKey) {
      setIsOpen(false);
    }
  };

  return (
    <div className={classes}>
      <Drawer
        title="Navigation"
        position={Position.LEFT}
        isOpen={isOpen}
        className={styles.navigationDrawer}
        isCloseButtonShown
        size={DrawerSize.SMALL}
        canOutsideClickClose
        onClose={() => setIsOpen(false)}
      >
        <div className={containerClasses}>
          <div className={styles.navButtonContainer}>
            <Tooltip2 content="Setups" disabled={isOpen}>
              <NavLink
                to="/"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="car" size="lg" />
                </span>
                <span className={styles.navButtonText}>Setups</span>
              </NavLink>
            </Tooltip2>
            {hasPermission('suit_write', permissions) ? (
              <>
                <Tooltip2 content="Setup Fields" disabled={isOpen}>
                  <NavLink
                    to="/setups/fields"
                    className={styles.navButton}
                    onClick={(e) => handleNavLinkClick(e)}
                  >
                    <span>
                      <FontAwesomeIcon fixedWidth icon="wrench" size="lg" />
                    </span>
                    <span className={styles.navButtonText}>Setup Fields</span>
                  </NavLink>
                </Tooltip2>
                <Tooltip2 content="SUITs" disabled={isOpen}>
                  <NavLink
                    to="/setups/suits"
                    className={styles.navButton}
                    onClick={(e) => handleNavLinkClick(e)}
                  >
                    <span>
                      <FontAwesomeIcon fixedWidth icon="clipboard-list-check" size="lg" />
                    </span>
                    <span className={styles.navButtonText}>SUITs</span>
                  </NavLink>
                </Tooltip2>
              </>
            ) : null}
            <hr className={styles.navBorder} />
            <Tooltip2 content="Run Compare" disabled={isOpen}>
              <NavLink
                to="/runs/compare"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="cars" size="lg" />
                </span>
                <span className={styles.navButtonText}>Run Compare</span>
              </NavLink>
            </Tooltip2>
            {hasPermission('ruit_write', permissions) ? (
              <>
                <Tooltip2 content="Run Fields" disabled={isOpen}>
                  <NavLink
                    to="/runs/fields"
                    className={styles.navButton}
                    onClick={(e) => handleNavLinkClick(e)}
                  >
                    <span>
                      <FontAwesomeIcon fixedWidth icon="temperature-list" size="lg" />
                    </span>
                    <span className={styles.navButtonText}>Run Fields</span>
                  </NavLink>
                </Tooltip2>
                <Tooltip2 content="RUITs" disabled={isOpen}>
                  <NavLink
                    to="/runs/ruits"
                    className={styles.navButton}
                    onClick={(e) => handleNavLinkClick(e)}
                  >
                    <span>
                      <FontAwesomeIcon fixedWidth icon="memo" size="lg" />
                    </span>
                    <span className={styles.navButtonText}>RUITs</span>
                  </NavLink>
                </Tooltip2>
              </>
            ) : null}
            <hr className={styles.navBorder} />
            <Tooltip2 content="Sims" disabled={isOpen}>
              <NavLink
                to="/sims"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="computer" size="lg" />
                </span>
                <span className={styles.navButtonText}>Sims</span>
              </NavLink>
            </Tooltip2>
            <Tooltip2 content="Sim Reporting" disabled={isOpen}>
              <NavLink
                to="/sims/reporting"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="magnifying-glass-chart" size="lg" />
                </span>
                <span className={styles.navButtonText}>Sim Reporting</span>
              </NavLink>
            </Tooltip2>
            <Tooltip2 content="Sim Documents" disabled={isOpen}>
              <NavLink
                to="/sims/documents"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="notebook" size="lg" />
                </span>
                <span className={styles.navButtonText}>Sim Documents</span>
              </NavLink>
            </Tooltip2>
            <Tooltip2 content="Sim Worksheets" disabled={isOpen}>
              <NavLink
                to="/sim-worksheets"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="table-tree" size="lg" />
                </span>
                <span className={styles.navButtonText}>Sim Worksheets</span>
              </NavLink>
            </Tooltip2>
            <Tooltip2 content="Sweeps" disabled={isOpen}>
              <NavLink
                to="/sims/sweeps"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="gauge-max" size="lg" />
                </span>
                <span className={styles.navButtonText}>Sweeps</span>
              </NavLink>
            </Tooltip2>
            <Tooltip2 content="Metrics" disabled={isOpen}>
              <NavLink
                to="/sims/metrics"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="sliders" size="lg" />
                </span>
                <span className={styles.navButtonText}>Metrics</span>
              </NavLink>
            </Tooltip2>
            <hr className={styles.navBorder} />
            <Tooltip2 content="Environments" disabled={isOpen}>
              <NavLink
                to="/environments"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="road" size="lg" />
                </span>
                <span className={styles.navButtonText}>Environments</span>
              </NavLink>
            </Tooltip2>
            <hr className={styles.navBorder} />
            <Tooltip2 content="Parts" disabled={isOpen}>
              <NavLink
                to="/parts"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="pump" size="lg" />
                </span>
                <span className={styles.navButtonText}>Parts</span>
              </NavLink>
            </Tooltip2>
            <Tooltip2 content="Part Properties" disabled={isOpen}>
              <NavLink
                to="/parts/properties"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="scale-unbalanced" size="lg" />
                </span>
                <span className={styles.navButtonText}>Part Properties</span>
              </NavLink>
            </Tooltip2>
            <Tooltip2 content="Part Configs" disabled={isOpen}>
              <NavLink
                to="/parts/configs"
                className={styles.navButton}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <span>
                  <FontAwesomeIcon fixedWidth icon="toolbox" size="lg" />
                </span>
                <span className={styles.navButtonText}>Part Configs</span>
              </NavLink>
            </Tooltip2>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
