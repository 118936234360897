import React, { useEffect, useRef, useState, useMemo, CSSProperties } from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  InputGroup,
  Intent,
  Icon,
  Menu,
  MenuItem,
  Checkbox,
  Overlay,
  Spinner,
} from '@blueprintjs/core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { set, cloneDeep, forEach, isEqual, omit } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  useParams,
  useBlocker,
  BlockerFunction,
} from 'react-router-dom';
import { Popover2 } from '@blueprintjs/popover2';
import {
  flexRender,
  getCoreRowModel,
  Row,
  Table,
  useReactTable,
  createColumnHelper,
} from '@tanstack/react-table';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import {
  useSimWorksheetByIdQuery,
  useBulkAddSetupBranchToSimOutlineMutation,
  useBulkAddSimToSimOutlineMutation,
  useBulkAddEnvToSimOutlineMutation,
  useBulkAddSweepToSimOutlineMutation,
  useBulkCloneSimOutlineMutation,
  useDeleteSimFromSimOutlineMutation,
  useDeleteSetupBranchFromSimOutlineMutation,
  useDeleteEnvFromSimOutlineMutation,
  useCloneSimOutlineMutation,
  useDeleteSimOutlineMutation,
  useClearSimOutlineMutation,
  useBulkDeleteSimOutlineMutation,
  useAddSimOutlineToWorksheetMutation,
  useUpdateSimWorksheetMutation,
  useUpdateSimOutlineDetailsMutation,
  useUpdateSimOutlineOrderMutation,
  Environment,
  Sim,
  Sweep,
  useBulkAddMetricToSimOutlineMutation,
  Metric,
  useDeleteSweepFromSimOutlineMutation,
  useDeleteMetricFromSimOutlineMutation,
} from 'graphql/generated/graphql';
import { SetupSelection, GQLSimOutline, GQLSimWorksheet } from 'types';
import TitleBar from 'components/TitleBar';
import { BulkRowActions, BulkRowAction, RowActions, RowSelections } from 'components/Table';
import { useAlert } from 'components/Alert';
import AddSetupsModal from 'components/SelectorModal/setup';
import AddEnvironmentsModal from 'components/SelectorModal/environment';
import AddSimsModal from 'components/SelectorModal/sim';
import AddSweepsModal from 'components/SelectorModal/sweep';
import AddMetricsModal from 'components/SelectorModal/metric';
import Select from 'components/Select';
import SimpleInputDialog from './SimpleInputDialog';
import RunButton from './RunButton';
import AppToaster from 'helpers/toaster';
import { SimWorksheetToSimWorksheetInput } from 'helpers/converter';
import { baseName } from 'config';
import { selectDarkMode } from 'reducers/ui';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

type DragEndEventType = typeof DragEndEvent

interface SimOutlineItem {
  id: number,
  name: string,
  directory_suffix?: string,
  head?: {
    name: string,
  },
}

enum SimOutlineItemType {
  SIM,
  SETUP_BRANCH,
  ENVIRONMENT,
  SWEEP,
  METRIC,
}

type SimOutlineRow = GQLSimOutline & { run: boolean }

// Drag and Drop Cell Component
const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <button
      type="button"
      className={styles.dndButton}
      {...attributes}
      {...listeners}
    >
      <Icon icon="drag-handle-horizontal" />
    </button>
  );
};

// Row Component
const DraggableRow = ({ row }: { row: Row<SimOutlineRow> }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id.toString(),
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), // let dnd-kit do its thing
    transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  return (
    // connect row ref to dnd-kit, apply important styles
    <tr ref={setNodeRef} style={style}>
      {row.getVisibleCells().map(cell => (
        <td key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

const HeaderSelectCheckbox = <TData, >({ table }: { table: Table<TData> }) => (
  <Checkbox
    checked={table.getIsAllRowsSelected()}
    className={styles.rowSelectCheckbox}
    indeterminate={table.getIsSomeRowsSelected()}
    onChange={table.getToggleAllRowsSelectedHandler()}
  />
);

const RowSelectCheckbox = <TData, >({ row }: { row: Row<TData> }) => (
  <Checkbox
    checked={row.getIsSelected()}
    className={styles.rowSelectCheckbox}
    disabled={!row.getCanSelect()}
    indeterminate={row.getIsSomeSelected()}
    onChange={row.getToggleSelectedHandler()}
  />
);

const RowActionCell = <TData, >({ row }: { row: Row<TData> }, actions: RowActions<TData>) => (
  <Popover2
    content={(
      <Menu>
        {actions.map((action, index) => {
          const menuItemProps: Record<string, unknown> = {
            intent: action.intent,
            key: index,
            onClick: () => action.value(row),
            text: action.label,
          };
          const icon = action.icon?.(row);
          if (icon) {
            menuItemProps.icon = icon;
          }
          return (
            <MenuItem {...menuItemProps} />
          );
        })}
      </Menu>
    )}
    placement="left"
  >
    <Button minimal icon={<FontAwesomeIcon icon="ellipsis-vertical" />} />
  </Popover2>
);

export default () => {
  const params = useParams();
  const worksheetId = Number(params.worksheetId);
  const darkMode = useSelector(selectDarkMode);
  const [worksheet, setWorksheet] = useState<GQLSimWorksheet>();
  const [cleanWorksheet, setCleanWorksheet] = useState<GQLSimWorksheet>();
  const [isAddSimOutlineOpen, setAddSimOutlineOpen] = useState(false);
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const [isSetupSelectorOpen, setSetupSelectorOpen] = useState(false);
  const [isEnvSelectorOpen, setEnvSelectorOpen] = useState(false);
  const [isSimSelectorOpen, setSimSelectorOpen] = useState(false);
  const [isSweepSelectorOpen, setSweepSelectorOpen] = useState(false);
  const [isMetricSelectorOpen, setMetricSelectorOpen] = useState(false);
  const [cloneSource, setCloneSource] = useState<SimOutlineRow>();
  const [currentSimOutlineId, setCurrentSimOutlineId] = useState<number | null>(null);
  const [data, setData] = React.useState<SimOutlineRow[]>([]);
  const [rowSelection, setRowSelection] = useState<RowSelections>({});
  const [isDirty, setIsDirty] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useDocumentTitle(
    cleanWorksheet && cleanWorksheet.name ? `${cleanWorksheet.name}` : 'Apex Setup'
  );

  const cloneName = useRef<HTMLInputElement>(null);
  const alert = useAlert();

  /**
   * Immediate queries
   */
  const { loading } = useSimWorksheetByIdQuery({
    variables: { id: worksheetId },
    onCompleted: data => {
      setWorksheet(data.simWorksheet as GQLSimWorksheet);
      setCleanWorksheet(data.simWorksheet as GQLSimWorksheet);

      if (data.simWorksheet?.sim_outlines) {
        setData(data.simWorksheet.sim_outlines as SimOutlineRow[]);
      }

      setIsInitialLoad(false);
    },
    fetchPolicy: 'cache-and-network',
  });

  /**
   * Mutations
   */
  const [bulkAddSimToSimOutline] = useBulkAddSimToSimOutlineMutation();
  const [bulkAddSetupBranchToSimOutline] = useBulkAddSetupBranchToSimOutlineMutation();
  const [bulkAddEnvToSimOutline] = useBulkAddEnvToSimOutlineMutation();
  const [bulkAddSweepToSimOutline] = useBulkAddSweepToSimOutlineMutation();
  const [bulkAddMetricToSimOutline] = useBulkAddMetricToSimOutlineMutation();
  const [bulkCloneSimOutline] = useBulkCloneSimOutlineMutation();
  const [deleteSimFromSimOutline] = useDeleteSimFromSimOutlineMutation();
  const [deleteSetupBranchFromSimOutline] = useDeleteSetupBranchFromSimOutlineMutation();
  const [deleteEnvFromSimOutline] = useDeleteEnvFromSimOutlineMutation();
  const [deleteSweepFromSimOutline] = useDeleteSweepFromSimOutlineMutation();
  const [deleteMetricFromSimOutline] = useDeleteMetricFromSimOutlineMutation();
  const [cloneSimOutline] = useCloneSimOutlineMutation();
  const [clearSimOutline] = useClearSimOutlineMutation();
  const [deleteSimOutline] = useDeleteSimOutlineMutation();
  const [bulkDeleteSimOutline] = useBulkDeleteSimOutlineMutation();
  const [addSimOutlineToWorksheet] = useAddSimOutlineToWorksheetMutation();
  const [updateSimWorksheet] = useUpdateSimWorksheetMutation();
  const [updateSimOutlineDetails] = useUpdateSimOutlineDetailsMutation();
  const [updateSimOutlineOrder] = useUpdateSimOutlineOrderMutation();

  const onSimWorksheetChange = (key: string, value: string) => {
    if (!worksheet) return;

    const newWorksheet = cloneDeep(worksheet);
    set(newWorksheet, key, key === 'year' ? Number(value) : value);
    setWorksheet(newWorksheet);
  };

  const onSimWorksheetChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!worksheet) return;
    const { name, value } = e.target;

    onSimWorksheetChange(name, value);
  };

  /**
   * Effects
   */
  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onCloneSimOutline();
      }
    };

    if (isCloneModalOpen) {
      document.addEventListener('keydown', onEnter);
    }

    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [isCloneModalOpen]);

  useEffect(() => {
    if (!worksheet || !cleanWorksheet) return;

    const isDirtyCheck = !isEqual(omit(worksheet, ['sim_outlines']), omit(cleanWorksheet, ['sim_outlines']));
    const prevIsDirty = isDirty;

    setIsDirty(isDirtyCheck);
    if (isDirtyCheck !== prevIsDirty) {
      if (isDirtyCheck) addWarningListener();
      else removeWarningListener();
    }
  }, [worksheet, cleanWorksheet]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  useBlocker(blockerFunc);

  const dataIds = useMemo<string[]>(
    () => data?.map(({ id }) => id.toString()),
    [data]
  );

  const addSimOutlineItems = (itemType: SimOutlineItemType, simOutlineId?: number) => {
    if (!simOutlineId) return;
    setCurrentSimOutlineId(simOutlineId);
    switch (itemType) {
      case SimOutlineItemType.SIM:
        setSimSelectorOpen(true);
        break;
      case SimOutlineItemType.SETUP_BRANCH:
        setSetupSelectorOpen(true);
        break;
      case SimOutlineItemType.ENVIRONMENT:
        setEnvSelectorOpen(true);
        break;
      case SimOutlineItemType.SWEEP:
        setSweepSelectorOpen(true);
        break;
      case SimOutlineItemType.METRIC:
        setMetricSelectorOpen(true);
        break;
      default:
        break;
    }
  };

  const deleteSimOutlineItem = (simOutlineId: number, itemType: SimOutlineItemType, itemId: number) => {
    switch (itemType) {
      case SimOutlineItemType.SIM:
        deleteSimFromSimOutline({
          variables: { simOutlineId, simId: itemId },
          refetchQueries: ['SimWorksheetById'],
        });
        break;
      case SimOutlineItemType.SETUP_BRANCH:
        deleteSetupBranchFromSimOutline({
          variables: { simOutlineId, setupBranchId: itemId },
          refetchQueries: ['SimWorksheetById'],
        });
        break;
      case SimOutlineItemType.ENVIRONMENT:
        deleteEnvFromSimOutline({
          variables: { simOutlineId, envId: itemId },
          refetchQueries: ['SimWorksheetById'],
        });
        break;
      case SimOutlineItemType.SWEEP:
        deleteSweepFromSimOutline({
          variables: { simOutlineId, sweepId: itemId },
          refetchQueries: ['SimWorksheetById'],
        });
        break;
      case SimOutlineItemType.METRIC:
        deleteMetricFromSimOutline({
          variables: { simOutlineId, metricId: itemId },
          refetchQueries: ['SimWorksheetById'],
        });
        break;
      default:
        break;
    }
  };

  interface RunButtonProps {
    simOutlineIds: number[]
    text?: string
    transparent?: boolean
    disabled?: boolean
  }

  const renderRunButton = (options: RunButtonProps) => {
    if (!worksheet) return null;
    return (
      <RunButton {...options} worksheet={worksheet} isDirty={isDirty} onSave={onSaveWorksheet} />
    );
  };

  const onOutlineNameBlur = (id: number, name: string) => {
    const input = { id, name };

    const currentOutline = worksheet?.sim_outlines?.find(outline => outline.id === id);

    // Optimistic update for better UX
    const optimisticResponse = {
      updateSimOutlineDetails: {
        id,
        directory_suffix: currentOutline?.directory_suffix ?? '',
        name,
      },
    };

    updateSimOutlineDetails({
      variables: { input },
      optimisticResponse,
      refetchQueries: ['SimWorksheetById'],
    });
  };

  const onOutlineDirectorySuffixBlur = (id: number, directorySuffix: string) => {
    const input = { id, directory_suffix: directorySuffix };

    const currentOutline = worksheet?.sim_outlines?.find(outline => outline.id === id);

    // Optimistic update for better UX
    const optimisticResponse = {
      updateSimOutlineDetails: {
        id,
        name: currentOutline?.name ?? '',
        directory_suffix: directorySuffix,
      },
    };

    updateSimOutlineDetails({
      variables: { input },
      optimisticResponse,
      refetchQueries: ['SimWorksheetById'],
    });
  };

  const renderSimOutlineItemCell = (
    itemType: SimOutlineItemType,
    itemArray?: SimOutlineItem[] | null,
    simOutlineId?: number
  ) => {
    if (!itemArray || !simOutlineId) return null;

    const getNavPath = (item: SimOutlineItem) => {
      let prefix = '';
      switch (itemType) {
        case SimOutlineItemType.SETUP_BRANCH:
          prefix = 'setup/';
          break;
        case SimOutlineItemType.SIM:
          prefix = 'sims/';
          break;
        case SimOutlineItemType.ENVIRONMENT:
          prefix = 'environments/';
          break;
        case SimOutlineItemType.SWEEP:
          prefix = 'sims/sweeps/';
          break;
        case SimOutlineItemType.METRIC:
          prefix = 'sims/metrics/';
          break;
        default:
          break;
      }
      return `${baseName === '/' ? '' : baseName}/${prefix}${item.id}`;
    };

    const getItemName = (item: SimOutlineItem) => {
      if (item.head) return `${item.head.name} - ${item.name}`;
      if (item.name) return item.name;
      return '';
    };
    return (
      <div>
        {
          itemArray.map((item, index) => (
            <div key={index} className={styles.simOutlineItemCell}>
              <div>
                <a href={getNavPath(item)}>{getItemName(item)}</a>
              </div>
              <div
                className={styles.deleteButton}
              >
                <Button
                  minimal
                  onClick={() => deleteSimOutlineItem(simOutlineId, itemType, item.id)}
                >
                  <FontAwesomeIcon icon={faXmark} color="red" />
                </Button>
              </div>
            </div>
          ))
        }
        <Button
          onClick={() => addSimOutlineItems(itemType, simOutlineId)}
          className={styles.buttonMargins}
        >
          <FontAwesomeIcon icon={faPlus} /> Add
        </Button>
      </div>
    );
  };

  const renderOutlineNameCell = (id: number, name: string) => {
    const [value, setValue] = useState(name);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    return (
      <InputGroup value={value} onChange={onChange} onBlur={() => onOutlineNameBlur(id, value)} />
    );
  };

  const renderOutlineDirectorySuffixCell = (id: number, directorySuffix: string) => {
    const [value, setValue] = useState(directorySuffix);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    return (
      <InputGroup value={value} onChange={onChange} onBlur={() => onOutlineDirectorySuffixBlur(id, value)} />
    );
  };

  const rowActions: RowActions<SimOutlineRow> = [{
    label: 'Clone',
    value: row => {
      if (!row.original) return;
      setCloneSource(row.original);
      setCloneModalOpen(true);
    },
  }, {
    label: 'Clear',
    value: row => {
      if (!row.original) return;
      clearSimOutline({
        variables: { simOutlineId: row.original.id },
        refetchQueries: ['SimWorksheetById'],
      });
    },
  }, {
    label: 'Delete',
    intent: Intent.DANGER,
    value: row => {
      if (!row.original) return;
      alert.showAlert(`Delete sim outline "${row.original.name}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        if (!row.original) return;
        deleteSimOutline({
          variables: { simOutlineId: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Successfully deleted sim outline',
            });
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Failed to delete sim outline: ${e.message}`,
            });
          },
          refetchQueries: ['SimWorksheetById'],
        });
      });
    },
  }];

  const makeDndCell = (rowId: string) => (<RowDragHandleCell rowId={rowId} />);

  const columnHelper = createColumnHelper<SimOutlineRow>();

  const staticColumns = useMemo(() => [
    columnHelper.display({
      id: 'drag-handle',
      header: 'Move',
      cell: info => makeDndCell(info.row.id),
      size: 50,
    }),
    columnHelper.display({
      id: 'select',
      header: HeaderSelectCheckbox,
      cell: RowSelectCheckbox,
      size: 30,
    }),
  ], []);

  const dynamicColumns = useMemo(() => [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => renderOutlineNameCell(info.row.original.id, info.getValue()),
    }),
    columnHelper.accessor('directory_suffix', {
      header: 'Directory Suffix',
      cell: info => renderOutlineDirectorySuffixCell(info.row.original.id, (info.getValue() || '')),
    }),
    columnHelper.accessor('setup_branches', {
      header: 'Setup',
      cell: info => renderSimOutlineItemCell(SimOutlineItemType.SETUP_BRANCH, info.getValue(), info.row.original?.id),
    }),
    columnHelper.accessor('sims', {
      header: 'Simulation',
      cell: info => renderSimOutlineItemCell(SimOutlineItemType.SIM, info.getValue(), info.row.original?.id),
    }),
    columnHelper.accessor('environments', {
      header: 'Environment',
      cell: info => renderSimOutlineItemCell(SimOutlineItemType.ENVIRONMENT, info.getValue(), info.row.original?.id),
    }),
    columnHelper.accessor('sweeps', {
      header: 'Sweep',
      cell: info => renderSimOutlineItemCell(SimOutlineItemType.SWEEP, info.getValue(), info.row.original?.id),
    }),
    columnHelper.accessor('metrics', {
      header: 'Metric',
      cell: info => renderSimOutlineItemCell(SimOutlineItemType.METRIC, info.getValue(), info.row.original?.id),
    }),
    columnHelper.display({
      id: 'run-button',
      cell: info => {
        // eslint-disable-next-line camelcase
        const { setup_branches: setups, sims, environments } = info.row.original;
        const totalSims = (setups?.length ? setups.length : 1)
          * (sims?.length ? sims.length : 1)
          * (environments?.length ? environments.length : 1);

        const simCount = (setups?.length || sims?.length || environments?.length) ? ` (${totalSims})` : '';

        return renderRunButton({ simOutlineIds: [info.row.original.id], text: `Run ${simCount}` });
      },
      size: 90,
    }),
    columnHelper.display({
      id: 'actions',
      cell: row => RowActionCell(row, rowActions!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
      size: 40,
    }),
  ], [worksheet, isDirty]);

  const columns = useMemo(() => [
    ...staticColumns,
    ...dynamicColumns,
  ], [staticColumns, dynamicColumns]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id.toString(), // required because row indexes will change
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    enableMultiRowSelection: true,
  });

  // reorder rows after drag & drop
  const handleDragEnd = (event: DragEndEventType) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = dataIds.indexOf(active.id);
      const newIndex = dataIds.indexOf(over.id);

      setData(data => {
        return arrayMove(data, oldIndex, newIndex); // this is just a splice util
      });

      updateSimOutlineOrder({
        variables: { id: Number(active.id), oldIndex, newIndex },
      });
    }
  };

  const selectedRows = useMemo(() => {
    return table.getSelectedRowModel().rows;
  }, [rowSelection]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  if (!worksheet) return null;

  const bulkRowActions: BulkRowActions<SimOutlineRow> = [{
    label: 'Clone',
    value: rows => {
      const outlineIds: number[] = [];
      forEach(rows, (row) => {
        outlineIds.push(row.original.id);
      });
      bulkCloneSimOutline({
        variables: { ids: outlineIds },
        onCompleted: () => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Sim outline(s) successfully cloned',
          });
          rows.forEach(r => r.toggleSelected());
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error cloning sim outline(s): ${e.message}`,
          });
        },
        refetchQueries: ['SimWorksheetById'],
      });
    },
  },
  {
    intent: Intent.DANGER,
    label: 'Delete',
    value: rows => {
      const content = (
        <>
          <p>Delete these?</p>
          <ul>
            {rows.map(r => <li>{r.original.name}</li>)}
          </ul>
        </>
      );
      alert.showAlert(content, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        bulkDeleteSimOutline({
          variables: { simOutlineIds: rows.map(r => r.original.id) },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Sim outlines(s) successfully deleted',
            });
            rows.forEach(r => r.toggleSelected());
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting sim outline(s): ${e.message}`,
            });
          },
          refetchQueries: ['SimWorksheetById'],
        });
      });
    },
  }];

  const onSaveWorksheet = async (): Promise<void> => {
    if (!worksheet) {
      throw new Error('No worksheet to save');
    }

    const translated = SimWorksheetToSimWorksheetInput(worksheet);

    return new Promise((resolve, reject) => {
      updateSimWorksheet({
        variables: {
          id: worksheet.id,
          input: translated,
        },
        onCompleted: () => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Sim worksheet saved',
          });
          setCleanWorksheet(worksheet);
          resolve();
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error saving sim worksheet: ${e.message}`,
          });
          reject(e);
        },
      });
    });
  };

  const handleSaveClick = async () => {
    try {
      await onSaveWorksheet();
    } catch (err) {
      const error = err instanceof Error
        ? err.message
        : 'Unknown error occurred';

      AppToaster.show({
        intent: Intent.DANGER,
        message: `Error saving worksheet: ${error}`,
      });
    }
  };

  const onCloneSimOutline = () => {
    if (!cloneSource) return;
    cloneSimOutline({
      variables: {
        simOutlineId: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
      },
      refetchQueries: ['SimWorksheetById'],
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned sim outline',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone sim outline: ${e.message}`,
        });
      },
    });
    setCloneModalOpen(false);
  };

  const onSetupSelectionDone = async (selections: SetupSelection[]) => {
    setSetupSelectorOpen(false);
    if (!currentSimOutlineId) return;
    bulkAddSetupBranchToSimOutline({
      variables: {
        simOutlineId: currentSimOutlineId,
        setupBranchIds: selections.map(s => s.branch.id),
      },
      refetchQueries: ['SimWorksheetById'],
    });
  };

  const onEnvSelectionDone = async (selections: Environment[]) => {
    setEnvSelectorOpen(false);
    if (!currentSimOutlineId) return;
    if (currentSimOutlineId === -1) {
      // This is for worksheet environment
      setWorksheet({
        ...worksheet,
        environment: selections[0],
      });
      return;
    }
    bulkAddEnvToSimOutline({
      variables: {
        simOutlineId: currentSimOutlineId,
        envIds: selections.map(e => e.id),
      },
      refetchQueries: ['SimWorksheetById'],
    });
  };

  const onSweepSelectionDone = async (selections: Sweep[]) => {
    setSweepSelectorOpen(false);
    if (!currentSimOutlineId) return;
    const currentSweeps = data.find(d => d.id === currentSimOutlineId)?.sweeps;
    if (currentSweeps?.length) {
      deleteSweepFromSimOutline({
        variables: {
          simOutlineId: currentSimOutlineId,
          sweepId: currentSweeps[0].id,
        },
      });
    }
    bulkAddSweepToSimOutline({
      variables: {
        simOutlineId: currentSimOutlineId,
        sweepIds: selections.map(e => e.id),
      },
      refetchQueries: ['SimWorksheetById'],
    });
  };

  const onMetricSelectionDone = async (selections: Metric[]) => {
    setMetricSelectorOpen(false);
    if (!currentSimOutlineId) return;
    const currentMetrics = data.find(d => d.id === currentSimOutlineId)?.metrics;
    if (currentMetrics?.length) {
      deleteMetricFromSimOutline({
        variables: {
          simOutlineId: currentSimOutlineId,
          metricId: currentMetrics[0].id,
        },
      });
    }
    bulkAddMetricToSimOutline({
      variables: {
        simOutlineId: currentSimOutlineId,
        metricIds: selections.map(e => e.id),
      },
      refetchQueries: ['SimWorksheetById'],
    });
  };

  const onSimSelectionDone = async (selections: Sim[]) => {
    setSimSelectorOpen(false);
    if (!currentSimOutlineId) return;
    bulkAddSimToSimOutline({
      variables: {
        simOutlineId: currentSimOutlineId,
        simIds: selections.map(e => e.id),
      },
      refetchQueries: ['SimWorksheetById'],
    });
  };

  const onWorksheetEnvironmentClick = () => {
    setCurrentSimOutlineId(-1);
    setEnvSelectorOpen(true);
  };

  const onOkSimOutlineAdd = (text: string) => {
    setAddSimOutlineOpen(false);
    addSimOutlineToWorksheet({
      variables: {
        worksheetId,
        simOutlineName: text,
      },
      refetchQueries: ['SimWorksheetById'],
    });
  };

  const onBulkRowActionSelect = (action: BulkRowAction<SimOutlineRow>) => {
    action.value(selectedRows);
  };

  const containerClasses = classNames(styles.mainContainer, { [styles.dark]: darkMode });

  const tableClasses = classNames(
    'bp4-html-table bp4-compact bp4-html-table-striped',
    styles.table,
  );

  return (
    <div className={containerClasses}>
      <TitleBar
        values={{
          name: worksheet.name,
          year: worksheet.year,
          track: worksheet.track,
          event: worksheet.event,
          session: worksheet.session,
          description: worksheet.description,
        }}
        onChange={onSimWorksheetChange}
      />

      <Button
        className={styles.saveButton}
        icon="floppy-disk"
        intent={Intent.PRIMARY}
        text="Save"
        onClick={handleSaveClick}
        disabled={!isDirty}
      />

      <div className={classNames(styles.inlineInputs, styles.quarterWidth)}>
        <div className={styles.inlineLabel}>
          Output Directory
        </div>
        <InputGroup
          className={classNames(styles.percentWidth85, styles.marginLeft10px)}
          name="output_directory"
          placeholder="Output Directory"
          defaultValue={worksheet.output_directory || ''}
          onBlur={onSimWorksheetChangeEvent}
        />
      </div>

      <div className={styles.buttonsOnRight}>
        <div className={classNames(styles.inlineInputs, styles.quarterWidth)}>
          <div className={styles.inlineLabel}>
            Default Environment
          </div>
          {worksheet.environment && <span className={styles.textWithEditButton}>{worksheet.environment?.name}</span>}
          <Button
            className={styles.marginLeft10px}
            onClick={() => onWorksheetEnvironmentClick()}
          >
            Change...
          </Button>
        </div>

        <div className={styles.tableActions}>
          <Button
            onClick={() => setAddSimOutlineOpen(true)}
            className={styles.buttonMargins}
          >
            <FontAwesomeIcon icon={faPlus} /> Add Row
          </Button>
          {renderRunButton({
            simOutlineIds: Object.values(selectedRows).map(row => Number(row.id)),
            text: 'Selected',
            disabled: Object.keys(selectedRows).length === 0,
          })}
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.bulkRowActionsContainer}>
          <span>{Object.keys(rowSelection).length} selected</span>
          <Select
            disabled={!Object.keys(rowSelection).length}
            onChange={onBulkRowActionSelect}
            items={bulkRowActions}
            selectProps={{ filterable: false }}
          />
        </div>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
        >
          <table className={tableClasses}>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} colSpan={header.colSpan} style={{ width: header.column.getSize() }}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              <SortableContext
                items={dataIds}
                strategy={verticalListSortingStrategy}
              >
                {table.getRowModel().rows.map(row => (
                  <DraggableRow key={row.id} row={row} />
                ))}
              </SortableContext>
            </tbody>
          </table>
        </DndContext>
      </div>

      {/* -------- Modals --------- */}
      <SimpleInputDialog
        isOpen={isAddSimOutlineOpen}
        isCloseButtonShown
        onClose={() => setAddSimOutlineOpen(false)}
        title="Add sim outline"
        inputPlaceholder="New sim outline name"
        onOk={onOkSimOutlineAdd}
      />

      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <InputGroup
            placeholder="New sim outline name"
            inputRef={cloneName}
            required
            defaultValue={`${cloneSource?.name} CLONE`}
          />
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => onCloneSimOutline()}
            />
          )}
        />
      </Dialog>

      <AddSetupsModal
        isOpen={isSetupSelectorOpen}
        onClose={() => setSetupSelectorOpen(false)}
        onSuccess={onSetupSelectionDone}
      />

      <AddEnvironmentsModal
        isOpen={isEnvSelectorOpen}
        onClose={() => setEnvSelectorOpen(false)}
        onSuccess={onEnvSelectionDone}
      />

      <AddSimsModal
        isOpen={isSimSelectorOpen}
        onClose={() => setSimSelectorOpen(false)}
        onSuccess={onSimSelectionDone}
      />

      <AddSweepsModal
        isOpen={isSweepSelectorOpen}
        onClose={() => setSweepSelectorOpen(false)}
        onSuccess={onSweepSelectionDone}
      />

      <AddMetricsModal
        isOpen={isMetricSelectorOpen}
        onClose={() => setMetricSelectorOpen(false)}
        onSuccess={onMetricSelectionDone}
      />

      <Overlay
        isOpen={loading && isInitialLoad}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </div>
  );
};
