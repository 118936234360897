import { createSlice } from '@reduxjs/toolkit';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { get } from 'lodash';

import { State } from 'reducers';

export interface IndividualTableSettings {
  tableFilters?: ColumnFiltersState;
  tableSorting: SortingState;
}

export interface Settings {
  setupSummary: IndividualTableSettings;
  simSummary: IndividualTableSettings;
  environmentSummary: IndividualTableSettings;
  setupFieldsSummary: IndividualTableSettings;
  suitsSummary: IndividualTableSettings;
  runFieldsSummary: IndividualTableSettings;
  ruitsSummary: IndividualTableSettings;
  sweepsSummary: IndividualTableSettings;
  partPropertiesSummary: IndividualTableSettings;
  partConfigsSummary: IndividualTableSettings;
  simReportingSummary: IndividualTableSettings;
  sweepReportingSummary: IndividualTableSettings;
  simWorksheetSummary: IndividualTableSettings;
  partManagementSummary: IndividualTableSettings;
}

export interface TableViewState {
  settings: Settings;
}

export const initialState = {
  settings: {
    setupSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    simSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    environmentSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    setupFieldsSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    suitsSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    runFieldsSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    runSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    ruitsSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    sweepsSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    partPropertiesSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    partsSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    partConfigsSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    simReportingSummary: {
      tableSorting: [],
    },
    sweepReportingSummary: {
      tableSorting: [],
    },
    simWorksheetSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    partManagementSummary: {
      tableFilters: [],
      tableSorting: [],
    },
  },
};

export const tableViewSlice = createSlice({
  name: 'tableView',
  initialState,
  reducers: {
    setSetupSummaryView: (state, { payload }) => {
      if (!state.settings.setupSummary) {
        state.settings.setupSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.setupSummary.tableFilters = payload.filters;
      state.settings.setupSummary.tableSorting = payload.sorting;
    },
    setSimSummaryView: (state, { payload }) => {
      if (!state.settings.simSummary) {
        state.settings.simSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.simSummary.tableFilters = payload.filters;
      state.settings.simSummary.tableSorting = payload.sorting;
    },
    setEnvironmentSummaryView: (state, { payload }) => {
      if (!state.settings.environmentSummary) {
        state.settings.environmentSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.environmentSummary.tableFilters = payload.filters;
      state.settings.environmentSummary.tableSorting = payload.sorting;
    },
    setSetupFieldsSummaryView: (state, { payload }) => {
      if (!state.settings.setupFieldsSummary) {
        state.settings.setupFieldsSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.setupFieldsSummary.tableFilters = payload.filters;
      state.settings.setupFieldsSummary.tableSorting = payload.sorting;
    },
    setSuitsSummaryView: (state, { payload }) => {
      if (!state.settings.suitsSummary) {
        state.settings.suitsSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.suitsSummary.tableFilters = payload.filters;
      state.settings.suitsSummary.tableSorting = payload.sorting;
    },
    setRunSummaryView: (state, { payload }) => {
      if (!state.settings.runSummary) {
        state.settings.runSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.runSummary.tableFilters = payload.filters;
      state.settings.runSummary.tableSorting = payload.sorting;
    },
    setRunFieldsSummaryView: (state, { payload }) => {
      if (!state.settings.runFieldsSummary) {
        state.settings.runFieldsSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.runFieldsSummary.tableFilters = payload.filters;
      state.settings.runFieldsSummary.tableSorting = payload.sorting;
    },
    setRuitsSummaryView: (state, { payload }) => {
      if (!state.settings.ruitsSummary) {
        state.settings.ruitsSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.ruitsSummary.tableFilters = payload.filters;
      state.settings.ruitsSummary.tableSorting = payload.sorting;
    },
    setSweepsSummaryView: (state, { payload }) => {
      if (!state.settings.sweepsSummary) {
        state.settings.sweepsSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.sweepsSummary.tableFilters = payload.filters;
      state.settings.sweepsSummary.tableSorting = payload.sorting;
    },
    setPartPropertiesSummaryView: (state, { payload }) => {
      if (!state.settings.partPropertiesSummary) {
        state.settings.partPropertiesSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.partPropertiesSummary.tableFilters = payload.filters;
      state.settings.partPropertiesSummary.tableSorting = payload.sorting;
    },
    setPartsSummaryView: (state, { payload }) => {
      if (!state.settings.partsSummary) {
        state.settings.partsSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.partsSummary.tableFilters = payload.filters;
      state.settings.partsSummary.tableSorting = payload.sorting;
    },
    setPartConfigsSummaryView: (state, { payload }) => {
      if (!state.settings.partConfigsSummary) {
        state.settings.partConfigsSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.partConfigsSummary.tableFilters = payload.filters;
      state.settings.partConfigsSummary.tableSorting = payload.sorting;
    },
    setSimReportingSummaryView: (state, { payload }) => {
      if (!state.settings.simReportingSummary) {
        state.settings.simReportingSummary = { tableSorting: [] };
      }
      state.settings.simReportingSummary.tableSorting = payload.sorting;
    },
    setSweepReportingSummaryView: (state, { payload }) => {
      if (!state.settings.sweepReportingSummary) {
        state.settings.sweepReportingSummary = { tableSorting: [] };
      }
      state.settings.sweepReportingSummary.tableSorting = payload.sorting;
    },
    setSimWorksheetSummaryView: (state, { payload }) => {
      if (!state.settings.simWorksheetSummary) {
        state.settings.simWorksheetSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.simWorksheetSummary.tableFilters = payload.filters;
      state.settings.simWorksheetSummary.tableSorting = payload.sorting;
    },
    setPartManagementSummaryView: (state, { payload }) => {
      if (!state.settings.partManagementSummary) {
        state.settings.partManagementSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.partManagementSummary.tableFilters = payload.filters;
      state.settings.partManagementSummary.tableSorting = payload.sorting;
    },
  },
});
export default tableViewSlice.reducer;

export const selectSetupSummaryView = (state: State) => get(state, 'tableView.settings.setupSummary', { tableFilters: [], tableSorting: [] });
export const selectSimSummaryView = (state: State) => get(state, 'tableView.settings.simSummary', { tableFilters: [], tableSorting: [] });
export const selectEnvironmentSummaryView = (state: State) => get(state, 'tableView.settings.environmentSummary', { tableFilters: [], tableSorting: [] });
export const selectSetupFieldsSummaryView = (state: State) => get(state, 'tableView.settings.setupFieldsSummary', { tableFilters: [], tableSorting: [] });
export const selectSuitsSummaryView = (state: State) => get(state, 'tableView.settings.suitsSummary', { tableFilters: [], tableSorting: [] });
export const selectRunFieldsSummaryView = (state: State) => get(state, 'tableView.settings.runFieldsSummary', { tableFilters: [], tableSorting: [] });
export const selectRunSummaryView = (state: State) => get(state, 'tableView.settings.runSummary', { tableFilters: [], tableSorting: [] });
export const selectRuitsSummaryView = (state: State) => get(state, 'tableView.settings.ruitsSummary', { tableFilters: [], tableSorting: [] });
export const selectSweepsSummaryView = (state: State) => get(state, 'tableView.settings.sweepsSummary', { tableFilters: [], tableSorting: [] });
export const selectPartPropertiesSummaryView = (state: State) => get(state, 'tableView.settings.partPropertiesSummary', { tableFilters: [], tableSorting: [] });
export const selectPartConfigsSummaryView = (state: State) => get(state, 'tableView.settings.partConfigsSummary', { tableFilters: [], tableSorting: [] });
export const selectSimReportingSummaryView = (state: State) => get(state, 'tableView.settings.simReportingSummary', { tableSorting: [] });
export const selectSweepReportingSummaryView = (state: State) => get(state, 'tableView.settings.sweepReportingSummary', { tableSorting: [] });
export const selectSimWorksheetSummaryView = (state: State) => get(state, 'tableView.settings.simWorksheetSummary', { tableFilters: [], tableSorting: [] });
export const selectPartManagementSummaryView = (state: State) => get(state, 'tableView.settings.partManagementSummary', { tableFilters: [], tableSorting: [] });

export const selectors = {
  selectSetupSummaryView,
  selectSimSummaryView,
  selectEnvironmentSummaryView,
  selectSetupFieldsSummaryView,
  selectSuitsSummaryView,
  selectRunFieldsSummaryView,
  selectRuitsSummaryView,
  selectSweepsSummaryView,
  selectPartPropertiesSummaryView,
  selectPartConfigsSummaryView,
  selectSimReportingSummaryView,
  selectSweepReportingSummaryView,
  selectSimWorksheetSummaryView,
  selectRunSummaryView,
  selectPartManagementSummaryView,
};
