import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnDef, RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button } from '@blueprintjs/core';
import classNames from 'classnames';
import { debounce, keyBy, mapValues } from 'lodash';
import Table, { ParamsChangeFn } from 'components/Table';
import styles from './index.module.css';
import {
  Part,
  SetupField,
  useSUITFormPartsLazyQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import { tableViewSlice } from '../../reducers/tableView';
import { globalDebounceTime } from '../../constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelected: (part: Part, sf: SetupField) => void;
  setupField: SetupField;
  partId?: number;
}

export default (props: Props) => {
  const { partId, setupField, onSelected, isOpen, onClose } = props;
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const [parts, setParts] = useState<Part[]>([]);
  const [isSelectDisabled, setIsSelectDisabled] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const columnHelper = createColumnHelper<Part>();
  const columns = [
    columnHelper.accessor('description', {
      header: 'Description',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    columnHelper.accessor('part_number', {
      header: 'Part Number',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    columnHelper.accessor('serial_number', {
      header: 'Serial Number',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    columnHelper.accessor('mileage', {
      header: 'Mileage',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    columnHelper.accessor('organization_name', {
      header: 'Organization',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    columnHelper.accessor('team_name', {
      header: 'Team',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    columnHelper.accessor('series', {
      header: 'Series',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
  ] as ColumnDef<Part>[];

  // Query Part for specific Part Config ID
  const [getParts, { data: partData }] = useSUITFormPartsLazyQuery();

  useEffect(() => {
    setParts(partData?.parts.rows as Part[] ?? [] as Part[]);
    setTotalCount(partData?.parts.totalCount || 0);
  }, [partData]);

  useEffect(() => {
    if (selectedRowIndex === -1) {
      setIsSelectDisabled(true);
    } else {
      setIsSelectDisabled(false);
    }
  }, [selectedRowIndex]);

  const onSubmit = () => {
    if (selectedRowIndex >= 0) {
      onSelected(parts[selectedRowIndex], setupField);
    }
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    dispatch(tableViewSlice.actions.setPartsSummaryView({ filters, sorting }));

    getParts({
      variables: {
        input: {
          filters: {
            ...mapValues(keyBy(filters, 'id'), 'value'),
            part_config: setupField?.part_config?.id ? [setupField.part_config.id] : null,
          },
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const onSelectionChange = (rowIndex: RowSelectionState) => {
    setSelectedRowIndex(Object.keys(rowIndex)[0] ? Number(Object.keys(rowIndex)[0]) : -1);
  };
  const debouncedOnTableParamsChange = debounce(onTableParamsChange, globalDebounceTime);

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isOpen={isOpen}
      onClose={onClose}
      title={`Select "${setupField?.part_config?.name || ''}" Part`}
      style={{ width: '80%', maxWidth: 'none' }}
    >
      <DialogBody className={styles.dialogBodyPadding}>
        <Table
          id={`part-selector${setupField?.part_config?.id || ''}`}
          columns={columns}
          data={parts}
          enableRowSelection
          enableSingleRowSelection
          onRowSelect={onSelectionChange}
          enablePagination
          enableHiding
          manualPagination
          manualSorting
          manualFiltering
          persistColumnVisibility
          onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
          totalRowCount={totalCount}
          highlightedRows={[partId || -1]}
          frozenBgColor="#252a31"
          headerStickyTop="15px"
          footerStickyBottom="-15px"
        />
      </DialogBody>
      <DialogFooter
        actions={[
          <Button
            key="cancel"
            onClick={onClose}
            text="Cancel"
          />,
          <Button
            key="select-part-btn"
            disabled={isSelectDisabled}
            intent={Intent.PRIMARY}
            onClick={onSubmit}
            text="Select"
          />,
        ]}
      />
    </Dialog>
  );
};
