import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { find, toUpper } from 'lodash';
import { H3, Intent } from '@blueprintjs/core';
import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';

import {
  useEightPostsLazyQuery,
  useDriveFilesLazyQuery,
  useDriversLazyQuery,
  useSimStatesLazyQuery,
  useLapTimeDataLazyQuery,
  useLapWindDataLazyQuery,
  useDeleteEightPostMutation,
  useDeleteDriveFileMutation,
  useDeleteDriverMutation,
  useDeleteLapWindDataMutation,
  useDeleteLapTimeDataMutation,
  useDeleteSimStatesMutation,
} from 'graphql/generated/graphql';
import LinkButton from 'components/LinkButton';
import SimDocumentNavMenu from '../SimDocumentNavMenu';
import Table, { RowActions } from 'components/Table';
import { useAlert } from 'components/Alert';
import ClickableCell from 'components/ClickableCell';
import { simDocumentCategories, seriesItems, driverSimTypes } from '../../../constants';
import { baseName } from 'config';
import { SimDocumentType, SimDocumentCategory, FilterType } from '../../../types';
import AppToaster from '../../../helpers/toaster';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import styles from './index.module.css';

type SimDocument = {
  id: number;
  desc: string;
  name: string;
  track? : string;
  organization_name: string;
  team_name?: string;
  series: string;
  owner: string;
  created_at?: string;
  updated_at?: string;
}

export default () => {
  const params = useParams();
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<SimDocumentCategory | null>(null);
  const [selectedConfig, setSelectedConfig] = useState<SimDocumentType | null>(null);
  const [tableData, setTableData] = useState<SimDocument[]>([]);
  const alert = useAlert();

  useDocumentTitle(
    selectedConfig && selectedConfig.displayName ? `${selectedConfig.displayName}` : 'Apex Setup'
  );

  const [deleteEightPost] = useDeleteEightPostMutation();
  const [deleteDriveFile] = useDeleteDriveFileMutation();
  const [deleteDriver] = useDeleteDriverMutation();
  const [deleteLapWindData] = useDeleteLapWindDataMutation();
  const [deleteLapTimeData] = useDeleteLapTimeDataMutation();
  const [deleteSimStates] = useDeleteSimStatesMutation();

  const renderDescCell = (id: number, name: string) => {
    const handleClick = (newTab?: boolean) => {
      if (!selectedCategory?.name || !selectedConfig?.name) {
        AppToaster.show({
          intent: Intent.WARNING,
          message: 'Navigation failed: Missing document category or configuration',
        });
        return;
      }

      const url = `/sims/documents/category/${selectedCategory.name}/${selectedConfig.name}/${id}`;

      if (newTab) {
        const basePath = baseName === '/' ? '' : baseName;
        window.open(`${basePath}${url}`, '_blank');
      } else {
        navigate(url);
      }
    };

    return (
      <ClickableCell
        value={name}
        onClick={handleClick}
      />
    );
  };

  const columnHelper = createColumnHelper<SimDocument>();
  const columns = selectedConfig?.name === 'driver' ? [
    columnHelper.accessor('desc', {
      header: 'Description',
      cell: info => renderDescCell(info.row.original.id, info.getValue()),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('name', {
      header: 'Type',
      cell: info => toUpper(info.getValue()),
      enableColumnFilter: true,
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: driverSimTypes,
          multiSelect: true,
        },
      },
      filterFn: (row, columnId, filterValue) => {
        return Array.isArray(filterValue) && (filterValue.length === 0 || filterValue.includes(row.getValue(columnId)));
      },
    }),
    columnHelper.accessor('track', {
      header: 'Track',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('organization_name', {
      header: 'Organization',
      cell: info => info.getValue() as string,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('team_name', {
      header: 'Team',
      cell: info => info.getValue() as string,
      enableColumnFilter: true,
      size: 100,
    }),
    columnHelper.accessor('series', {
      header: 'Series',
      cell: info => {
        const getSeries = find(seriesItems, series => series.value === info.getValue());
        if (!getSeries) return '';
        return getSeries.label as string;
      },
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: seriesItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
      filterFn: (row, columnId, filterValue) => {
        return Array.isArray(filterValue) && (filterValue.length === 0 || filterValue.includes(row.getValue(columnId)));
      },
      size: 100,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as AccessorKeyColumnDef<SimDocument>[] : [
    columnHelper.accessor('desc', {
      header: 'Description',
      cell: info => renderDescCell(info.row.original.id, info.getValue()),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('name', {
      header: 'Track',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('organization_name', {
      header: 'Organization',
      cell: info => info.getValue() as string,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('team_name', {
      header: 'Team',
      cell: info => info.getValue() as string,
      enableColumnFilter: true,
      size: 100,
    }),
    columnHelper.accessor('series', {
      header: 'Series',
      cell: info => {
        const getSeries = find(seriesItems, series => series.value === info.getValue());
        if (!getSeries) return '';
        return getSeries.label as string;
      },
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: seriesItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
      filterFn: (row, columnId, filterValue) => {
        return Array.isArray(filterValue) && (filterValue.length === 0 || filterValue.includes(row.getValue(columnId)));
      },
      size: 100,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as AccessorKeyColumnDef<SimDocument>[];

  const [getEightPosts] = useEightPostsLazyQuery({
    onCompleted: data => setTableData(data.eightPosts as SimDocument[]),
  });

  const [getDrivers] = useDriversLazyQuery({
    onCompleted: data => setTableData(data.drivers as SimDocument[]),
  });

  const [getLapTimeData] = useLapTimeDataLazyQuery({
    onCompleted: data => setTableData(data.lapTimeDatas as SimDocument[]),
  });

  const [getDriveFiles] = useDriveFilesLazyQuery({
    onCompleted: data => setTableData(data.driveFiles as SimDocument[]),
  });

  const [getLapWindData] = useLapWindDataLazyQuery({
    onCompleted: data => setTableData(data.lapWindDatas as SimDocument[]),
  });

  const [getSimStates] = useSimStatesLazyQuery({
    onCompleted: data => setTableData(data.simStates as SimDocument[]),
  });

  useEffect(() => {
    if (params.categoryName && params.configName) {
      const category = find(simDocumentCategories, (o) => (o.name === params.categoryName));
      if (category) {
        const type = find(category.types, (o) => (o.name === params.configName));
        if (type) setSelectedConfig(type as SimDocumentType);
      }
    }
  }, [params.configName]);

  useEffect(() => {
    if (params.categoryName) {
      const category = find(simDocumentCategories, (o) => (o.name === params.categoryName));
      if (category) {
        setSelectedCategory(category as SimDocumentCategory);
      }
    }
  }, [params.categoryName]);

  useEffect(() => {
    if (selectedConfig) {
      switch (selectedConfig.name) {
        case 'eight_post':
          getEightPosts({ fetchPolicy: 'no-cache' });
          break;
        case 'driver':
          getDrivers({ fetchPolicy: 'no-cache' });
          break;
        case 'lap_time_data':
          getLapTimeData({ fetchPolicy: 'no-cache' });
          break;
        case 'lap_wind_data':
          getLapWindData({ fetchPolicy: 'no-cache' });
          break;
        case 'drive_file':
          getDriveFiles({ fetchPolicy: 'no-cache' });
          break;
        case 'sim_states':
          getSimStates({ fetchPolicy: 'no-cache' });
          break;
        default:
          setTableData([]);
          break;
      }
    }
  }, [selectedConfig]);

  const rowActions: RowActions<SimDocument> = [
    {
      label: 'Edit',
      value: row => {
        if (selectedCategory && selectedConfig) {
          navigate(`/sims/documents/category/${selectedCategory.name}/${selectedConfig.name}/${row.original.id}`);
        }
      },
    },
    {
      label: 'Clone',
      value: row => {
        if (selectedCategory && selectedConfig) {
          navigate(`/sims/documents/category/${selectedCategory.name}/${selectedConfig.name}/clone/${row.original.id}`);
        }
      },
    },
    {
      intent: Intent.DANGER,
      label: 'Delete',
      value: row => {
        alert.showAlert(`Delete "${row.original.desc}"?`, {
          intent: Intent.DANGER,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
        }).then((yes) => {
          if (!yes || !selectedConfig) return;

          let deleteMutation;
          const variables = { id: row.original.id };

          switch (selectedConfig.name) {
            case 'eight_post':
              deleteMutation = deleteEightPost;
              break;
            case 'driver':
              deleteMutation = deleteDriver;
              break;
            case 'drive_file':
              deleteMutation = deleteDriveFile;
              break;
            case 'lap_time_data':
              deleteMutation = deleteLapTimeData;
              break;
            case 'lap_wind_data':
              deleteMutation = deleteLapWindData;
              break;
            case 'sim_states':
              deleteMutation = deleteSimStates;
              break;
            default:
              console.error('Unknown document type'); // eslint-disable-line no-console
              return;
          }

          deleteMutation({ variables })
            .then(() => {
              AppToaster.show({
                intent: Intent.SUCCESS,
                message: 'Document successfully deleted',
              });
              // Refresh the data after deletion
              if (selectedConfig) {
                switch (selectedConfig.name) {
                  case 'eight_post':
                    getEightPosts({ fetchPolicy: 'no-cache' });
                    break;
                  case 'driver':
                    getDrivers({ fetchPolicy: 'no-cache' });
                    break;
                  case 'lap_time_data':
                    getLapTimeData({ fetchPolicy: 'no-cache' });
                    break;
                  case 'lap_wind_data':
                    getLapWindData({ fetchPolicy: 'no-cache' });
                    break;
                  case 'drive_file':
                    getDriveFiles({ fetchPolicy: 'no-cache' });
                    break;
                  case 'sim_states':
                    getSimStates({ fetchPolicy: 'no-cache' });
                    break;
                  default:
                    break;
                }
              }
            })
            .catch((error) => {
              AppToaster.show({
                intent: Intent.DANGER,
                message: `Error deleting document: ${error.message}`,
              });
            });
        });
      },
    },
  ];

  if (!selectedCategory || !selectedConfig) return null;

  return (
    <div className={styles.container}>
      <SimDocumentNavMenu />
      <div className={styles.content}>
        <div className={styles.header}>
          <H3>{selectedConfig.displayName}</H3>
          <LinkButton
            buttonProps={{
              icon: 'cube-add',
              intent: Intent.PRIMARY,
              text: 'Add Document',
            }}
            to={{
              pathname: '/sims/documents/add',
              search: `?type=${selectedConfig.name}`,
            }}
          />
        </div>
        <Table
          columns={columns}
          data={tableData}
          enablePagination
          id={`${selectedConfig}_sim_documents`}
          key={selectedConfig.name}
          persistColumnVisibility
          rowActions={rowActions}
          totalRowCount={tableData.length}
        />
      </div>
    </div>
  );
};
