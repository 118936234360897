import { Button, EditableText, FormGroup, Intent, Tree, TreeNodeInfo } from '@blueprintjs/core';
import styles from './index.module.css';
import { useParams } from 'react-router-dom';
import { FormProvider, set, useForm } from 'react-hook-form';
import {
  Part,
  SetupField,
  Sweep,
  SweepGroup,
  SweepGroupInput,
  SweepMethod,
  SweepParameter,
  SweepParameterApplicationMethod,
  SweepParameterGenerationMethod,
  SweepParameterInput,
  SweepPart,
  SweepPartInput,
  UpdateSweepInput,
  useSetupFieldsQuery,
  useSweepByIdQuery,
  useSweepParameterOptionsQuery,
  useUpdateSweepMutation,
} from 'graphql/generated/graphql';
import { cloneDeep, toString, toNumber } from 'lodash';
import { useCallback, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Accordion from 'components/Accordion';
import { RHFNumericInput, RHFTextInput } from 'components/RHFInputs';
import SweepPartRow from './SweepPartRow';
import SweepParameterRow from './SweepParameterRow';
import AppToaster from 'helpers/toaster';
import SweepPayloadTable from './SweepPayloadTable';
import SweepGroupRow from './SweepGroupRow';
import { SweepPayloadGroup } from './types';
import ImportSweepButton from './ImportSweepButton';
import { ApiRequest } from 'api';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export type SweepForm = {
  name?: string;
  description?: string | null;
  method: SweepMethod;
  numRun?: number;
  parameters?: Partial<SweepParameter>[];
  parts?: Partial<SweepPart>[];
  data?: string;
  sweep_groups?: Partial<SweepGroup>[];
}

const nodes: TreeNodeInfo[] = [
  {
    id: 0,
    hasCaret: false,
    label: 'Config',
  },
  {
    id: 1,
    hasCaret: false,
    label: 'Sweep Payload',
  },
];

export default () => {
  const params = useParams();
  const sweepId = Number(params.sweepId);
  const [hasPayload, setHasPayload] = useState<boolean>();
  const [sweep, setSweep] = useState<Sweep>();
  const [sweepPayload, setSweepPayload] = useState<SweepPayloadGroup[] | null>();
  const [setupFields, setSetupFields] = useState<SetupField[]>([]);
  const [parameterOptions, setParameterOptions] = useState<{ label: string, value: string }[]>([]);
  const prevIsDirty = useRef<boolean>(false);

  useDocumentTitle(
    sweep && sweep.name ? `${sweep.name}` : 'Apex Setup'
  );

  const form = useForm<SweepForm>({
    defaultValues: {
      name: '',
      description: '',
      method: SweepMethod.RANDOM,
      numRun: 0,
      data: '',
      parameters: [],
      parts: [],
      sweep_groups: [],
    },
  });

  const { setValue, getValues, watch, formState: { isDirty } } = form;

  useEffect(() => {
    if (prevIsDirty.current === false && isDirty && hasPayload === false) {
      AppToaster.show({
        intent: Intent.WARNING,
        message: 'Sweep is out of sync with what is saved in the database. Please save changes before running sims to be back in sync.',
      });
    }

    prevIsDirty.current = isDirty;
  }, [isDirty, form.formState]);

  const [updateSweep] = useUpdateSweepMutation();

  useSetupFieldsQuery({
    onCompleted: data => setSetupFields(data.setupFields.rows as SetupField[]),
  });

  useSweepParameterOptionsQuery({
    variables: { specs: ['gen7'] },
    onCompleted: data => setParameterOptions(data.sweepParameterOptions.map(p => ({ label: p.name, value: p.path }))),
  });

  const { loading } = useSweepByIdQuery({
    variables: { id: sweepId },
    skip: !sweepId,
    onCompleted: data => {
      if (data.sweep) {
        const sweepParameters = data.sweep.parameters as SweepParameter[];
        const payload = data.sweep.data?.payload;
        const sweepParts = data.sweep.parts?.map(sp => {
          const parts = sp.parts?.map(part => part as Part);
          return {
            id: sp.id,
            enabled: sp.enabled,
            setup_field_id: (sp.setup_field as SetupField).id,
            setup_field: sp.setup_field as SetupField,
            position: sp.position,
            parts,
            sweep_group_id: sp.sweep_group_id,
            sweep_id: sp.sweep_id,
          };
        });
        const sweepGroups = data.sweep.sweep_groups?.map(sg => {
          const parts = sg.parts?.map(sp => {
            return sp as SweepPart;
          });
          return {
            id: sg.id,
            enabled: sg.enabled,
            name: sg.name,
            parameters: sg.parameters,
            parts,
          };
        });
        const sweepPayloadText = payload ? JSON.stringify(payload) : undefined;
        setSweep({
          id: data.sweep.id,
          method: data.sweep.method,
          name: data.sweep.name,
          description: data.sweep.description,
          owner: data.sweep.owner,
          num_run: data.sweep.num_run,
          parameters: sweepParameters,
          parts: sweepParts,
          sweep_groups: sweepGroups,
          data: sweepPayloadText,
          organization_id: data.sweep.organization_id,
          organization_name: data.sweep.organization_name,
          series: data.sweep.series,
        });
        setHasPayload(!!payload);
        setSweepPayload(payload);
        form.reset({
          name: data.sweep.name,
          description: data.sweep.description,
          method: data.sweep.method,
          numRun: data.sweep.num_run ?? undefined,
          parameters: sweepParameters,
          parts: sweepParts,
          data: sweepPayloadText,
          sweep_groups: sweepGroups,
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const method = watch('method');
  const numRun = watch('numRun');
  const parameters = watch('parameters');
  const parts = watch('parts');
  const groups = watch('sweep_groups');

  const onSweepMetaChange = (target: string, value: string | number) => {
    if (!sweep) return;

    const newSweep = cloneDeep(sweep);
    set(newSweep, target, value);
    setSweep(newSweep);
  };

  const sweepPartToSweepPartInput = (sweepPart: Partial<SweepPart>): SweepPartInput => {
    return {
      id: sweepPart.id,
      enabled: sweepPart.enabled,
      setup_field_id: sweepPart.setup_field_id,
      parts: sweepPart.parts?.map(p => { return { id: p.id }; }),
      position: sweepPart.position,
      sweep_group_id: sweepPart.sweep_group_id,
      sweep_id: sweepPart.sweep_id,
    } as SweepPartInput;
  };

  const sweepParameterToSweepParameterInput = (sweepParameter: Partial<SweepParameter>): SweepParameterInput => {
    return {
      ...sweepParameter,
      min: toNumber(sweepParameter.min),
      inc: toNumber(sweepParameter.inc),
      max: toNumber(sweepParameter.max),
    } as SweepParameterInput;
  };

  const warnOutOfSync = () => {
    if (!isDirty && hasPayload) {
      AppToaster.show({
        intent: Intent.WARNING,
        message: 'Sweep Config is out of sync with the DB.  Please save before running sims to be in sync.',
      });
    }
  };

  const onSubmit = useCallback((input: SweepForm) => {
    if (sweep) {
      const sweepParameters = input.parameters?.filter(sp => !sp.sweep_group_id)?.map(sp => sweepParameterToSweepParameterInput(sp));
      const sweepParts = input.parts?.filter(sp => !sp.sweep_group_id)?.map(sp => sweepPartToSweepPartInput(sp));
      const sweepGroups = input.sweep_groups?.map(sg => {
        const sweepGroupParts = sg.parts?.map(sp => sweepPartToSweepPartInput(sp));
        const sweepGroupParameters = sg.parameters?.map(sp => sweepParameterToSweepParameterInput(sp));
        return {
          ...sg,
          sweep_id: sweep.id,
          parameters: sweepGroupParameters,
          parts: sweepGroupParts,
        } as SweepGroupInput;
      });
      const updateSweepInput: UpdateSweepInput = {
        id: sweep.id,
        name: input.name || '',
        description: input.description,
        method: input.method,
        num_run: toNumber(input.numRun),
        parameters: sweepParameters,
        parts: sweepParts,
        data: sweepPayload ? { payload: sweepPayload } : null,
        sweep_groups: sweepGroups,
      };

      updateSweep({
        variables: {
          input: {
            ...updateSweepInput,
          },
        },
        onCompleted: () => {
          setHasPayload(!!sweepPayload);
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Sweep successfully updated',
          });

          form.reset({ ...input });
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error updating Sweep: ${e.message}`,
          });
        },
      });
    }
  }, [sweep, sweepPayload]);

  const handleAddParameter = () => {
    warnOutOfSync();
    const currentParameters = getValues('parameters') ?? [];
    const newParameter: Partial<SweepParameter> = {
      enabled: true,
      name: '',
      param_application_method: SweepParameterApplicationMethod.ABSOLUTE,
      param_generation_method: SweepParameterGenerationMethod.STEP,
      path: '',
      sweep_group_id: undefined,
    };

    setValue('parameters', [...currentParameters, newParameter], { shouldDirty: true });
  };

  const handleAddPart = () => {
    warnOutOfSync();
    const currentParts = getValues('parts') ?? [];
    const newPart: Partial<SweepPart> = {
      enabled: true,
      setup_field: undefined,
      parts: [],
      position: undefined,
      sweep_id: sweepId,
      sweep_group_id: undefined,
    };

    setValue('parts', [...currentParts, newPart], { shouldDirty: true });
  };

  const handleAddGroup = () => {
    warnOutOfSync();
    const currentGroups = getValues('sweep_groups') ?? [];
    const newGroup: Partial<SweepGroup> = {
      enabled: true,
      name: '',
      parameters: [],
      parts: [],
    };

    setValue('sweep_groups', [...currentGroups, newGroup], { shouldDirty: true });
  };

  const handleNodeClick = useCallback((node: TreeNodeInfo) => {
    const sectionElement = document.getElementById(`section-${node.id}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const generatePayload = async () => {
    const formData = form.getValues();
    const { data } = await ApiRequest.post(`/sweep/${sweepId}/generate`, { sweep: { ...formData, num_run: Number(formData.numRun) } });
    const generatedData = data.data as SweepPayloadGroup[];
    handleUpdateSweepPayload(generatedData);
    AppToaster.show({
      intent: Intent.WARNING,
      message: 'Sweep Payload may be out of sync with what is saved in the database. Please save changes to be back in sync.',
    });
  };

  const handleUpdateSweepPayload = (newPayload: SweepPayloadGroup[] | null) => {
    setSweepPayload(newPayload);
    setValue('data', JSON.stringify(newPayload), { shouldDirty: true });
  };

  return (
    <FormProvider {...form}>
      <form className={styles.mainForm} onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.titleBar}>
          <div className={styles.titleContainer}>
            <div className={styles.titleColumn}>
              <div className={styles.titleLabel}>Name</div>
              <EditableText
                className={styles.titleValue}
                value={sweep?.name}
                onChange={value => {
                  onSweepMetaChange('name', value);
                  setValue('name', value, { shouldDirty: true });
                }}
                placeholder="Sweep name"
              />
            </div>
          </div>
          <div className={styles.titleColumnDesc}>
            <div className={styles.titleLabel}>Description</div>
            <EditableText
              className={styles.titleValue}
              value={sweep?.description || ''}
              onChange={value => {
                onSweepMetaChange('description', value);
                setValue('description', value, { shouldDirty: true });
              }}
              minWidth={2}
              placeholder="Description"
            />
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.nav}>
            <Button
              className={styles.saveButton}
              intent={Intent.PRIMARY}
              type="submit"
              text="Save"
              fill
              loading={loading}
              disabled={!isDirty}
            />
            <div className={styles.sideNavContainer}>
              <Tree
                contents={nodes}
                onNodeClick={handleNodeClick}
              />
            </div>
            {sweep?.method !== SweepMethod.IMPORT && (
              <Button
                className={styles.saveButton}
                intent={Intent.NONE}
                type="button"
                disabled={((numRun ?? 0) > 10000)}
                text="Generate Payload"
                fill
                onClick={generatePayload}
                loading={loading}
              />
            )}
            {sweep?.method === SweepMethod.IMPORT && (
              <ImportSweepButton setSweepPayload={handleUpdateSweepPayload} />
            )}
          </div>
          <div className={styles.sweepContainer}>
            <Accordion
              className={styles.itemContainer}
              id="section-0"
              initialOpen
              key="config"
              title="Config"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.sweepInput}
                    label="Description"
                    inline
                  >
                    <RHFTextInput
                      controllerProps={{
                        name: 'description',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    label="Method"
                    inline
                  >
                    <span>{method && SweepMethod[method]}</span>
                  </FormGroup>
                </div>
                {sweep?.method === SweepMethod.RANDOM && (
                  <div className={styles.inputRow}>
                    <FormGroup
                      className={classNames(styles.formGroup, styles.flex)}
                      label="Number of Runs"
                      inline
                    >
                      <RHFNumericInput
                        controllerProps={{
                          name: 'numRun',
                        }}
                        inputProps={{
                          fill: true,
                          value: toString(numRun),
                        }}
                        useStringValue
                      />
                    </FormGroup>
                  </div>
                )}
                {sweep?.method === SweepMethod.FULLFACTORIAL && (
                  <>
                    <div className={styles.groupHeader}>
                      <div className={styles.addButton}>
                        <Button
                          intent={Intent.PRIMARY}
                          type="button"
                          text="Add Group"
                          icon="plus"
                          small
                          onClick={handleAddGroup}
                        />
                      </div>
                    </div>
                    <div className={styles.group}>
                      {groups?.map((sg, index) => {
                        return (
                          <SweepGroupRow
                            group={sg}
                            groupIndex={index}
                            parameterOptions={parameterOptions}
                            setupFields={setupFields}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
                {sweep?.method === SweepMethod.RANDOM && (
                  <Accordion
                    className={styles.itemContainer}
                    initialOpen
                    key="parameters"
                    title="Parameters"
                    buttonProps={{
                      className: styles.accordionHeader,
                    }}
                  >
                    <div className={styles.sectionContainer}>
                      <div className={styles.headerRow}>
                        <div className={styles.inline}>
                          <div className={styles.addButton}>
                            <Button
                              intent={Intent.PRIMARY}
                              type="button"
                              text="Add Parameter"
                              fill
                              icon="plus"
                              small
                              onClick={handleAddParameter}
                            />
                          </div>
                        </div>
                        <div className={styles.inlineCheckbox}> </div>
                        <div className={styles.inlineCheckbox}> </div>
                        <h6 className={classNames(styles.inline, styles.pathHeader)}>Path</h6>
                        <h6 className={styles.inlineSelect}>Absolute/Delta</h6>
                        <h6 className={styles.inlineSmall}>Min</h6>
                        <h6 className={styles.inlineSmall}>Max</h6>
                        <div className={styles.inlineCheckbox}> </div>
                      </div>
                      {parameters?.map((p, index) => {
                        return (
                          <SweepParameterRow
                            sweepParameter={p}
                            index={index}
                            parameterOptions={parameterOptions}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                )}
                {sweep?.method === SweepMethod.RANDOM && (
                  <Accordion
                    className={styles.itemContainer}
                    initialOpen
                    key="parts"
                    title="Parts"
                    buttonProps={{
                      className: styles.accordionHeader,
                    }}
                  >
                    <div className={styles.sectionContainer}>
                      <div className={styles.headerRow}>
                        <div className={styles.inline}>
                          <div className={styles.addButton}>
                            <Button
                              intent={Intent.PRIMARY}
                              type="button"
                              text="Add Part"
                              fill
                              icon="plus"
                              small
                              onClick={handleAddPart}
                            />
                          </div>
                        </div>
                        <div className={styles.inlineCheckbox}> </div>
                        <h6 className={classNames(styles.inline, styles.partsHeader)}>Part(s)</h6>
                        <h6 className={styles.inlineSelect}>Position</h6>
                        <div className={styles.inlineCheckbox}> </div>
                      </div>
                      {parts?.map((p, index) => {
                        return (
                          <SweepPartRow
                            sweepPart={p}
                            index={index}
                            setupFields={setupFields}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                )}
              </div>
            </Accordion>
            <div id="section-1" className={styles.groupHeader}>
              <span className={styles.inline}>Sweep Payload</span>
              {sweepPayload && (
                <div className={styles.rightButton}>
                  <Button
                    intent={Intent.DANGER}
                    type="button"
                    icon="cross"
                    minimal
                    onClick={() => handleUpdateSweepPayload(null)}
                  />
                </div>
              )}
            </div>
            {sweepPayload && (
              <div className={styles.sweepSectionContainer}>
                <SweepPayloadTable
                  sweepPayload={sweepPayload}
                  setSweepPayload={handleUpdateSweepPayload}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
