import React, { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import { H4 } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

import { SetupField, Setup } from 'graphql/generated/graphql';
import SetupCard from './index';
import { SetupSelection, SetupUITemplate } from 'types';
import { SetupTemplateMeta } from '../../pages/SetupCompare';
import styles from './index.module.css';

interface SortableSetupCardProps {
  setup: SetupSelection;
  setupFields: SetupField[];
  template: SetupUITemplate;
  templateMeta: SetupTemplateMeta;
  color: string;
  onContainerCollapse: (id: string | undefined, newOpenState: boolean) => void;
}

export default (props: SortableSetupCardProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.setup.branch.id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 2 : 1,
    position: 'relative' as const,
    cursor: 'grab',
  };

  const renderDraggableHeader = () => {
    const compareHeader = props.setup.branch.name ? `${props.setup.setup.name} - ${props.setup.branch.name}` : props.setup.setup.name;
    return (
      <div
        {...attributes}
        {...listeners}
        className={styles.draggableHeader}
      >
        <Tooltip2
          content={compareHeader}
          className={styles.headerTooltip}
        >
          <H4
            style={{ color: props.color || 'white' }}
            className={styles.setupLabel}
          >
            {compareHeader}
          </H4>
        </Tooltip2>
      </div>
    );
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={classNames({
        [styles.dragging]: isDragging,
      })}
    >
      <SetupCard
        key={`compare-setup-${props.setup.branch.id}`}
        setup={props.setup.setup as Setup}
        branchName={props.setup.branch.name}
        setupFields={props.setupFields}
        template={props.template}
        templateMeta={props.templateMeta[props.setup.branch.id]}
        color={props.color}
        onContainerCollapse={props.onContainerCollapse}
        customHeader={renderDraggableHeader()}
      />
    </div>
  );
};
