import React from 'react';
import styles from './index.module.css';

interface Props {
  value: React.ReactNode;
  onClick: (newTab?: boolean) => void;
}

export default (props: Props) => {
  const { value, onClick } = props;

  const handleClick = (e: React.MouseEvent) => {
    // Check if Ctrl key (or Cmd key on Mac) is pressed
    const newTab = e.ctrlKey || e.metaKey;
    onClick(newTab);
  };

  return (
    <span
      className={styles.clickableCell}
      onClick={handleClick}
    >
      {value}
    </span>
  );
};
