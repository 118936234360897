import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useSearchParams,
  useRouteLoaderData,
  useBlocker,
  BlockerFunction,
} from 'react-router-dom';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  Card,
  Button,
  Elevation,
  Checkbox,
  Callout,
  Intent,
  Divider,
  Overlay,
  Spinner,
} from '@blueprintjs/core';
import _, { startCase, isEqual, get, map, toString } from 'lodash';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';

import Select from 'components/Select';
import SetupCard, { TemplateMeta } from 'components/SetupCard';
import SortableSetupCard from 'components/SetupCard/SortableSetupCard';
import {
  Setup,
  SetupBranch,
  SetupField,
  SetupFieldType,
  useSetupBranchesByIdsQuery,
  useSetupFieldsQuery,
  useSUITByIdQuery,
  useSUITsQuery,
  useCommitSetupMutation,
  SetupByBranchIdDocument,
  SetupByIdDocument,
  SetupBranchesByRootIdDocument,
} from 'graphql/generated/graphql';
import { generateTemplateMeta, getContainerPathKeys, suitPathToKey } from 'helpers/suit';
import { selectActiveSUITId, SUITSlice } from 'reducers/suit';
import { selectSetupCompareState, SetupCompareSlice } from 'reducers/setupCompare';
import { selectSetupColorsState } from '../../reducers/setupColors';
import {
  SetupUITemplate,
  SetupUITemplateContainerItem,
  SetupUITemplateItem,
  SetupUITemplateItemType,
  SelectItem,
  SetupSelection,
  SUITNoTemplate,
  SetupUITemplateFieldItem,
} from 'types';
import AppToaster from 'helpers/toaster';
import { SetupToSetupInput } from 'helpers/converter';
import { hasPermission } from '../../helpers/permissions';
import { PermissionName } from '../../constants';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

export enum HighlightOption {
  DIFFERENCES,
  EQUALITIES,
  NONE,
}
const HIGHLIGHT_OPTIONS = [
  { value: HighlightOption.NONE, label: 'No Highlighting' },
  { value: HighlightOption.DIFFERENCES, label: 'Highlight Differences' },
  { value: HighlightOption.EQUALITIES, label: 'Highlight Equalities' },
];

export enum ShowOption {
  ALL,
  DIFFERENCES,
}
const SHOW_OPTIONS = [
  { value: ShowOption.ALL, label: 'Show All' },
  { value: ShowOption.DIFFERENCES, label: 'Show Differences' },
];

export enum CompareOption {
  BASELINE,
  SEQUENTIAL,
}
const COMPARE_OPTIONS = [
  { value: CompareOption.BASELINE, label: 'Baseline' },
  { value: CompareOption.SEQUENTIAL, label: 'Sequential' },
];

interface SectionSelections {
  [key: string]: boolean;
}

export interface SetupTemplateMeta {
  [key: string]: TemplateMeta;
}

type DragEndEventType = typeof DragEndEvent

export default () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const branchId = Number(params.branchId);
  const compareIds = searchParams.get('ids');
  const [branchIds, setBranchIds] = useState<number[]>([branchId, ...compareIds?.split(',').map(Number) ?? []]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { permissions } } = useRouteLoaderData('root') as any;

  const activeSUITId = useSelector(selectActiveSUITId);
  const activeSetupColorsState = useSelector(selectSetupColorsState);

  const [baselineSetup, setBaselineSetup] = useState<SetupSelection>();
  const [cleanBaselineSetup, setCleanBaselineSetup] = useState<SetupSelection>();
  const [isDirty, setIsDirty] = useState(false);
  const [setupFields, setSetupFields] = useState<SetupField[]>([]);
  const [selectedSetups, setSelectedSetups] = useState<SetupSelection[]>([]);
  const [selectedSections, setSelectedSections] = useState<SectionSelections>({});
  const [highlightOption, setHighlightOption] = useState(HIGHLIGHT_OPTIONS[1]);
  const [showOption, setShowOption] = useState(SHOW_OPTIONS[0]);
  const [compareOption, setCompareOption] = useState(COMPARE_OPTIONS[0]);
  const [activeSUITTemplate, setActiveSUIT] = useState<SetupUITemplate>();
  const [setupTemplateMeta, setSetupTemplateMeta] = useState<SetupTemplateMeta>();
  const [suitItems, setSUITItems] = useState<SelectItem<SUITNoTemplate>[]>([]);
  const storeSetups = useSelector(selectSetupCompareState);

  // Add handler for drag end
  const handleDragEnd = (event: DragEndEventType) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = selectedSetups.findIndex(setup => setup.branch.id === active.id);
    const newIndex = selectedSetups.findIndex(setup => setup.branch.id === over.id);

    const newSetups = [...selectedSetups];
    const [movedSetup] = newSetups.splice(oldIndex, 1);
    newSetups.splice(newIndex, 0, movedSetup);

    setSelectedSetups(newSetups);

    // Update Redux store
    const newCompareSetupIds = map(newSetups, (s: SetupSelection) => s.branch.id);
    const newSetupCompareState = { [branchId]: newCompareSetupIds };
    dispatch(SetupCompareSlice.actions.setSetupCompareState(newSetupCompareState));
  };

  // Add sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useDocumentTitle(
    cleanBaselineSetup && cleanBaselineSetup.setup && cleanBaselineSetup.setup.name ? `${cleanBaselineSetup.setup.name}` : 'Apex Setup'
  );

  const hasAllSetups = useMemo(() => {
    // Get all setup IDs we currently have
    const existingIds = new Set([
      baselineSetup?.branch.id,
      ...selectedSetups.map(s => s.branch.id),
    ].filter(Boolean));

    // Check if we're just reordering (all IDs exist) or if we need new data
    const needsNewData = branchIds.some(id => !existingIds.has(id));

    // Also check if the length matches to handle removal case
    const hasAllData = branchIds.length === existingIds.size;

    return !needsNewData && hasAllData;
  }, [branchIds, selectedSetups, baselineSetup]);

  useSetupFieldsQuery({
    onCompleted: data => setSetupFields(data.setupFields.rows as SetupField[]),
  });
  useSUITsQuery({
    onCompleted: data => {
      const items: SelectItem<SUITNoTemplate>[] = data.suits.rows.map(s => ({ label: s.name, value: s }));
      setSUITItems(items);
    },
  });
  useSetupBranchesByIdsQuery({
    variables: { branchIds },
    skip: hasAllSetups,  // Skip if we have all the setups already
    onCompleted: data => {
      const branchHeads = data.setupBranches.map(b => ({
        branch: {
          id: b.id,
          name: b.name,
        },
        setup: { ...b.head },
      }));
      const [baselineBranch, ...compareBranches] = branchHeads.reduce((acc, b) => {
        if (b.branch.id === branchId) acc.unshift(b);
        else acc.push(b);
        return acc;
      }, [] as SetupSelection[]);
      setBaselineSetup(baselineBranch);
      setCleanBaselineSetup(baselineBranch);

      if (compareBranches?.length) {
        // Use branchIds (from URL) to sort the setups in the correct order
        // Filter out the baseline branchId since it's handled separately
        const orderedBranchIds = branchIds.filter(id => id !== branchId);
        const orderedSetups = orderedBranchIds
          .map(id => compareBranches.find((setup: SetupSelection) => (setup.branch.id === id)))
          .filter(Boolean) as SetupSelection[];

        setSelectedSetups(orderedSetups);
      }
    },
    fetchPolicy: 'network-only',
  });
  const { loading: suitLoading } = useSUITByIdQuery({
    variables: { id: activeSUITId || -1 },
    skip: !activeSUITId,
    onCompleted: data => {
      if (data.suit) setActiveSUIT(data.suit.template);
    },
  });

  const [commitSetup] = useCommitSetupMutation();

  useEffect(() => {
    const baselineSetupData = get(baselineSetup, 'setup.data');
    const cleanBaselineSetupData = get(cleanBaselineSetup, 'setup.data');

    if (!baselineSetupData || !cleanBaselineSetupData) return;

    const isDirtyCheck = !isEqual(baselineSetupData, cleanBaselineSetupData);
    const prevIsDirty = isDirty;
    setIsDirty(isDirtyCheck);
    if (isDirtyCheck !== prevIsDirty) {
      if (isDirtyCheck) addWarningListener();
      else removeWarningListener();
    }
  }, [baselineSetup, cleanBaselineSetup]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  useBlocker(blockerFunc);

  useEffect(() => {
    if (activeSUITTemplate) {
      const partConfigIds: number[] = [];
      const handleSetupField = (field: SetupUITemplateFieldItem) => {
        const setupField = setupFields.find(sf => sf.name === field.setup_field);
        if (setupField?.part_config) partConfigIds.push(setupField.part_config.id);
      };

      const processTemplateItem = (item: SetupUITemplateItem) => {
        if (item.type === SetupUITemplateItemType.CONTAINER || item.type === SetupUITemplateItemType.GRID) {
          item.items.forEach(processTemplateItem);
        } else {
          handleSetupField(item);
        }
      };
      activeSUITTemplate.items.forEach(processTemplateItem);
    }
  }, [activeSUITTemplate]);

  const activeSUITSections = useMemo(() => {
    if (!activeSUITTemplate) return [];
    return _.compact(activeSUITTemplate.items.map(i => getContainerPathKeys([i]))).flat();
  }, [activeSUITTemplate]);

  const allSectionsSelected = useMemo(() => {
    return !_.toPairs(selectedSections).some(([, selected]) => !selected);
  }, [selectedSections]);

  const someSectionsSelected = useMemo(() => {
    return _.toPairs(selectedSections).some(([, selected]) => selected);
  }, [selectedSections]);

  const selectAllSections = (select: boolean) => {
    const newSelected = _.cloneDeep(selectedSections);
    _.each(activeSUITSections, section => { newSelected[section] = select; });
    setSelectedSections(newSelected);
  };

  const isSetupDataValueEqual = (baselineValue: unknown, compareValue: unknown, setupFieldType: SetupFieldType | undefined) => {
    switch (setupFieldType) {
      case SetupFieldType.BOOLEAN:
        return isEqual(Boolean(baselineValue), Boolean(compareValue));
      case SetupFieldType.FLOAT:
      case SetupFieldType.INT:
        return isEqual(Number(baselineValue), Number(compareValue));
      case SetupFieldType.STRING:
        return isEqual(toString(baselineValue), toString(compareValue));
      default:
        return isEqual(baselineValue, compareValue);
    }
  };

  // When (almost) any relevant data changes, regenerates the meta using the
  // configured settings (highlight, show option, etc).
  useEffect(() => {
    if (activeSUITTemplate && baselineSetup) {
      // Starts by generating a template meta where all containers and fields
      // are visible
      const meta: TemplateMeta = {
        containers: {},
        fields: {},
      };
      activeSUITTemplate.items.map(i => generateTemplateMeta([i], meta, setupFields));

      // Loops the container keys and uses `selectedSections` to determine
      // which to mark as non-visible.
      //
      // This applies to all setups, so this happens before cloning off for each
      // selected setup
      const containerKeys = Object.keys(meta.containers);
      for (let i = 0; i < containerKeys.length; i++) {
        const containerKey = containerKeys[i];
        if (!selectedSections[containerKey]) {
          meta.containers[containerKey].visible = false;
        }
      }

      // Loops through the fields for each setup and hides equal fields
      //
      // This applies to all setups, so this happens before cloning off for each
      // selected setup.
      const fieldPaths = Object.keys(meta.fields);
      if (showOption.value === ShowOption.DIFFERENCES) {
        for (let i = 0; i < fieldPaths.length; i++) {
          const fieldPath = fieldPaths[i];
          let baselineValue = get(baselineSetup.setup.data, fieldPath);

          for (let j = 0; j < selectedSetups.length; j++) {
            if ((compareOption.value === CompareOption.SEQUENTIAL) && (j > 0)) {
              baselineValue = get(selectedSetups, `[${j - 1}].setup.data.${fieldPath}`);
            }

            const compareSetup = selectedSetups[j];
            const compareValue = get(compareSetup.setup.data, fieldPath);

            if (!isSetupDataValueEqual(baselineValue, compareValue, meta.fields[fieldPath].fieldType)) {
              meta.fields[fieldPath].visible = true;
              break;
            }
            meta.fields[fieldPath].visible = false;
          }
        }
      }

      // Loops [baselineSetup, ...selectedSetups] and sets the highlighting
      // based on the option and comparative values
      const setupMetas: SetupTemplateMeta = {};
      const allSetups = [baselineSetup, ...selectedSetups];
      for (let i = 0; i < allSetups.length; i++) {
        const compareSetup = allSetups[i];
        if (!baselineSetup || !compareSetup) continue;

        const isBaseline = i === 0;
        const setupMeta = _.cloneDeep(meta);

        for (let j = 0; j < fieldPaths.length; j++) {
          const fieldPath = fieldPaths[j];

          const baselineValue = (!isBaseline && (compareOption.value === CompareOption.SEQUENTIAL))
            ? get(allSetups, `[${i - 1}].setup.data.${fieldPath}`)
            : get(baselineSetup.setup.data, fieldPath);

          const compareValue = get(compareSetup.setup.data, fieldPath);

          const areValuesEqual = isSetupDataValueEqual(baselineValue, compareValue, meta.fields[fieldPath].fieldType);

          if (!isBaseline && ((!areValuesEqual && (highlightOption.value === HighlightOption.DIFFERENCES))
            || (areValuesEqual && (highlightOption.value === HighlightOption.EQUALITIES)))) {
            const fieldStyle = setupMeta.fields[fieldPath].fieldType === SetupFieldType.BOOLEAN
              ? styles.highlightedFieldColor
              : styles.highlightedFieldValue;
            setupMeta.fields[fieldPath].inputClassName = fieldStyle;
          }
        }

        setupMetas[compareSetup.branch.id.toString()] = setupMeta;
      }

      setSetupTemplateMeta(setupMetas);
    }
  }, [baselineSetup, selectedSetups, activeSUITTemplate, selectedSections, highlightOption, showOption, compareOption]);

  // Enables all sections by default when a template changes
  useEffect(() => {
    selectAllSections(true);
  }, [activeSUITTemplate, activeSUITSections]);

  // Appends selected setup IDs to the URL query params
  useEffect(() => {
    if (storeSetups && storeSetups[branchId]) {
      let newCompareIds: number[] = [];
      if (storeSetups[branchId].length > 0) {
        newCompareIds = storeSetups[branchId];
      }
      setBranchIds([branchId, ...newCompareIds]);
    }
  }, [storeSetups]);

  const handleSelectSection = (section: string) => {
    const newSelections = _.cloneDeep(selectedSections);
    const existingSelection = _.get(selectedSections, section, false);
    newSelections[section] = !existingSelection;
    setSelectedSections(newSelections);
  };

  const onContainerCollapse = (id: string | undefined, newOpenState: boolean) => {
    if (id && setupTemplateMeta) {
      const newMetas = Object.keys(setupTemplateMeta).reduce((acc, setupId) => {
        const newMeta = _.cloneDeep(setupTemplateMeta[setupId]);
        newMeta.containers[id].open = newOpenState;
        acc[setupId] = newMeta;
        return acc;
      }, {} as SetupTemplateMeta);
      setSetupTemplateMeta(newMetas);
    }
  };

  const onSUITChange = (item: SelectItem<SUITNoTemplate>) => {
    dispatch(SUITSlice.actions.setActiveSUITId(item.value.id));
  };

  const handleNodeClick = (event: React.MouseEvent<HTMLSpanElement>, key: string) => {
    event.preventDefault();

    const sectionElement = document.getElementById(key);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'auto' });
    }
  };

  const getSetupColor = (branchId: number) => (activeSetupColorsState[branchId] || '#9E9E9E');

  const renderNavItem = (path: SetupUITemplateItem[]): ReactNode => {
    const item = path[path.length - 1];
    if (item.type === SetupUITemplateItemType.FIELD || item.type === SetupUITemplateItemType.GRID) return null;
    // Filter nav by permissions
    if (!hasPermission(PermissionName.SETUP_WRITE, permissions) && !hasPermission(PermissionName.SETUP_READ, permissions)) {
      if (item.name.toLowerCase() !== 'car') {
        if (!hasPermission(`setup_${item.name.toLowerCase()}_read`, permissions) && !hasPermission(`setup_${item.name.toLowerCase()}_write`, permissions)) return null;
      }
    }
    const depth = path.length - 1;
    const key = suitPathToKey(path as SetupUITemplateContainerItem[]);
    const checked = _.get(selectedSections, key, false);

    // Loops through the path, excluding the final (current) item, and
    // determines whether any ancestor in the current chain is checked. If so,
    // the current item checkbox is disabled
    const ancestorChecked = path.slice(0, -1).reduce((acc, node, currIndex, nodes) => {
      const ancestorPath = nodes.slice(0, currIndex + 1);
      const pathKey = suitPathToKey(ancestorPath as SetupUITemplateContainerItem[]);
      const ancestorPathChecked = _.get(selectedSections, pathKey, false);
      if (acc && !ancestorPathChecked) return false;
      return acc;
    }, true);

    const labelNode = (
      <span onClick={(event) => handleNodeClick(event, key)}>{item.label ?? startCase(item.name)}</span>
    );

    return (
      <React.Fragment key={key}>
        <Checkbox
          className={styles.sectionSelectCheckbox}
          checked={checked}
          disabled={path.length > 1 && !ancestorChecked}
          labelElement={labelNode}
          onChange={() => handleSelectSection(key)}
          style={{ marginLeft: depth * 15 }}
        />
        {item.items.map(i => renderNavItem([...path, i]))}
      </React.Fragment>
    );
  };

  const onSetupChange = (updatedSetup: Setup) => {
    if (baselineSetup && baselineSetup.branch) {
      const updatedBaseline: SetupSelection = {
        branch: baselineSetup?.branch,
        setup: updatedSetup,
      };
      setBaselineSetup(updatedBaseline);
    }
  };

  const onSave = async () => {
    if (!baselineSetup || !baselineSetup.branch) return;

    await commitSetup({
      variables: {
        branchId: baselineSetup.branch.id,
        setup: SetupToSetupInput(baselineSetup.setup),
      },
      onCompleted: () => {
        setCleanBaselineSetup(baselineSetup);
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully saved setup',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error saving setup: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }, { variables }) => {
        if (mutationData?.commitedSetup) {
          cache.writeQuery({
            query: SetupByBranchIdDocument,
            variables: { branchId: variables?.branchId },
            data: {
              setup: { ...mutationData.commitedSetup },
            },
          });

          cache.writeQuery({
            query: SetupByIdDocument,
            variables: { id: mutationData.commitedSetup.id },
            data: {
              setup: mutationData.commitedSetup,
            },
          });

          if (baselineSetup.setup.root?.id) {
            cache.updateQuery({
              query: SetupBranchesByRootIdDocument,
              variables: { rootId: baselineSetup.setup.root.id },
            }, queryData => {
              if (!queryData) return undefined;

              return {
                branches: queryData.branches.map((b: SetupBranch) => {
                  if (b.id !== baselineSetup.branch.id) return b;
                  return {
                    ...b,
                    head: {
                      ...b.head,
                      id: mutationData.commitedSetup?.id,
                    },
                  };
                }),
              };
            });
          }
        }
      },
    });
  };

  const renderCompareOptions = () => {
    return (
      <Card interactive={false} elevation={Elevation.ZERO} className={styles.optionsContainer}>
        <Button
          className={styles.saveButton}
          icon="git-commit"
          intent={Intent.PRIMARY}
          onClick={onSave}
          text="Save"
          fill
          disabled={!activeSUITTemplate || !isDirty}
        />
        <div className={styles.suitSelector}>
          <span className={styles.label}>SUIT</span>
          <Select
            value={_.find(suitItems, o => (o.value.id === activeSUITId))}
            items={suitItems}
            noSelectionText="Select SUIT"
            onChange={onSUITChange}
            fill
            buttonProps={{
              className: styles.compareMenuButton,
            }}
          />
        </div>
        {activeSUITTemplate ? (
          <>
            <div className={styles.selectContainer}>
              <Checkbox
                checked={allSectionsSelected}
                indeterminate={!allSectionsSelected && someSectionsSelected}
                label="Select All"
                onChange={() => selectAllSections(!allSectionsSelected)}
              />
              <Divider className={styles.selectDivider} />
              {activeSUITTemplate.items.map(item => renderNavItem([item]))}
            </div>

            <div className={styles.marginBottom}>
              <Select
                value={highlightOption}
                items={HIGHLIGHT_OPTIONS}
                onChange={setHighlightOption}
                fill
                selectProps={{ filterable: false }}
              />
            </div>

            <div className={styles.marginBottom}>
              <Select
                value={showOption}
                items={SHOW_OPTIONS}
                onChange={setShowOption}
                fill
                selectProps={{ filterable: false }}
              />
            </div>

            <div className={styles.marginBottom}>
              <Select
                value={compareOption}
                items={COMPARE_OPTIONS}
                onChange={setCompareOption}
                fill
                selectProps={{ filterable: false }}
              />
            </div>
          </>
        ) : (
          null
        )}
      </Card>
    );
  };

  const renderCompareSetups = () => {
    if (!selectedSetups.length) return null;

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToHorizontalAxis]}
      >
        <SortableContext
          items={selectedSetups.map(setup => setup.branch.id)}
          strategy={horizontalListSortingStrategy}
        >
          {selectedSetups.map((compareSetup) => {
            if (!setupTemplateMeta?.[compareSetup.branch.id] || !activeSUITTemplate) return null;
            return (
              <SortableSetupCard
                setup={compareSetup}
                setupFields={setupFields}
                template={activeSUITTemplate}
                templateMeta={setupTemplateMeta}
                color={getSetupColor(compareSetup.branch.id)}
                onContainerCollapse={onContainerCollapse}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    );
  };

  return (
    <>
      <div className={styles.container}>
        {renderCompareOptions()}
        {(!activeSUITTemplate && !suitLoading) && (
          <div className={styles.calloutContainer}>
            <Callout
              intent={Intent.WARNING}
              title="No active SUIT selected!"
            />
          </div>
        )}
        {(baselineSetup && setupTemplateMeta && activeSUITTemplate) && (
          <ScrollSync>
            <div className={styles.cardsContainer}>
              <ScrollSyncPane>
                <div className={styles.baselineSetupContainer}>
                  <SetupCard
                    isBaseline
                    onContainerCollapse={onContainerCollapse}
                    setup={baselineSetup.setup as Setup}
                    branchName={baselineSetup.branch.name}
                    setupFields={setupFields}
                    template={activeSUITTemplate}
                    templateMeta={setupTemplateMeta[baselineSetup.branch.id]}
                    onSetupChange={onSetupChange}
                    color={getSetupColor(baselineSetup.branch.id)}
                  />
                </div>
              </ScrollSyncPane>
              <ScrollSyncPane>
                <div className={styles.compareSetupsContainer}>
                  {renderCompareSetups()}
                </div>
              </ScrollSyncPane>
            </div>
          </ScrollSync>
        )}
      </div>
      <Overlay isOpen={suitLoading} className="bp3-overlay-scroll-container">
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </>
  );
};
