import { Drawer, DrawerSize, Intent, Position, Switch } from '@blueprintjs/core';
import { faCloudDownload, faLightbulb } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import AppToaster from 'helpers/toaster';
import {
  DownloadLinkResult,
  useGetApexSetupDownloaderLinkMutation,
} from 'graphql/generated/graphql';
import { selectDarkMode, uiSlice } from 'reducers/ui';
import styles from './index.module.css';
import { useEffect, useState } from 'react';

interface SettingsMenuProps {
  isOpen: boolean;
  setIsOpen: (openState: boolean) => void;
}

export default (props: SettingsMenuProps) => {
  const { isOpen, setIsOpen } = props;
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const [apexSetupDownloaderInstallerLink, setApexSetupDownloaderInstallerLink] = useState<string>('');
  const [getApexSetupDownloaderLink] = useGetApexSetupDownloaderLinkMutation({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getApexSetupDownloaderLink();
        const downloadLinkResult = result.data?.getApexSetupDownloaderLink as DownloadLinkResult;
        if (downloadLinkResult?.success) {
          setApexSetupDownloaderInstallerLink(downloadLinkResult?.link);
        }
      } catch (e) {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to fetch Apex setup downloader link: ${e}`,
        });
      }
    };

    fetchData();
  }, []);

  return (
    <Drawer
      title="Settings"
      position={Position.RIGHT}
      isOpen={isOpen}
      className={styles.settingsDrawer}
      isCloseButtonShown
      size={DrawerSize.SMALL}
      canOutsideClickClose
      onClose={() => setIsOpen(false)}
    >
      <ul className={styles.settingsList} key="settingsList">
        <li className={styles.settingsListItem} key="settingsListItem">
          <div>
            <FontAwesomeIcon
              className={styles.settingsIcon}
              icon={faLightbulb}
            />
            <span>Dark Mode</span>
          </div>
          <Switch
            checked={darkMode}
            onChange={() => dispatch(uiSlice.actions.toggleDarkMode())}
          />
        </li>
        <li className={styles.settingsListItem}>
          <div>
            <FontAwesomeIcon
              className={styles.settingsIcon}
              icon={faCloudDownload}
            />
            <span>Apex Setup Downloader</span>
          </div>
          <a
            className={styles.apexSetupDownloaderLink}
            target="_blank"
            href={apexSetupDownloaderInstallerLink}
            rel="noopener noreferrer"
          >
            <div className="download-link-label inline">Download Installer</div>
          </a>
        </li>
      </ul>
    </Drawer>
  );
};
