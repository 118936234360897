import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormGroup, Intent } from '@blueprintjs/core';

import { organizationSelectItems, runFieldTypeSelectItems, seriesItems, teamSelectItems } from '../../constants';
import RHFSelect from 'components/RHFInputs/Select';
import RHFTagInput from 'components/RHFInputs/TagInput';
import RHFTextInput from 'components/RHFInputs/TextInput';
import { RunField, RunFieldType, SetupField } from 'graphql/generated/graphql';
import Positions from './Positions';
import ExpressionInput from '../ExpressionInput';

import styles from './index.module.css';
import { selectDarkMode } from '../../reducers/ui';
import IconTooltip from 'components/IconTooltip';
import { useRouteLoaderData } from 'react-router-dom';
import { find } from 'lodash';

interface Props {
  runFields: RunField[];
  setupFields: SetupField[];
}

export default ({ runFields, setupFields }: Props) => {
  const { control, setValue, formState: { errors } } = useFormContext();
  const darkMode = useSelector(selectDarkMode);
  const type = useWatch({ control, name: 'type' });
  const teamName = useWatch({ control, name: 'team_name' });
  const orgName = useWatch({ control, name: 'organization_name' });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  // Reset team selections if a user changes organization.
  const findTeam = find(teams, team => team.name === teamName);
  if (findTeam && findTeam.organization.name !== orgName) {
    setValue('team_name', null);
  }

  return (
    <>
      <FormGroup
        contentClassName={styles.selectFormGroup}
        helperText={<ErrorMessage errors={errors} name="type" />}
        label="Type"
        labelInfo="(required)"
      >
        <RHFSelect
          controllerProps={{
            control,
            name: 'type',
            rules: { required: 'Type is required' },
          }}
          intent={errors.type && Intent.DANGER}
          items={runFieldTypeSelectItems}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="organization_name" />}
        intent={errors.organization_name ? Intent.DANGER : Intent.NONE}
        label="Organization"
        labelInfo="(required)"
      >
        <RHFSelect
          controllerProps={{
            control,
            name: 'organization_name',
            rules: {
              required: 'Organization is required',
            },
          }}
          intent={errors.organization_name && Intent.DANGER}
          items={organizationSelectItems(organizations)}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="team_name" />}
        intent={errors.team_name ? Intent.DANGER : Intent.NONE}
        label="Team"
      >
        <RHFSelect
          controllerProps={{
            control,
            name: 'team_name',
          }}
          disabled={!orgName}
          intent={errors.team_name && Intent.DANGER}
          items={teamSelectItems(teams, orgName)}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="series" />}
        intent={errors.series ? Intent.DANGER : Intent.NONE}
        label="Series"
        labelInfo="(required)"
      >
        <RHFSelect
          controllerProps={{
            control,
            name: 'series',
            rules: {
              required: 'Series is required',
            },
          }}
          intent={errors.series && Intent.DANGER}
          items={seriesItems}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="name" />}
        label="Name"
        labelInfo={(
          <>
            <IconTooltip
              content={`
                The name that will be specified in RUITs to include this
                field. It should be lowercase with no spaces, ideally using
                underscores between words.
              `}
            />
            <span> (required)</span>
          </>
        )}
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'name',
            rules: {
              required: 'Name is required',
              pattern: {
                value: /^\S*$/,
                message: 'Name must not contain spaces',
              },
            },
          }}
          inputProps={{
            intent: errors.name && Intent.DANGER,
          }}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="label" />}
        label="Label"
        labelInfo="(required)"
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'label',
            rules: { required: 'Label is required' },
          }}
          inputProps={{
            intent: errors.label && Intent.DANGER,
          }}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="tooltip" />}
        label="Tooltip"
        labelInfo={(
          <IconTooltip content="Text shown in a tooltip when hovering this field" />
        )}
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'tooltip',
          }}
          inputProps={{
            intent: errors.tooltip && Intent.DANGER,
          }}
        />
      </FormGroup>
      {type === RunFieldType.EXPRESSION && (
        <ExpressionInput
          fields={runFields}
          darkMode={darkMode}
          fieldType="run"
        />
      )}
      {type === RunFieldType.SETUP_FIELD && (
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="setup_field_id" />}
          label="Setup Field"
          labelInfo="(required)"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'setup_field_id',
              rules: { required: 'Setup field is required' },
            }}
            intent={errors.setup_field_id && Intent.DANGER}
            items={setupFields.map(field => ({
              label: field.name,
              value: field.id,
            }))}
          />
        </FormGroup>
      )}
      {type !== RunFieldType.EXPRESSION && type !== RunFieldType.SETUP_FIELD && (
        <FormGroup label="Positions">
          <Positions />
        </FormGroup>
      )}
      {type === RunFieldType.STRING && (
        <FormGroup
          label="Options"
          labelInfo={(
            <IconTooltip
              content={`
                Optional. When specified, options limit the values an input can
                have by rendering a dropdown with the specified values rather
                than a text input.
              `}
            />
          )}
        >
          <RHFTagInput
            controllerProps={{
              control,
              name: 'options',
            }}
          />
        </FormGroup>
      )}
    </>
  );
};
