import { H3 } from '@blueprintjs/core';

import SetupSearch from 'components/SetupSearch';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export default () => {
  useDocumentTitle('Setups');

  return (
    <>
      <H3>Setup Summary</H3>
      <SetupSearch />
    </>
  );
};
