import React, { useState, useRef } from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, H3, Intent } from '@blueprintjs/core';

import {
  DriverSimType,
  CreateSimStatesInput,
  CreateEightPostInput,
  CreateLapWindDataInput,
  CreateLapTimeDataInput,
  CreateDriverInput,
  CreateDriveFileInput,
  useCreateEightPostMutation,
  useCreateDriverMutation,
  useCreateLapTimeDataMutation,
  useCreateDriveFileMutation,
  useCreateLapWindDataMutation,
  useCreateSimStatesMutation,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { isGraphQLErrorResponse, extractInvalidFields } from 'helpers/errorFormatting';
import SimDocumentForm from '../../SimDocumentManagement/SimDocumentForm';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import styles from './index.module.css';
import { find, get } from 'lodash';

interface SimDocumentFormMethods {
  getData: () => any | null; // eslint-disable-line @typescript-eslint/no-explicit-any
}

type CreateSimDocumentFormInput = {
  id: number | null;
  sim_document_type: string;
  desc: string;
  driver_sim_type?: DriverSimType | null | undefined;
  name: string;
  track?: string;
  data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  organization_id: string;
  organization_name: string;
  team_id: string | null;
  team_name: string | null;
  series: string;
}

type CreateSimStatesMutationVariables = {
  input: CreateSimStatesInput;
}

type CreateEightPostMutationVariables = {
  input: CreateEightPostInput;
}

type CreateLapWindDataMutationVariables = {
  input: CreateLapWindDataInput;
}

type CreateLapTimeDataMutationVariables = {
  input: CreateLapTimeDataInput;
}

type CreateDriverMutationVariables = {
  input: CreateDriverInput;
}

type CreateDriveFileMutationVariables = {
  input: CreateDriveFileInput;
}

const defaultDocument = {
  id: null,
  sim_document_type: '',
  name: '',
  desc: '',
  track: '',
  data: {},
  organization_name: '',
  organization_id: '',
  team_id: null,
  team_name: null,
  series: '',
};

export default () => {
  useDocumentTitle('Add Sim Document');
  const navigate = useNavigate();
  const formRef = useRef<SimDocumentFormMethods>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<CreateSimDocumentFormInput>({ defaultValues: defaultDocument });

  const [createEightPost] = useCreateEightPostMutation();
  const [createDriver] = useCreateDriverMutation();
  const [createLapTimeData] = useCreateLapTimeDataMutation();
  const [createDriveFile] = useCreateDriveFileMutation();
  const [createLapWindData] = useCreateLapWindDataMutation();
  const [createSimStates] = useCreateSimStatesMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMutation = async (input: CreateSimDocumentFormInput, data: any) => {
    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name); // eslint-disable-line @typescript-eslint/no-explicit-any
    switch (input.sim_document_type) {
      case 'eight_post': {
        const eightPostVariables: CreateEightPostMutationVariables = {
          input: {
            name: input.name,
            desc: input.desc,
            venue: data.venue,
            data,
            organization_id: get(findOrg, 'id', null),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await createEightPost({ variables: eightPostVariables });
        navigate('/sims/documents/category/eight_post/eight_post');
        break;
      }

      case 'driver': {
        const driverVariables: CreateDriverMutationVariables = {
          input: {
            name: input.name,
            desc: input.desc,
            track: input.track || '',
            data,
            organization_id: get(findOrg, 'id', null),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await createDriver({ variables: driverVariables });
        navigate('/sims/documents/category/elap/driver');
        break;
      }

      case 'lap_time_data': {
        const lapTimeDataVariables: CreateLapTimeDataMutationVariables = {
          input: {
            name: input.name,
            desc: input.desc,
            data,
            organization_id: get(findOrg, 'id', null),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await createLapTimeData({ variables: lapTimeDataVariables });
        navigate('/sims/documents/category/elap/lap_time_data');
        break;
      }

      case 'drive_file': {
        const driveFileVariables: CreateDriveFileMutationVariables = {
          input: {
            name: input.name,
            desc: input.desc,
            venue: data.venue,
            data,
            organization_id: get(findOrg, 'id', null),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await createDriveFile({ variables: driveFileVariables });
        navigate('/sims/documents/category/ride_model/drive_file');
        break;
      }

      case 'lap_wind_data': {
        const lapWindDataVariables: CreateLapWindDataMutationVariables = {
          input: {
            name: input.name,
            desc: input.desc,
            data,
            organization_id: get(findOrg, 'id', null),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await createLapWindData({ variables: lapWindDataVariables });
        navigate('/sims/documents/category/misc/lap_wind_data');
        break;
      }

      case 'sim_states': {
        const simStatesVariables: CreateSimStatesMutationVariables = {
          input: {
            simStates: {
              name: input.name,
              desc: input.desc,
              track: input.name,
              organization_id: get(findOrg, 'id', null),
              organization_name: input.organization_name,
              team_name: input.team_name,
              team_id: get(findTeam, 'id', null),
              series: input.series,
            },
            simStatesStates: data.states,
          },
        };
        await createSimStates({ variables: simStatesVariables });
        navigate('/sims/documents/category/misc/sim_states');
        break;
      }

      default:
        throw new Error('Unknown document type');
    }
  };

  const onSubmit = async (input: CreateSimDocumentFormInput) => {
    if (!formRef.current) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Form reference is not available',
      });
      return;
    }

    const data = formRef.current.getData(); // Get data from the child form

    if (!data) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Invalid data, cannot submit.',
      });
      return;
    }

    setIsSubmitting(true);

    try {
      await handleMutation(input, data);

      AppToaster.show({
        intent: Intent.SUCCESS,
        message: 'Document successfully added',
      });
    } catch (error) {
      if (isGraphQLErrorResponse(error)) {
        const invalidFields = extractInvalidFields(error);
        const errorMessage = invalidFields.length > 0
          ? `Invalid fields: ${invalidFields.join(', ')}`
          : 'An unknown error occurred.';

        AppToaster.show({
          intent: Intent.DANGER,
          message: errorMessage,
        });
      } else {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'An unknown error occured.',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to read the file and parse JSON
  const readFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const result = event.target?.result;
        if (typeof result === 'string') {
          const jsonObj = JSON.parse(result);

          const documentType = form.getValues('sim_document_type');

          if (jsonObj && documentType && jsonObj[documentType]) {
            form.reset({
              sim_document_type: documentType,
              name: jsonObj.meta?.name || '',
              desc: jsonObj.meta?.desc || '',
              track: jsonObj.meta?.track || '',
              data: jsonObj[documentType],
            });
          } else {
            throw new Error('Invalid file content');
          }
        } else {
          throw new Error('File content is not a string');
        }
      } catch (error) {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'Error parsing JSON!',
        });
      }
    };
    reader.readAsText(file);
  };

  // Function to handle file selection
  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Access the file from the input event
    const file = e.target.files && e.target.files[0];
    if (file) {
      // Check if the file type is JSON
      if (file.type === 'application/json') {
        readFile(file);
      } else {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'Please upload a JSON file.',
        });
      }
    }
  };

  return (
    <div className={styles.simDocumentFormContainer}>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className={styles.headerBar}>
            <H3>Add Document</H3>
            <input
              type="file"
              onChange={handleFileInput}
              style={{ display: 'none' }}
              id="file-input"
              accept=".json"
            />
            <Button
              className={styles.saveButton}
              icon="import"
              intent={Intent.PRIMARY}
              large
              text="Import"
              onClick={() => {
                const inputFileElement = document.getElementById('file-input') as HTMLInputElement;
                if (inputFileElement) {
                  inputFileElement.click();
                }
              }}
            />
            <Button
              className={styles.saveButton}
              icon="floppy-disk"
              intent={Intent.PRIMARY}
              large
              text="Save"
              type="submit"
              disabled={isSubmitting}
            />
          </div>
          <SimDocumentForm ref={formRef} />
        </form>
      </FormProvider>
    </div>
  );
};
