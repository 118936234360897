import { RunField, RunFieldType, SetupField, SetupFieldType } from 'graphql/generated/graphql';
import { get, snakeCase } from 'lodash';
import * as math from 'mathjs';
import { ValidationContext, FieldReference } from 'types';

interface Field {
  id: number;
  path: string;
  expression?: string;
}

interface Position {
  label: string;
  path_part?: string;
}

export class ExpressionValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ExpressionValidationError';
  }
}

type ArithmeticOperator = typeof OPERATORS.ARITHMETIC[number];

const OPERATORS = {
  ARITHMETIC: ['+', '-', '*', '/', '**'] as const,
  PARENTHESES: ['(', ')'] as const,
} as const;

export const validateExpressionSyntax = (expression: string): boolean => {
  try {
    // Check for at least one field reference before sanitizing
    if (!(/{[^}]+}/.test(expression))) {
      throw new Error('Expression must contain at least one field reference');
    }

    // Replace field references with spaces to isolate non-field content
    const nonFieldContent = expression.replace(/\{[^}]+\}/g, ' ');
    // Check for any digits that have spaces between them, but allow decimal points
    if (/\d+\s+(?:\d*\.)?\d+|\d+(?:\.\d+)?\s+\d+/.test(nonFieldContent)) {
      throw new Error('Numbers cannot contain spaces');
    }

    const sanitizedExpression = sanitizeExpression(expression);
    validateBasicSyntax(sanitizedExpression);
    validateOperatorPlacement(sanitizedExpression);
    validateParentheses(sanitizedExpression);
    validateTokens(sanitizedExpression);
    return true;
  } catch (error) {
    if (error instanceof Error) {
      throw new ExpressionValidationError(`Syntax error: ${error.message}`);
    }
    throw new ExpressionValidationError('Invalid expression syntax');
  }
};

const sanitizeExpression = (expression: string): string => {
  return expression
    .replace(/\{[^}]+\}/g, '1') // Replace field references with dummy number
    .replace(/\s/g, ''); // Remove whitespace
};

const validateBasicSyntax = (expression: string): void => {
  if (!expression) {
    throw new Error('Empty expression is not allowed');
  }

  if (!/[+\-*/%()]/.test(expression)) {
    throw new Error('Expression must contain at least one operator');
  }
};

const validateOperatorPlacement = (expression: string): void => {
  const invalidPatterns = [
    {
      pattern: /[+\-/]{2,}/,
      message: 'Multiple operators in sequence not allowed',
    },
    { pattern: /\*{3,}/, message: 'More than two * in sequence not allowed' },
    {
      pattern: /^[+\-*/]/,
      message: 'Expression cannot start with an operator',
    },
    { pattern: /[+\-*/]$/, message: 'Expression cannot end with an operator' },
    { pattern: /\(\)/, message: 'Empty parentheses not allowed' },
    {
      pattern: /\([+\-*/]/,
      message: 'Operator not allowed after opening parenthesis',
    },
    {
      pattern: /[+\-*/]\)/,
      message: 'Operator not allowed before closing parenthesis',
    },
  ];

  invalidPatterns.forEach(({ pattern, message }) => {
    if (pattern.test(expression)) {
      throw new Error(message);
    }
  });
};

const validateParentheses = (expression: string): void => {
  const stack = [];
  for (let i = 0; i < expression.length; i++) {
    if (expression[i] === '(') {
      stack.push(i);
    } else if (expression[i] === ')') {
      if (stack.length === 0) {
        throw new Error(`Unexpected closing parenthesis at position ${i}`);
      }
      stack.pop();
    }
  }

  if (stack.length > 0) {
    throw new Error(`Unclosed parenthesis at position ${stack[0]}`);
  }
};

export const validateFieldReferences = (
  context: ValidationContext
): FieldReference[] => {
  const { fields, expression, fieldType } = context;

  const matchResults = expression.match(/{[^}]+}/g);
  const matches: string[] = matchResults || [];

  const references = matches.reduce<FieldReference[]>((refs, matchStr) => {
    const fieldName = matchStr.slice(1, -1);
    const field = fields.find((f: SetupField | RunField) => {
      return 'name' in f && f.name === fieldName;
    });

    if (!field) {
      throw new ExpressionValidationError(
        `Referenced ${fieldType} field "${fieldName}" not found`
      );
    }

    if (field.type !== (fieldType === 'setup' ? SetupFieldType.FLOAT : RunFieldType.FLOAT)) {
      throw new ExpressionValidationError(
        `Referenced ${fieldType} field "${fieldName}" must be of type FLOAT`
      );
    }

    const startIndex = expression.indexOf(matchStr);
    refs.push({
      name: fieldName,
      field,
      startIndex,
      endIndex: startIndex + matchStr.length,
    });

    return refs;
  }, []);

  validateFieldCompatibility(references);
  validateAdjacentFields(references, expression);
  return references;
};

const validateFieldCompatibility = (references: FieldReference[]): void => {
  if (references.length <= 1) return;

  const firstField = references[0].field;
  const firstPositions = getSortedPositionLabels(firstField);

  references.slice(1).forEach(({ field, name }) => {
    const currentPositions = getSortedPositionLabels(field);
    if (currentPositions !== firstPositions) {
      throw new ExpressionValidationError(
        `Position mismatch: "${name}" has different positions than "${firstField.name}"`
      );
    }
  });
};

const validateAdjacentFields = (references: FieldReference[], expression: string): void => {
  for (let i = 0; i < references.length - 1; i++) {
    const currentField = references[i];
    const nextField = references[i + 1];
    const textBetween = expression.slice(currentField.endIndex, nextField.startIndex).trim();

    if (!textBetween || !/[+\-*/()]/.test(textBetween)) {
      throw new ExpressionValidationError(
        'Invalid expression: Field references must be separated by an operator'
      );
    }
  }
};

const getSortedPositionLabels = (field: SetupField | RunField): string => {
  return (field.positions || [])
    .map((p) => p.label)
    .sort()
    .join(',');
};

const validateTokens = (expression: string): void => {
  const tokens = expression.match(/\*\*|\*|[0-9]+\.[0-9]+|[0-9]+|[+\-/()]|[A-Za-z_][A-Za-z0-9_]*/g) || [];
  let expectingOperand = true;

  tokens.forEach((token, index) => {
    if (/^[0-9]+(\.[0-9]+)?$/.test(token)) {
      if (!expectingOperand) {
        throw new Error(`Unexpected number at token ${index + 1}`);
      }
      expectingOperand = false;
    } else if (OPERATORS.ARITHMETIC.includes(token as ArithmeticOperator)) {
      if (expectingOperand) {
        throw new Error(`Unexpected operator "${token}" at token ${index + 1}`);
      }
      expectingOperand = true;
    }
  });

  if (expectingOperand) {
    throw new Error('Expression ends with an operator');
  }
};

export const evaluateFieldExpression = (
  field: Field,
  referencedFields: Field[],
  initialData?: object,
  position?: Position,
): string | number => {
  const expressionWithValues = referencedFields.reduce((expr, refField) => {
    const fieldId = refField.id;
    // Only apply position-based path modification to referenced fields if position is provided
    const path = position && 'positions' in refField
      ? getFieldPath(refField.path, position)
      : refField.path;
    const value = initialData ? get(initialData, path, '') : '';
    const safeValue = value === '' ? '0' : value;
    return expr.replace(
      new RegExp(`\\{${fieldId}\\}`, 'g'),
      safeValue.toString()
    );
  }, field.expression || '');

  try {
    // eslint-disable-next-line no-eval
    const result = eval(expressionWithValues);
    // eslint-disable-next-line no-restricted-globals
    return isNaN(result) ? '' : result;
  } catch {
    return '';
  }
};

const getFieldPath = (path: string, position?: Position): string => {
  if (!position) return path;
  if (!position.path_part) return `${path}_${snakeCase(position.label)}`;

  const pathParts = path.split('.');
  pathParts.splice(-1, 1, position.path_part);
  return pathParts.join('.');
};

// Checks val is a math expression, not if it's a value of apex setup expression type, which involves other setup variables.
export const isMathExpression = (val: unknown) => {
  try {
    return typeof val === 'string' && math.parse(val as string) && !Number.isFinite(Number(val));
  } catch (e) {
    return false;
  }
};

export const isValidNumber = (value: string) => {
  return isMathExpression(value) || Number.isFinite(Number(value));
};
