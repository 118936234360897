import React, { useState, useRef } from 'react';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, H3, Intent, Overlay, Spinner } from '@blueprintjs/core';

import {
  DriverSimType,
  UpdateSimStatesInput,
  UpdateEightPostInput,
  UpdateLapWindDataInput,
  UpdateLapTimeDataInput,
  UpdateDriverInput,
  UpdateDriveFileInput,
  useUpdateEightPostMutation,
  useUpdateDriverMutation,
  useUpdateLapTimeDataMutation,
  useUpdateDriveFileMutation,
  useUpdateLapWindDataMutation,
  useUpdateSimStatesMutation,
  useEightPostByIdQuery,
  useDriverByIdQuery,
  useDriveFileByIdQuery,
  useLapTimeDataByIdQuery,
  useLapWindDataByIdQuery,
  useSimStatesByIdQuery,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { isGraphQLErrorResponse, extractInvalidFields } from 'helpers/errorFormatting';
import SimDocumentForm from '../../SimDocumentManagement/SimDocumentForm';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import styles from './index.module.css';
import { find, get } from 'lodash';

interface SimDocumentFormMethods {
  getData: () => any | null; // eslint-disable-line @typescript-eslint/no-explicit-any
}

type UpdateSimDocumentFormInput = {
  id: number;
  sim_document_type: string;
  desc: string;
  driver_sim_type?: DriverSimType | null | undefined;
  name: string;
  track?: string;
  data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  organization_id: string;
  organization_name: string;
  team_id: string | null;
  team_name: string | null;
  series: string;
}

type UpdateSimStatesMutationVariables = {
  input: UpdateSimStatesInput;
}

type UpdateEightPostMutationVariables = {
  input: UpdateEightPostInput;
}

type UpdateLapWindDataMutationVariables = {
  input: UpdateLapWindDataInput;
}

type UpdateLapTimeDataMutationVariables = {
  input: UpdateLapTimeDataInput;
}

type UpdateDriverMutationVariables = {
  input: UpdateDriverInput;
}

type UpdateDriveFileMutationVariables = {
  input: UpdateDriveFileInput;
}

const defaultDocument = {
  id: 0,
  sim_document_type: '',
  name: '',
  desc: '',
  track: '',
  data: {},
  organization_name: '',
  organization_id: '',
  team_id: null,
  team_name: null,
  series: '',
};

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const configName = String(params.configName);
  const documentId = Number(params.documentId);
  const formRef = useRef<SimDocumentFormMethods>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<UpdateSimDocumentFormInput>({ defaultValues: defaultDocument });

  useDocumentTitle(
    form && form.getValues('desc') ? `${form.getValues('desc')}` : 'Apex Setup'
  );

  // Fetch document data based on type
  const { loading: documentLoading } = (() => {
    switch (configName) {
      case 'eight_post':
        return useEightPostByIdQuery({
          variables: { id: documentId },
          fetchPolicy: 'no-cache',
          skip: !documentId,
          onCompleted: (data) => {
            if (data.eightPost) {
              form.reset({
                id: data.eightPost.id,
                sim_document_type: configName,
                name: data.eightPost.name,
                desc: data.eightPost.desc,
                data: data.eightPost.data,
                organization_name: data.eightPost.organization_name,
                team_name: data.eightPost.team_name,
                series: data.eightPost.series,
              });
            }
          },
        });
      case 'driver':
        return useDriverByIdQuery({
          variables: { id: documentId },
          fetchPolicy: 'no-cache',
          skip: !documentId,
          onCompleted: (data) => {
            if (data.driver) {
              form.reset({
                id: data.driver.id,
                sim_document_type: configName,
                name: String(data.driver.name),
                desc: data.driver.desc,
                track: data.driver.track,
                data: data.driver.data,
                organization_name: data.driver.organization_name,
                team_name: data.driver.team_name,
                series: data.driver.series,
              });
            }
          },
        });
      case 'lap_time_data':
        return useLapTimeDataByIdQuery({
          variables: { id: documentId },
          fetchPolicy: 'no-cache',
          skip: !documentId,
          onCompleted: (data) => {
            if (data.lapTimeData) {
              form.reset({
                id: data.lapTimeData.id,
                sim_document_type: configName,
                name: data.lapTimeData.name,
                desc: data.lapTimeData.desc,
                data: data.lapTimeData.data,
                organization_name: data.lapTimeData.organization_name,
                team_name: data.lapTimeData.team_name,
                series: data.lapTimeData.series,
              });
            }
          },
        });
      case 'drive_file':
        return useDriveFileByIdQuery({
          variables: { id: documentId },
          fetchPolicy: 'no-cache',
          skip: !documentId,
          onCompleted: (data) => {
            if (data.driveFile) {
              form.reset({
                id: data.driveFile.id,
                sim_document_type: configName,
                name: data.driveFile.name,
                desc: data.driveFile.desc,
                data: data.driveFile.data,
                organization_name: data.driveFile.organization_name,
                team_name: data.driveFile.team_name,
                series: data.driveFile.series,
              });
            }
          },
        });
      case 'lap_wind_data':
        return useLapWindDataByIdQuery({
          variables: { id: documentId },
          fetchPolicy: 'no-cache',
          skip: !documentId,
          onCompleted: (data) => {
            if (data.lapWindData) {
              form.reset({
                id: data.lapWindData.id,
                sim_document_type: configName,
                name: data.lapWindData.name,
                desc: data.lapWindData.desc,
                data: data.lapWindData.data,
                organization_name: data.lapWindData.organization_name,
                team_name: data.lapWindData.team_name,
                series: data.lapWindData.series,
              });
            }
          },
        });
      case 'sim_states':
        return useSimStatesByIdQuery({
          variables: { id: documentId },
          fetchPolicy: 'no-cache',
          skip: !documentId,
          onCompleted: (data) => {
            if (data.simState) {
              form.reset({
                id: data.simState.id,
                sim_document_type: configName,
                name: data.simState.name,
                desc: data.simState.desc,
                data: data.simState.data,
                organization_name: data.simState.organization_name,
                team_name: data.simState.team_name,
                series: data.simState.series,
              });
            }
          },
        });
      default:
        throw new Error('Unknown document type');
    }
  })();

  const [updateEightPost] = useUpdateEightPostMutation();
  const [updateDriver] = useUpdateDriverMutation();
  const [updateLapTimeData] = useUpdateLapTimeDataMutation();
  const [updateDriveFile] = useUpdateDriveFileMutation();
  const [updateLapWindData] = useUpdateLapWindDataMutation();
  const [updateSimStates] = useUpdateSimStatesMutation();

  const handleMutation = async (input: UpdateSimDocumentFormInput, data: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);

    switch (input.sim_document_type) {
      case 'eight_post': {
        const eightPostVariables: UpdateEightPostMutationVariables = {
          input: {
            id: input.id,
            name: input.name,
            desc: input.desc,
            venue: data.venue,
            data,
            organization_id: get(findOrg, 'id', ''),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await updateEightPost({ variables: eightPostVariables });
        navigate('/sims/documents/category/eight_post/eight_post');
        break;
      }

      case 'driver': {
        const driverVariables: UpdateDriverMutationVariables = {
          input: {
            id: input.id,
            name: input.name,
            desc: input.desc,
            track: input.track || '',
            data,
            organization_id: get(findOrg, 'id', ''),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await updateDriver({ variables: driverVariables });
        navigate('/sims/documents/category/elap/driver');
        break;
      }

      case 'lap_time_data': {
        const lapTimeDataVariables: UpdateLapTimeDataMutationVariables = {
          input: {
            id: input.id,
            name: input.name,
            desc: input.desc,
            data,
            organization_id: get(findOrg, 'id', ''),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await updateLapTimeData({ variables: lapTimeDataVariables });
        navigate('/sims/documents/category/elap/lap_time_data');
        break;
      }

      case 'drive_file': {
        const driveFileVariables: UpdateDriveFileMutationVariables = {
          input: {
            id: input.id,
            name: input.name,
            desc: input.desc,
            venue: data.venue,
            data,
            organization_id: get(findOrg, 'id', ''),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await updateDriveFile({ variables: driveFileVariables });
        navigate('/sims/documents/category/ride_model/drive_file');
        break;
      }

      case 'lap_wind_data': {
        const lapWindDataVariables: UpdateLapWindDataMutationVariables = {
          input: {
            id: input.id,
            name: input.name,
            desc: input.desc,
            data,
            organization_id: get(findOrg, 'id', ''),
            organization_name: input.organization_name,
            team_name: input.team_name,
            team_id: get(findTeam, 'id', null),
            series: input.series,
          },
        };
        await updateLapWindData({ variables: lapWindDataVariables });
        navigate('/sims/documents/category/misc/lap_wind_data');
        break;
      }

      case 'sim_states': {
        const simStatesVariables: UpdateSimStatesMutationVariables = {
          input: {
            id: input.id,
            simStates: {
              name: input.name,
              desc: input.desc,
              track: input.name,
              organization_id: get(findOrg, 'id', ''),
              organization_name: input.organization_name,
              team_name: input.team_name,
              team_id: get(findTeam, 'id', null),
              series: input.series,
            },
            simStatesStates: data.states,
          },
        };
        await updateSimStates({ variables: simStatesVariables });
        navigate('/sims/documents/category/misc/sim_states');
        break;
      }

      default:
        throw new Error('Unknown document type');
    }
  };

  const onSubmit = async (input: UpdateSimDocumentFormInput) => {
    if (!formRef.current) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Form reference is not available',
      });
      return;
    }

    const data = formRef.current.getData(); // Get data from the child form

    if (!data) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Invalid data, cannot submit.',
      });
      return;
    }

    setIsSubmitting(true);

    try {
      await handleMutation(input, data);

      AppToaster.show({
        intent: Intent.SUCCESS,
        message: 'Document successfully added',
      });
    } catch (error) {
      if (isGraphQLErrorResponse(error)) {
        const invalidFields = extractInvalidFields(error);
        const errorMessage = invalidFields.length > 0
          ? `Invalid fields: ${invalidFields.join(', ')}`
          : 'An unknown error occurred.';

        AppToaster.show({
          intent: Intent.DANGER,
          message: errorMessage,
        });
      } else {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'An unknown error occured.',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to read the file and parse JSON
  const readFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const result = event.target?.result;
        if (typeof result === 'string') {
          const jsonObj = JSON.parse(result);

          const documentType = form.getValues('sim_document_type');

          if (jsonObj && documentType && jsonObj[documentType]) {
            form.reset({
              id: documentId,
              sim_document_type: documentType,
              name: jsonObj.meta?.name || '',
              desc: jsonObj.meta?.desc || '',
              track: jsonObj.meta?.track || '',
              data: jsonObj[documentType],
            });
          } else {
            throw new Error('Invalid file content');
          }
        } else {
          throw new Error('File content is not a string');
        }
      } catch (error) {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'Error parsing JSON!',
        });
      }
    };
    reader.readAsText(file);
  };

  // Function to handle file selection
  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Access the file from the input event
    const file = e.target.files && e.target.files[0];
    if (file) {
      // Check if the file type is JSON
      if (file.type === 'application/json') {
        readFile(file);
      } else {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'Please upload a JSON file.',
        });
      }
    }
  };

  return (
    <div className={styles.simDocumentFormContainer}>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className={styles.headerBar}>
            <H3>Edit Document</H3>
            <input
              type="file"
              onChange={handleFileInput}
              style={{ display: 'none' }}
              id="file-input"
              accept=".json"
            />
            <Button
              className={styles.saveButton}
              icon="import"
              intent={Intent.PRIMARY}
              large
              text="Import"
              onClick={() => {
                const inputFileElement = document.getElementById('file-input') as HTMLInputElement;
                if (inputFileElement) {
                  inputFileElement.click();
                }
              }}
            />
            <Button
              className={styles.saveButton}
              icon="floppy-disk"
              intent={Intent.PRIMARY}
              large
              text="Save"
              type="submit"
              disabled={isSubmitting}
            />
          </div>
          <SimDocumentForm ref={formRef} />
        </form>
      </FormProvider>
      <Overlay
        isOpen={documentLoading}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </div>
  );
};
