import { Button, Divider, Intent } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import styles from './index.module.css';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { SetupSelection } from '../../types';
import AddSetupsModal from '../SelectorModal/setup';
import _ from 'lodash';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChromePicker } from 'react-color';
import { baseName } from '../../config';
import { SetupCompareSlice, selectSetupCompareState } from '../../reducers/setupCompare';
import { SetupColorsSlice, selectSetupColorsState } from '../../reducers/setupColors';
import { useSetupBranchesByIdsQuery } from '../../graphql/generated/graphql';
import SetupCheckbox from '../SetupCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { DEFAULT_COLORS } from '../../constants';

interface Props {
  showExtended: boolean;
}

interface ColorSelection {
  hex: string;
}

// A simple hash function to convert branch ID to a consistent color index
const getBranchColorIndex = (branchId: number): number => {
  let hash = 0;
  const str = branchId.toString();

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash *= 31;
    hash += char;
  }

  // Make hash positive and get a valid index
  return Math.abs(hash) % DEFAULT_COLORS.length;
};

export default (props: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const branchId = Number(params.branchId);
  const compareIds = searchParams.get('ids');
  const branchIds = [branchId, ...compareIds?.split(',').map(Number) ?? []];
  const [baselineSetup, setBaselineSetup] = useState<SetupSelection>();
  const [selectableSetups, setSelectableSetups] = useState<SetupSelection[]>([]);
  const [selectedSetups, setSelectedSetups] = useState<SetupSelection[]>([]);
  const [colorPickerBranchId, setColorPickerBranchId] = useState<number>(0);
  const activeSetupColorsState = useSelector(selectSetupColorsState);
  const storeSetups = useSelector(selectSetupCompareState);

  useEffect(() => {
    if (branchIds.length > 0) {
      // Check which branchIds don't have colors set in the store
      const unsetBranchIds = branchIds.filter(id => !activeSetupColorsState[id]);

      // If we have any unset branch colors, set them now
      if (unsetBranchIds.length > 0) {
        unsetBranchIds.forEach((id) => {
          const colorIndex = getBranchColorIndex(id);
          dispatch(SetupColorsSlice.actions.setSetupColorsState({
            branchId: id,
            color: DEFAULT_COLORS[colorIndex],
          }));
        });
      }
    }
  }, [branchIds]);

  useEffect(() => {
    const compareIds = selectedSetups.map(s => s.branch.id);

    const currentSetupCompareState = { [branchId]: [...compareIds || []] };
    dispatch(SetupCompareSlice.actions.setSetupCompareState(currentSetupCompareState));
  }, [selectedSetups]);

  // Appends selected setup IDs to the URL query params
  useEffect(() => {
    let compareUrl = baseName === '/'
      ? `${window.location.origin}${location.pathname}`
      : `${window.location.origin}${baseName}${location.pathname}`;

    const compareIds = storeSetups[branchId];
    if (!_.isEmpty(compareIds)) compareUrl += `?ids=${_.join(compareIds)}`;

    window.history.replaceState(null, '', compareUrl);
  }, [storeSetups]);

  useSetupBranchesByIdsQuery({
    variables: { branchIds },
    onCompleted: data => {
      const branchHeads = data.setupBranches.map(b => ({
        branch: {
          id: b.id,
          name: b.name,
        },
        setup: { ...b.head },
      }));
      const [baselineBranch, ...compareBranches] = branchHeads.reduce((acc, b) => {
        if (b.branch.id === branchId) acc.unshift(b);
        else acc.push(b);
        return acc;
      }, [] as SetupSelection[]);
      setBaselineSetup(baselineBranch);

      if (compareBranches?.length) {
        // Use branchIds (from URL) to sort the setups in the correct order
        // Filter out the baseline branchId since it's handled separately
        const orderedBranchIds = branchIds.filter(id => id !== branchId);
        const orderedSetups = orderedBranchIds
          .map(id => compareBranches.find((setup: SetupSelection) => (setup.branch.id === id)))
          .filter(Boolean) as SetupSelection[];

        setSelectableSetups(orderedSetups);
        setSelectedSetups(orderedSetups);
      }
    },
    fetchPolicy: 'network-only',
  });

  const handleSelectSetup = (setup: SetupSelection) => {
    const newSetups = [...selectedSetups];
    const idIndex = newSetups.findIndex(s => s.branch.id === setup.branch.id);
    if (idIndex > -1) {
      newSetups.splice(idIndex, 1);
    } else {
      newSetups.push(setup);
    }
    setSelectedSetups(newSetups);
  };

  const handleRemoveSetup = (id: number) => {
    const newSelectedSetups = [...selectedSetups];
    const selectedIdIndex = newSelectedSetups.findIndex(s => s.branch.id === id);
    if (selectedIdIndex > -1) {
      newSelectedSetups.splice(selectedIdIndex, 1);
      setSelectedSetups(newSelectedSetups);
    }

    const newSelectableSetups = [...selectableSetups];
    const selectableIdIndex = newSelectableSetups.findIndex(s => s.branch.id === id);
    if (selectableIdIndex > -1) {
      newSelectableSetups.splice(selectableIdIndex, 1);
      setSelectableSetups(newSelectableSetups);
    }
  };

  const onModalSuccess = (rows: SetupSelection[]) => {
    setIsDialogOpen(false);

    const newSetups: SetupSelection[] = _.filter(rows, r => r.branch.id !== baselineSetup?.branch.id);

    // Assign colors to any new setups that don't already have colors in the store
    newSetups.forEach((setup) => {
      const branchId = setup.branch.id;

      // Only set color if it doesn't already exist in the store
      if (!activeSetupColorsState[branchId]) {
        const colorIndex = getBranchColorIndex(branchId);
        dispatch(SetupColorsSlice.actions.setSetupColorsState({
          branchId,
          color: DEFAULT_COLORS[colorIndex],
        }));
      }
    });

    setSelectableSetups(_.uniqBy([...selectableSetups, ...newSetups], 'branch.id'));
    setSelectedSetups([
      ...selectedSetups,
      ...rows.filter(r => r.branch.id !== baselineSetup?.branch.id && !selectedSetups.find(s => s.branch.id === r.branch.id)),
    ]);
  };

  const getBranchColor = (branchId: number) => (activeSetupColorsState[branchId] || '#9E9E9E');

  const renderColorPicker = () => {
    const handleChangeComplete = (color: ColorSelection) => {
      dispatch(SetupColorsSlice.actions.setSetupColorsState({ branchId: colorPickerBranchId, color: color.hex }));
    };

    if (colorPickerBranchId) {
      return ReactDOM.createPortal(
        <div
          className={styles.colorPopover}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setColorPickerBranchId(0);
            }
          }}
        >
          <div className={styles.pickerContainer}>
            <ChromePicker
              disableAlpha
              color={getBranchColor(colorPickerBranchId)}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        </div>,
        document.body
      );
    }
    return null;
  };

  const highlightedIds: number[] = selectableSetups.map(selection => selection.setup.id);
  highlightedIds.push(branchId); // Add baseline setup id
  const activeIds: number[] = highlightedIds.filter(id => selectedSetups.some(setup => setup.setup.id === id));
  return (
    <div className={styles.setupPaneContainer}>
      <Tooltip2 content="Add Setups(s)">
        <Button
          className={styles.addSetupButton}
          icon="plus"
          onClick={() => setIsDialogOpen(true)}
        />
      </Tooltip2>
      <div style={props.showExtended ? { minWidth: '305px' } : { minWidth: '70px' }}>
        <Divider style={props.showExtended ? { width: '90%' } : { width: '21px' }} />
        {(baselineSetup && baselineSetup.branch && baselineSetup.setup) && (
          <div className={styles.setupSelectRow}>
            <div className={styles.setupSelectRowDetail}>
              <SetupCheckbox
                checked
                disabled
                branchId={baselineSetup.branch.id}
                setupName={baselineSetup.setup.name}
                branchName={baselineSetup.branch.name}
                color={getBranchColor(baselineSetup.branch.id)}
                showExtended={props.showExtended}
                key={baselineSetup.branch.id.toString()}
                tooltip={`${baselineSetup.setup.name} - ${baselineSetup.branch.name}`  || ''}
              />
            </div>
            <div className={props.showExtended ? undefined : styles.setupSelectRowHide}>
              <Tooltip2
                className="btnEditSetupColor"
                content="Edit Setup Color"
              >
                <FontAwesomeIcon icon={faPencil} onClick={() => setColorPickerBranchId(baselineSetup.branch.id)} color={getBranchColor(baselineSetup.branch.id)} />
              </Tooltip2>
              <Button
                icon="cross"
                intent={Intent.DANGER}
                minimal
                disabled
                small
              />
            </div>
          </div>
        )}
        {selectableSetups.map((compareSetup: SetupSelection) => {
          const setupColor: string = getBranchColor(compareSetup.branch.id);
          return (
            <div className={styles.setupSelectRow}>
              <div className={styles.setupSelectRowDetail}>
                <SetupCheckbox
                  checked={selectedSetups.some(s => s.branch.id === compareSetup.branch.id)}
                  color={setupColor}
                  branchId={compareSetup.branch.id}
                  showExtended={props.showExtended}
                  setupName={compareSetup.setup.name}
                  branchName={compareSetup.branch.name}
                  key={compareSetup.branch.id.toString()}
                  tooltip={`${compareSetup.setup.name} - ${compareSetup.branch.name}`  || ''}
                  onChange={() => handleSelectSetup(compareSetup)}
                />
              </div>
              <div className={props.showExtended ? undefined : styles.setupSelectRowHide}>
                {activeIds.includes(compareSetup.setup.id) && (
                  <Tooltip2
                    className="btnEditSetupColor"
                    content="Edit Setup Color"
                  >
                    <FontAwesomeIcon icon={faPencil} onClick={() => setColorPickerBranchId(compareSetup.branch.id)} color={setupColor} />
                  </Tooltip2>
                )}
                <Button
                  icon="cross"
                  intent={Intent.DANGER}
                  minimal
                  onClick={() => handleRemoveSetup(compareSetup.branch.id)}
                  small
                />
              </div>
            </div>
          );
        })}
        <AddSetupsModal
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSuccess={onModalSuccess}
          highlightedRows={highlightedIds}
        />
        {renderColorPicker()}
      </div>
    </div>
  );
};
