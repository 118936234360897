import React, { CSSProperties, FocusEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { Alignment, Button, Checkbox, InputGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { cloneDeep, get, includes, isNil, set, snakeCase, startCase, unset } from 'lodash';
import { useRouteLoaderData } from 'react-router-dom';
import { Tooltip2 } from '@blueprintjs/popover2';
import {
  SelectItem,
  SetupUITemplate,
  SetupUITemplateContainerItem,
  SetupUITemplateFieldItem,
  SetupUITemplateGridItem,
  SetupUITemplateItem,
  SetupUITemplateItemType,
} from 'types';
import Accordion from 'components/Accordion';
import Select from 'components/Select';
import SelectPartModel from 'components/SelectorModal/part';
import {
  Part,
  SetupField,
  SetupFieldPosition,
  SetupFieldType,
  useSUITFormPartsLazyQuery,
} from 'graphql/generated/graphql';
import SUITGrid from '../SUITGrid';
import { suitGridPathToKey, suitPathToKey } from 'helpers/suit';
import IconTooltip from 'components/IconTooltip';
import { getOrgIds, getTeamPermissions, hasPermission } from '../../helpers/permissions';
import { OrganizationsList, PermissionName } from '../../constants';
import styles from './index.module.css';
import { getSetupDataValue, makeSetupDataValue, setSetupDataValue } from 'helpers/setup';
import { isValidNumber } from 'helpers/expressionsValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type SetupFieldHandler = (field: SetupField, value: boolean | number | string | Part | unknown) => void;
export type SetupFieldBlurHandler = (field: SetupField, value: boolean | number | string | Part | unknown) => void;

interface SetupViewProps {
  template: SetupUITemplate;
  setupFields: SetupField[];
  initialSetup?: object;
  onChange?: SetupFieldHandler;
  team?: string;
  organization?: string;
  onBlur?: SetupFieldBlurHandler;
  isDesignerMode: boolean;
  partsInSetup: Part[];
}

interface ExpressionFieldProps {
  field: SetupField;
  referencedFields: SetupField[];
  position?: SetupFieldPosition;
  collectionItemKey?: string;
  parentSetupField?: SetupField;
  initialSetup?: object;
  onExpressionChange: (
    field: SetupField,
    value: unknown,
    collectionItemKey?: string,
    parentSetupField?: SetupField
  ) => void;
}

type ChildObjectType = Record<string, unknown>;

const getFieldPath = (path: string, position?: SetupFieldPosition) => {
  if (!position) return path;
  if (!position.path_part) return `${path}_${snakeCase(position.label)}`;

  const pathParts = path.split('.');
  pathParts.splice(-1, 1, position.path_part);
  return pathParts.join('.');
};

const evaluateExpression = (
  field: SetupField,
  referencedFields: SetupField[],
  position?: SetupFieldPosition,
  initialSetup?: object,
) => {
  const expressionWithValues = referencedFields.reduce((expr, refField) => {
    const fieldId = refField.id;
    // Only apply position-based path modification to referenced fields
    const path = position && refField.positions?.length
      ? getFieldPath(refField.path, refField.positions?.find(p => p.label === position.label))
      : refField.path;
    const value = get(initialSetup, path, '');
    const safeValue = value === '' ? '0' : value;
    return expr.replace(
      new RegExp(`\\{${fieldId}\\}`, 'g'),
      safeValue.toString()
    );
  }, field.expression || '');

  try {
    // eslint-disable-next-line no-eval
    const result = eval(expressionWithValues);
    // eslint-disable-next-line no-restricted-globals
    return isNaN(result) ? '' : result;
  } catch {
    return '';
  }
};

const ExpressionField = ({
  field,
  referencedFields,
  position,
  collectionItemKey,
  parentSetupField,
  initialSetup,
  onExpressionChange,
}: ExpressionFieldProps) => {
  const expressionPath = field.path;

  const result = useMemo(() => {
    const newResult = evaluateExpression(field, referencedFields, position, initialSetup);
    return newResult === '' ? 0 : Number(newResult);
  }, [
    field.expression,
    ...referencedFields.map(refField => {
      const path = position && refField.positions?.length
        ? getFieldPath(refField.path, refField.positions?.find(p => p.label === position.label))
        : refField.path;
      const value = get(initialSetup, path, '');
      return Number(value) || 0;
    }),
  ]);

  useEffect(() => {
    const currentValue = get(initialSetup, expressionPath);
    if (typeof currentValue === 'string' || Number(currentValue) !== result) {
      onExpressionChange(field, result, collectionItemKey, parentSetupField);
    }
  }, [result, expressionPath, initialSetup]);

  return (
    <InputGroup
      value={result.toString()}
      key={`sf_expression_${expressionPath}`}
      fill
      disabled
      inputClassName={styles.customDisabled}
    />
  );
};

const SUITFormComponent = (props: SetupViewProps) => {
  const { template, setupFields, initialSetup, onChange, onBlur, isDesignerMode, partsInSetup } = props;
  const [isPartSelectorOpen, setPartSelectorOpen] = useState(false);
  const [selectorPartSF, setSelectorPartSF] = useState<SetupField>();
  const [partParentSF, setPartParentSF] = useState<SetupField>();
  const [partColKeySF, setPartColKeySF] = useState<string>();
  const [selectedPartId, setSelectedPartId] = useState<number>();
  const [parts, setParts] = useState<Part[]>(partsInSetup);
  const [tireIds, setTireIds] = useState<number[]>([]);

  // Query Tireset Tire Parts for specific ids
  const [getParts] = useSUITFormPartsLazyQuery();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let { getUser: { teams, permissions, organizations } } = useRouteLoaderData('root') as any;

  permissions = getTeamPermissions(
    props.team,
    teams,
    organizations,
    permissions,
    OrganizationsList.TRD
  );

  useEffect(() => {
    if (tireIds && tireIds.length > 0) {
      getParts({
        variables: {
          input: {
            ids: tireIds,
          },
        },
      })
        .then((data) => {
          if (data.data?.parts.__typename === 'PartsResult') {
            if (data.data?.parts?.rows) {
              const { rows } = data.data.parts;

              if (rows?.length) {
                setParts(prevParts => [...prevParts, ...(rows as Part[])]);
              }
            }
          }
        });
    }
  }, [getParts, tireIds]);

  // Refresh if changes.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => { }, [isPartSelectorOpen]);

  useEffect(() => {
    if (partsInSetup) {
      setParts(prevParts => [...prevParts, ...partsInSetup]);
    }
  }, [partsInSetup]);

  const handleOnBlur = (field: SetupField, value: unknown, parentSetupField?: SetupField, collectionItemKey?: string) => {
    if (field.type === SetupFieldType.FLOAT && typeof value === 'string') {
      const convertedValue = makeSetupDataValue(value, field.type, true);

      // Handle collection items
      if (parentSetupField && parentSetupField.type === SetupFieldType.COLLECTION && collectionItemKey && initialSetup) {
        const updatedCollectionObj = cloneDeep(get(initialSetup, parentSetupField.path, {}));
        setSetupDataValue(updatedCollectionObj, `${collectionItemKey}.${field.path}`, value, field.type, true);
        onChange?.(parentSetupField, updatedCollectionObj);
        return;
      }

      onBlur?.(field, convertedValue);
      return;
    }

    if (parentSetupField && parentSetupField.type === SetupFieldType.COLLECTION) return;

    onBlur?.(field, value);
  };

  const handleOnChange = (field: SetupField, value: unknown, collectionItemKey?: string, parentSetupField?: SetupField, tireSetPart?: Part) => {
    let updatedSetupField = field;
    let updatedValue;

    if (field.type === SetupFieldType.FLOAT && typeof value === 'string') {
      updatedValue = value;
    } else {
      updatedValue = makeSetupDataValue(value, field.type);
    }

    if (collectionItemKey && parentSetupField && initialSetup) { // If this is a dynamic collection type, we need to update the parent field/value
      const updatedCollectionObj = cloneDeep(get(initialSetup, parentSetupField.path, {}));

      // For FLOAT fields, don't force conversion while typing to allow decimal points
      const forceConversion = !(field.type === SetupFieldType.FLOAT && typeof value === 'string');

      setSetupDataValue(updatedCollectionObj, `${collectionItemKey}.${field.path}`, value, field.type, forceConversion);
      updatedSetupField = parentSetupField;
      updatedValue = updatedCollectionObj;
    }

    if (field.name === 'tire_set') {
      onChange?.(updatedSetupField, tireSetPart);
    } else {
      onChange?.(updatedSetupField, updatedValue);
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => e.target.select();

  const handleOpenPartSelectorClick = (position: SetupFieldPosition | undefined, field: SetupField, collectionItemKey?: string, parentSetupField?: SetupField, partId?: number) => {
    setSelectorPartSF(field);
    setPartSelectorOpen(true);
    setPartParentSF(parentSetupField);
    setPartColKeySF(collectionItemKey);
    setSelectedPartId(partId);
  };

  const onPartSelection = (selectedPart: Part, currentSF: SetupField) => {
    // Close the Part Selector Modal
    setPartSelectorOpen(false);

    // Add the selected part to the parts array
    setParts(prevParts => [...prevParts, selectedPart]);

    // Call onChange Setup Update handler
    if (handleOnChange) {
      handleOnChange(currentSF, selectedPart.id, partColKeySF, partParentSF, selectedPart);
    }

    if (currentSF.name === 'tire_set' && selectedPart) {
      const ids: number[] = [];
      ids.push(Number(get(selectedPart, 'data.lf._source', -1)));
      ids.push(Number(get(selectedPart, 'data.rf._source', -1)));
      ids.push(Number(get(selectedPart, 'data.lr._source', -1)));
      ids.push(Number(get(selectedPart, 'data.rr._source', -1)));
      setTireIds(ids);
    }
  };

  const renderSetupField = (
    field: SetupField,
    readOnly: boolean,
    position?: SetupFieldPosition,
    initialSetup?: object,
    onChange?: SetupFieldHandler,
    isChild?: boolean,
    childObj?: ChildObjectType,
    collectionItemKey?: string,
    parentSetupField?: SetupField
  ): ReactNode => {
    const dataObj = (isChild && childObj) ? childObj : initialSetup; // If Collection Child, data path relative to childObj parent
    let initialVal = getSetupDataValue(dataObj, field.path, field.type);
    if (isNil(initialVal)) initialVal = '';

    const renderControlWithLabel = (control: ReactNode, label: string | undefined, tooltip: string | '') => {
      if (isChild && label) {
        return (
          <div key={`${field.path}_wrapper`} className={styles.childWrapper}>
            <div className={styles.childLabel}>{label}</div>
            <div className={styles.childToolTip}>
              <IconTooltip
                className={styles.tooltipIcon}
                content={tooltip}
              />
            </div>
            {control}
          </div>
        );
      }
      return control;
    };

    const handleAddCollectionItemClick = () => {
      if (field.setup_field_array && Array.isArray(field.setup_field_array)) {
        const setupObjsData = cloneDeep(get(initialSetup, field.path, {}));
        if (initialSetup && Object.keys(setupObjsData).length === 0) {
          const collectionObj = {};
          field.setup_field_array.forEach((childField) => {
            set(collectionObj, `${field.name}_0.${childField.path}`, '');
          });
          onChange?.(field, collectionObj);
        } else if (initialSetup) {
          let index = 0;
          let found = false;
          let newKey = '';
          while (!found) {
            const existingKey = `${field.name}_${index}`;
            if (!Object.keys(setupObjsData).includes(existingKey)) {
              newKey = existingKey;
              found = true;
            }
            index++;
          }
          field.setup_field_array.forEach((childField) => {
            set(setupObjsData, `${newKey}.${childField.path}`, '');
          });
          onChange?.(field, setupObjsData);
        }
      }
    };

    const handleRemoveCollectionItemClick = (event: React.MouseEvent<HTMLElement>, itemKey: string) => {
      const setupObjsData = cloneDeep(get(initialSetup, field.path, {}));
      unset(setupObjsData, itemKey);
      onChange?.(field, setupObjsData);
    };

    const renderCollectionChildren = (parentSetupField: SetupField) => {
      const setupObjsData = get(initialSetup, field.path, {});
      const childSetupFields: SetupField[] = field.setup_field_array || [];

      // Create a mapping between child_ids and child fields
      const childFieldMap = new Map();
      childSetupFields.forEach((field) => {
        childFieldMap.set(field.id, field);
      });

      return (
        <div>
          {Object.entries(setupObjsData).map(([key, setupObjData], index) => (
            <div key={`sf_parent_${key}`} className={styles.childContainer}>
              {/* Render child fields in the order of child_ids */}
              {Array.from(childFieldMap.values()).map((childField) => {
                return (
                  <div key={`sf_child_${childField.id}`}>
                    {renderSetupField(childField, readOnly, undefined, initialSetup, onChange, true, setupObjData as Record<string, unknown>, key, parentSetupField)}
                  </div>
                );
              })}

              {Object.keys(setupObjsData).length > 0 ? (
                <div key={`sf_parent_controls_${key}`} className={styles.childControls}>
                  <Button
                    intent={Intent.DANGER}
                    key={`remove_setup_field_collection_item_${index}`}
                    icon="cross"
                    onClick={(event) => handleRemoveCollectionItemClick(event, key)}
                  />
                  {(Object.keys(setupObjsData).length === (index + 1))
                    && (
                      <Button
                        intent={Intent.SUCCESS}
                        key={`add_setup_field_collection_item_${index}`}
                        icon="plus"
                        onClick={handleAddCollectionItemClick}
                      />
                    )}
                </div>
              ) : (
                <span style={{ minWidth: '30px' }} />
              )}
            </div>
          ))}

          {Object.keys(setupObjsData).length === 0 && initialSetup && (
            <div>
              <Button
                intent={Intent.SUCCESS}
                key="add_setup_field_collection_item_0"
                icon="plus"
                onClick={handleAddCollectionItemClick}
              />
            </div>
          )}

          {Object.keys(setupObjsData).length === 0 && !initialSetup && (
            <div key="sf_parent_" className={styles.childContainer}>
              {Array.from(childFieldMap.values()).map((childField) => {
                return (
                  <div key={`sf_child_${childField.id}`}>
                    {renderSetupField(childField, readOnly, undefined, initialSetup, onChange, true)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    };

    if (!field) return 'Missing SetupField';

    const getPartId = (partPath: string) => {
      const dataObj = (isChild && childObj) ? childObj : initialSetup;
      return getSetupDataValue(dataObj, partPath, SetupFieldType.PART);
    };

    const getPartDescription = (partPath: string): string => {
      const dataObj = (isChild && childObj) ? childObj : initialSetup;

      if (!dataObj) return 'Select Part';
      const partId: number = getSetupDataValue(dataObj, partPath, SetupFieldType.PART);

      // Early return if invalid ID
      if (partId === -1) return 'Select Part';

      // Create parts lookup once outside the function
      const partsLookup = new Map<number, typeof parts[number]>(
        parts.map(part => [part.id, part])
      );

      const part = partsLookup.get(partId);
      if (!part) return 'Select Part';

      let desc = part.description || 'Select Part';

      if (part.part_number) {
        desc += ` / PN ${part.part_number}`;
      }

      if (part.serial_number) {
        desc += ` / SN ${part.serial_number}`;
      }

      return desc;
    };

    switch (field.type) {
      case SetupFieldType.COLLECTION: {
        if (!field.setup_field_array || !Array.isArray(field.setup_field_array)) {
          return 'MISSING SetupFIELD Collection Items';
        }
        return renderCollectionChildren({ ...field });
      }
      case SetupFieldType.PART: {
        const isReadOnly = true;
        return renderControlWithLabel(
          <div>
            <div className={styles.partSelector}>
              <Tooltip2 content={getPartDescription(field.path)}>
                <InputGroup
                  fill
                  key={`sf_string_${field.path}`}
                  value={getPartDescription(field.path)}
                  onFocus={handleFocus}
                  readOnly={isReadOnly}
                  style={{ width: '250px', textOverflow: 'ellipsis' }}
                  inputClassName={readOnly ? styles.customDisabled : undefined}
                />
              </Tooltip2>
              <Button
                onClick={() => handleOpenPartSelectorClick(position, field, collectionItemKey, parentSetupField, getPartId(field.path))}
              >
                <FontAwesomeIcon
                  icon="ellipsis-h"
                />
                <i
                  style={{ pointerEvents: 'none' }}
                  className="fa fa-fw fa-ellipsis-h"
                />
              </Button>
            </div>
          </div>,
          field.label,
          field.tooltip || ''
        );
      }
      case SetupFieldType.STRING: {
        if (field.options && field.options.length > 0) {
          const options: SelectItem<string>[] = field.options.map(o => ({ label: o, value: o }));
          const initialOption = options.find(o => o.value === initialVal);
          const noSelectionText = position ? `${position.label} Option` : 'Select Option';
          const buttonStyles = readOnly ? classNames(styles.selectButton, styles.customDisabled) : styles.selectButton;
          return renderControlWithLabel(
            <Select
              value={initialOption}
              buttonProps={{ className: buttonStyles, fill: true }}
              fill
              items={options}
              key={`sf_string_${field.path}`}
              noSelectionText={noSelectionText}
              onChange={(item) => handleOnChange(field, item.value, collectionItemKey, parentSetupField)}
              disabled={readOnly}
            />,
            field.label,
            field.tooltip || ''
          );
        }
        return renderControlWithLabel(
          <InputGroup
            fill
            key={`sf_string_${field.path}`}
            placeholder={position?.label}
            onChange={(e) => handleOnChange(field, e.target.value, collectionItemKey, parentSetupField)}
            value={initialVal}
            onFocus={handleFocus}
            disabled={readOnly}
            inputClassName={readOnly ? styles.customDisabled : undefined}
          />,
          field.label,
          field.tooltip || ''
        );
      }
      case SetupFieldType.INT: {
        if (field.options && field.options.length > 0) {
          // Because both options and initialVal can be either strings or numbers, we need to convert both to strings.
          // Once we run a migration to force all INT options to be numbers we can remove this.
          const options: SelectItem<string>[] = field.options.map(o => ({
            label: o,
            value: o !== undefined && o !== null ? String(o) : '',
          }));

          const initialValStr = initialVal !== undefined && initialVal !== null ? String(initialVal) : '';
          const initialOption = options.find(o => o.value === initialValStr);

          const noSelectionText = position ? `${position.label} Option` : 'Select Option';
          const buttonStyles = readOnly ? classNames(styles.selectButton, styles.customDisabled) : styles.selectButton;
          return renderControlWithLabel(
            <Select
              value={initialOption}
              buttonProps={{ className: buttonStyles, fill: true }}
              fill
              items={options}
              key={`sf_int_${field.path}`}
              noSelectionText={noSelectionText}
              onChange={(item) => handleOnChange(field, item.value, collectionItemKey, parentSetupField)}
              disabled={readOnly}
            />,
            field.label,
            field.tooltip || ''
          );
        }
        return renderControlWithLabel(
          <InputGroup
            value={initialVal}
            key={`sf_int_${field.path}`}
            fill
            placeholder={position?.label}
            onChange={(e) => handleOnChange(field, e.target.value, collectionItemKey, parentSetupField)}
            onBlur={(e) => handleOnBlur(field, e.target.value, parentSetupField, collectionItemKey)}
            onFocus={handleFocus}
            disabled={readOnly}
            inputClassName={
              (() => {
                // if (Number.isNaN(initialVal)) return 'red-input';
                if (readOnly) return styles.customDisabled;
                return undefined;
              })()
            }
            style={{
              borderColor: isValidNumber(initialVal) ? undefined : 'red',
              borderWidth: isValidNumber(initialVal) ? undefined : '1px',
              borderStyle: isValidNumber(initialVal) ? undefined : 'solid',
              color: isValidNumber(initialVal) ? undefined : 'red',
            }}
          />,
          field.label,
          field.tooltip || ''
        );
      }
      case SetupFieldType.FLOAT: {
        return renderControlWithLabel(
          <InputGroup
            value={initialVal}
            key={`sf_float_${field.path}`}
            fill
            placeholder={position?.label}
            onChange={(e) => handleOnChange(field, e.target.value, collectionItemKey, parentSetupField)}
            onBlur={(e) => handleOnBlur(field, e.target.value, parentSetupField, collectionItemKey)}
            onFocus={handleFocus}
            disabled={readOnly}
            inputClassName={
              (() => {
                // if (Number.isNaN(initialVal)) return 'red-input';
                if (readOnly) return styles.customDisabled;
                return undefined;
              })()
            }
            style={{
              borderColor: isValidNumber(initialVal) ? undefined : 'red',
              borderWidth: isValidNumber(initialVal) ? undefined : '1px',
              borderStyle: isValidNumber(initialVal) ? undefined : 'solid',
              color: isValidNumber(initialVal) ? undefined : 'red',
            }}
          />,
          field.label,
          field.tooltip || ''
        );
      }
      case SetupFieldType.BOOLEAN: {
        return renderControlWithLabel(
          <Checkbox
            alignIndicator={Alignment.RIGHT}
            className={styles.booleanInput}
            checked={initialVal}
            key={`sf_boolean_${field.path}`}
            label={position && position.label}
            onChange={() => handleOnChange(field, !initialVal, collectionItemKey, parentSetupField)}
            disabled={readOnly}
          />,
          field.label,
          field.tooltip || ''
        );
      }
      case SetupFieldType.EXPRESSION: {
        const fieldRegex = /\{(\d+)\}/g;
        const referencedIds = Array.from(field.expression?.matchAll(fieldRegex) || [])
          .map(match => parseInt(match[1], 10));

        const referencedFields = referencedIds
          .map(id => setupFields.find(f => f.id === id))
          .filter((f): f is SetupField => f !== undefined);

        const hasPositions = referencedFields.some(f => f.positions && f.positions.length > 0);

        if (hasPositions) {
          const allPositions = referencedFields
            .flatMap(f => f.positions || [])
            .filter((pos, index, self) => index === self.findIndex(p => p.label === pos.label));

          return (
            <div>
              <div style={{ display: 'flex', gap: '10px' }}>
                {allPositions.map(position => (
                  <div
                    key={`sf_expression_${field.path}_${position.label}`}
                    style={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <div style={{ minWidth: 'fit-content' }}>{position.label}</div>
                    <ExpressionField
                      field={field}
                      referencedFields={referencedFields}
                      position={position}
                      collectionItemKey={collectionItemKey}
                      parentSetupField={parentSetupField}
                      initialSetup={initialSetup}
                      onExpressionChange={handleOnChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        }

        return renderControlWithLabel(
          <ExpressionField
            field={field}
            referencedFields={referencedFields}
            collectionItemKey={collectionItemKey}
            parentSetupField={parentSetupField}
            initialSetup={initialSetup}
            onExpressionChange={handleOnChange}
          />,
          field.label,
          field.tooltip || ''
        );
      }

      default:
        return null;
    }
  };

  const renderTemplateFieldItem = (
    item: SetupUITemplateFieldItem,
    setupFields: SetupField[],
    readOnly: boolean,
    initialSetup?: object,
    onChange?: SetupFieldHandler,
  ) => {
    const field = setupFields.find(f => f.name === item.setup_field);
    if (!field) return null;

    let inputs: ReactNode[];
    let inputClass;
    let inputStyles: CSSProperties = {};
    // If this field has `positions`, generates one field for each, passing the
    // position to the field render function and modifies the label for relevant
    // display
    if (field.positions && field.positions.length > 0) {
      inputs = field.positions.map(pos => {
        const expandedField = {
          ...field,
          path: getFieldPath(field.path, pos),
        };
        if (field.label) expandedField.label += ` ${pos}`;
        return renderSetupField(expandedField, readOnly, pos, initialSetup, onChange);
      });
      inputClass = classNames({
        [styles.grid]: true,
        [styles[`positions${field.positions.length}`]]: isNil(item.num_columns),
      });

      if (!isNil(item.num_columns)) {
        inputStyles = {
          gridTemplateColumns: `repeat(${item.num_columns}, 1fr)`,
        };
      }
    } else {
      inputs = [renderSetupField(field, readOnly, undefined, initialSetup, onChange)];
    }

    return (
      <div key={field.path} className={styles.inputRow}>
        <div key={`${field.path}root-bp4-ui`} className="root-bp4-ui">
          <span className="bp4-ui-text">{item.label ?? field.label}</span>
          {field.tooltip && (
            <IconTooltip
              className={styles.tooltipIcon}
              content={field.tooltip}
            />
          )}
        </div>
        <div
          key={`${field.path}inputs`}
          className={classNames(styles.inputs, inputClass)}
          style={inputStyles}
        >{inputs}
        </div>
      </div>
    );
  };

  const renderTemplateItem = (
    path: SetupUITemplateItem[],
    setupFields: SetupField[],
    initialSetup?: object,
    onChange?: SetupFieldHandler
  ): ReactNode => {
    const item = path[path.length - 1];
    if (item.type === SetupUITemplateItemType.CONTAINER) {
      // Filter what's viewable by permissions
      if (!hasPermission(PermissionName.SETUP_WRITE, permissions) && !hasPermission(PermissionName.SETUP_READ, permissions)) {
        if (item.name.toLowerCase() !== 'car') {
          if (!hasPermission(`setup_${item.name.toLowerCase()}_read`, permissions) && !hasPermission(`setup_${item.name.toLowerCase()}_write`, permissions)) return null;
        }
      }
      const id = suitPathToKey(path as SetupUITemplateContainerItem[]);
      return (
        <Accordion
          className={styles.itemContainer}
          id={id}
          initialOpen
          key={item.name}
          title={item.label ?? startCase(item.name)}
          buttonProps={{
            className: styles.accordionHeader,
          }}
        >
          <div className={styles.sectionContainer}>
            {item.items.map(innerItem => renderTemplateItem([...path, innerItem], setupFields, initialSetup, onChange))}
          </div>
        </Accordion>
      );
    }
    if (item.type === SetupUITemplateItemType.GRID) {
      const id = suitGridPathToKey(path as SetupUITemplateGridItem[]);
      const childrenArray: ReactNode[] = [];

      const getAlignItems = (fieldType: string): string => {
        const foundField = setupFields.find(item => item.name.toLowerCase() === fieldType.toLowerCase());

        if (!foundField) {
          return 'flex-start';
        }

        return foundField.type === 'BOOLEAN' ? 'flex-end' : 'flex-start';
      };

      item.items.forEach((innerItem) => {
        if (innerItem.type === 'field') {
          const child = renderTemplateItem([...path, innerItem], setupFields, initialSetup, onChange);
          if (child) {
            if (React.isValidElement(child)) {
              const typedChild = child as React.ReactElement;
              const keyedChild = React.cloneElement(typedChild, {
                key: `${innerItem.row}-${innerItem.column}`,
                style: {
                  ...typedChild.props.style,
                  backgroundColor: 'transparent',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: getAlignItems(innerItem.setup_field),
                  justifyContent: 'space-around',
                },
              });
              childrenArray.push(keyedChild);
            }
          }
        }
      });
      return (
        <div key={`${id}inputRow`} className={styles.inputRow}>
          <SUITGrid
            label={item.label}
            id={id}
            key={id}
            numColumns={item.num_columns || 0}
            numRows={item.num_rows || 0}
            isDesignerMode={isDesignerMode}
          >
            {childrenArray}
          </SUITGrid>
        </div>
      );
    }
    // Check permission to see if field should be read only
    const parentContainer = path[path.length - 2] as SetupUITemplateContainerItem;

    let readOnly = false;

    // Set read only permissions
    if (hasPermission(PermissionName.SETUP_READ, permissions)) {
      readOnly = true;
    } else {
      readOnly = hasPermission(`setup_${parentContainer.name.toLowerCase()}_read`, permissions);
    }

    // Set to Read Only if the setup is owend by TRD and the user is not in the TRD org
    if (props.organization === OrganizationsList.TRD
      && !includes(getOrgIds(organizations), OrganizationsList.TRD)) {
      readOnly = true;
    }

    return renderTemplateFieldItem(item, setupFields, readOnly, initialSetup, onChange);
  };

  return (
    <div className={styles.containerRelative}>
      {template?.items.map(item => renderTemplateItem([item], setupFields, initialSetup, onChange))}
      {selectorPartSF && (
        <SelectPartModel
          key={`selected_part_${selectorPartSF?.path || ''}`}
          isOpen={isPartSelectorOpen}
          onClose={() => setPartSelectorOpen(false)}
          onSelected={onPartSelection}
          setupField={selectorPartSF}
          partId={selectedPartId}
        />
      )}
    </div>
  );
};

export default SUITFormComponent;
