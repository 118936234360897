import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Intent, Button, NonIdealState, H3, Icon, Card, H4, UL, Position } from '@blueprintjs/core';
import jsonPatch, { Operation } from 'fast-json-patch';
import { cloneDeep, get, isEmpty, isNil, isObject, size, snakeCase, startsWith } from 'lodash';

import SetupCard, { Selections, TemplateMeta } from 'components/SetupCard';
import Select from 'components/Select';
import AddSetupsModal from 'components/SelectorModal/setup';
import {
  SetupBranch,
  useSUITByIdQuery,
  useSetupByIdLazyQuery,
  useApplyPatchToSetupMutation,
  useSetupMergeCommonAncestorLazyQuery,
  Setup,
  SetupField,
  useSetupFieldsQuery,
  useSetupBranchByIdQuery,
  useSetupBranchByIdLazyQuery,
  useSetupByBranchIdQuery,
  useSetupBranchesByRootIdQuery,
} from 'graphql/generated/graphql';
import { excludeMetaForCompare, getSetupDataValue, compareSetupValues } from 'helpers/setup';
import { generateTemplateMeta } from 'helpers/suit';
import getSetupFieldProps, { SetupFieldProps } from 'helpers/setupField';
import AppToaster from 'helpers/toaster';
import { selectActiveSUITId } from 'reducers/suit';
import {
  GQLSetup,
  SelectItem,
  SetupSelection,
  SetupUITemplate,
  SetupUITemplateFieldItem,
  SetupUITemplateItem,
  SetupUITemplateItemType,
} from 'types';

import styles from './index.module.css';
import IconTooltip from 'components/IconTooltip';

type MergeData = {
  op: Operation;
  conflictingTargetOp?: Operation;
}

type VisibleFieldItemWithMergeData = SetupFieldProps & MergeData;
type VisibleFieldsWithMergeData = {
  [key: string]: VisibleFieldItemWithMergeData[];
};

enum SourceType {
  NonCommonRootSetup,
  Branch
}

const removeDataPathPrefix = (opPath: string) => opPath.replace(/^\/data/, '');
const jsonOpPathToDot = (opPath: string) => opPath.replaceAll('/', '.').slice(1);
const decomposeAddPatch = (patch: Operation) => {
  if (patch.op !== 'add' || typeof patch.value !== 'object') return [patch];

  let patches: Operation[] = [];
  Object.entries(patch.value).forEach(([key, value]) => {
    patches = patches.concat(decomposeAddPatch({
      op: 'add',
      path: `${patch.path}/${key}`,
      value,
    }));
  });
  return patches;
};
const decomposeRemovePatch = (patch: Operation, comparisonObj: unknown) => {
  const elementToRemove = get(comparisonObj, patch.path.slice(1).replaceAll('/', '.'));
  if (patch.op !== 'remove' || !isObject(elementToRemove)) return [patch];

  let patches: Operation[] = [];
  Object.keys(elementToRemove).forEach(key => {
    patches = patches.concat(decomposeRemovePatch({
      op: 'remove',
      path: `${patch.path}/${key}`,
    }, comparisonObj));
  });
  return patches;
};

/**
 * Flatten out ops that contain object as values; we end up with one operation per field
 */
const decomposePatch = (patches: Operation[], comparisonObj: unknown | undefined) => {
  const newPatches = patches.flatMap(decomposeAddPatch);
  return newPatches.flatMap(p => decomposeRemovePatch(p, comparisonObj));
};

export default () => {
  /**
   * Misc. hooks
   */
  const params = useParams();
  const branchId = Number(params.branchId);

  /**
   * Selectors
   */
  const activeSUITId = useSelector(selectActiveSUITId);

  /**
   * Local state
   */
  const [setupFields, setSetupFields] = useState<SetupField[]>([]);
  const [branches, setBranches] = useState<SetupBranch[]>([]);
  const [sourceType, setSourceType] = useState<SourceType>(SourceType.Branch);
  const [sourceBranch, setSourceBranch] = useState<SetupBranch>();
  const [sourceNonCommonRootBranch, setSourceNonCommonRootBranch] = useState<SetupBranch>();
  const [sourceSetup, setSourceSetup] = useState<GQLSetup>();
  const [targetBranch, setTargetBranch] = useState<SetupBranch>();
  const [targetSetup, setTargetSetup] = useState<GQLSetup>();
  const [diffList, setDiffList] = useState<VisibleFieldsWithMergeData>({});
  const [unsuitedDiffList, setUnsuitedDiffList] = useState<MergeData[]>([]);
  const [selections, setSelections] = useState<Selections>();
  const [activeSUITTemplate, setActiveSUITTemplate] = useState<SetupUITemplate>();
  const [isSetupSelectorOpen, setIsSetupSelectorOpen] = useState(false);
  const [templateMeta, setTemplateMeta] = useState<TemplateMeta>({
    containers: {},
    fields: {},
  });

  /**
   * Immediate queries
   */
  useSetupFieldsQuery({
    onCompleted: data => setSetupFields(data.setupFields.rows as SetupField[]),
  });
  useSUITByIdQuery({
    variables: { id: activeSUITId || -1 },
    skip: !activeSUITId,
    onCompleted: data => {
      if (data.suit) setActiveSUITTemplate(data.suit.template);
    },
  });
  useSetupBranchByIdQuery({
    variables: { id: branchId },
    onCompleted: data => setTargetBranch(data.branch as SetupBranch),
    fetchPolicy: 'network-only',
  });
  useSetupByBranchIdQuery({
    variables: { branchId },
    onCompleted: data => setTargetSetup(data.setup as GQLSetup),
    fetchPolicy: 'network-only',
  });
  useSetupBranchesByRootIdQuery({
    variables: { rootId: targetBranch?.root.id ?? -1 },
    skip: !targetBranch,
    onCompleted: data => setBranches(data.branches as SetupBranch[]),
  });

  /**
   * Lazy queries
   */
  const [getCommon] = useSetupMergeCommonAncestorLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [getSetup] = useSetupByIdLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [applyPatch] = useApplyPatchToSetupMutation();
  const [getBranch] = useSetupBranchByIdLazyQuery();

  useEffect(() => {
    let setupIdToFetch;
    if (sourceType === SourceType.Branch && sourceBranch) {
      setupIdToFetch = sourceBranch.head.id;
    } else if (sourceType === SourceType.NonCommonRootSetup && sourceNonCommonRootBranch) {
      setupIdToFetch = sourceNonCommonRootBranch.head.id;
    }

    if (!setupIdToFetch) return;

    getSetup({
      variables: {
        id: setupIdToFetch,
      },
      onCompleted: ({ setup }) => {
        setSourceSetup({ ...setup });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to get source setup: ${e.message}`,
        });
      },
    });
  }, [sourceBranch, sourceType, sourceNonCommonRootBranch]);

  /**
   * Effects
   */
  useEffect(() => {
    if (activeSUITTemplate) {
      const meta: TemplateMeta = {
        containers: {},
        fields: {},
      };
      activeSUITTemplate.items.map(i => generateTemplateMeta([i], meta, setupFields, false));

      const diffKeys = Object.keys(diffList);
      for (let i = 0; i < diffKeys.length; i++) {
        const diffs = diffList[diffKeys[i]];
        for (let j = 0; j < diffs.length; j++) {
          const diff = diffs[j];
          meta.fields[diff.path].visible = true;

          if (diff.conflictingTargetOp) {
            meta.fields[diff.path].rightElement = (
              <IconTooltip
                content="Changes were made to the same field on both branches, causing a conflict"
                icon="warning-sign"
                iconIntent={Intent.DANGER}
              />
            );
          }
        }
      }

      setTemplateMeta(meta);
    }
  }, [activeSUITTemplate, targetSetup, sourceSetup, diffList]);

  useEffect(() => {
    if (!targetSetup || !sourceSetup) return;

    getCommon({
      variables: {
        setupIdA: targetSetup.id,
        setupIdB: sourceSetup.id,
      },
      onCompleted: ({ commonAncestor }) => {
        if (commonAncestor) {
          updateDiffList(targetSetup, sourceSetup, commonAncestor);
        } else {
          updateDiffList(targetSetup, sourceSetup);
        }
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Could not load common node for comparison. Refresh page and try again: ${e.message}`,
        });
      },
    });
  }, [targetSetup?.id, sourceSetup?.id]);

  const isSourceAvailable = () => {
    if (sourceType === SourceType.Branch && !sourceBranch) return false;
    if (sourceType === SourceType.NonCommonRootSetup && !sourceNonCommonRootBranch) return false;

    return true;
  };

  const getSourceBranchBySourceType = () => {
    if (sourceType === SourceType.Branch) return sourceBranch;
    return sourceNonCommonRootBranch;
  };

  const sourceTypeItems = [
    { label: 'Setup', value: SourceType.NonCommonRootSetup },
    { label: 'Branch', value: SourceType.Branch },
  ];

  const branchItems = branches
    .filter(b => b.id !== branchId)
    .map(b => ({ label: b.name, value: b }));

  const onSourceTypeChange = (item: SelectItem<SourceType>) => {
    setSourceType(item.value);
  };

  const onBranchChange = (item: SelectItem<SetupBranch>) => {
    setSourceBranch(item.value);
  };

  const onMerge = () => {
    if (!selections || !isSourceAvailable()) return;

    const allFieldItems = Object.values(diffList).reduce((acc, diffs) => {
      diffs.forEach(diff => { acc[diff.path] = diff; });
      return acc;
    }, {} as { [path: string]: VisibleFieldItemWithMergeData });

    const ops = Object.entries(selections)
      .filter(([, selected]) => selected)
      .map(([path]) => allFieldItems[path].op);

    applyPatch({
      variables: {
        // Disabling eslint for next line here because `isSourceAvailable()` is already checking for undefined source setup branch
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fromBranchId: getSourceBranchBySourceType()!.id,
        toBranchId: branchId,
        ops,
        fullMerge: ops.length === size(allFieldItems),
      },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Applied selected patches',
        });
        setTargetSetup(data.setup as GQLSetup);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to apply patch: ${e.message}`,
        });
      },
    });
  };

  const updateDiffList = (target: GQLSetup, source: GQLSetup, common?: GQLSetup) => {
    const differenceList: VisibleFieldsWithMergeData = {};
    let targetJsonPatchOps: Operation[] = [];
    let sourceJsonPathOps: Operation[] = [];
    if (common) {
      targetJsonPatchOps = jsonPatch.compare(excludeMetaForCompare(common), excludeMetaForCompare(target));
      targetJsonPatchOps = decomposePatch(targetJsonPatchOps, common);

      sourceJsonPathOps = jsonPatch.compare(excludeMetaForCompare(common), excludeMetaForCompare(source));
      sourceJsonPathOps = decomposePatch(sourceJsonPathOps, common);
    } else {
      sourceJsonPathOps = jsonPatch.compare(excludeMetaForCompare(target), excludeMetaForCompare(source));
      sourceJsonPathOps = decomposePatch(sourceJsonPathOps, target);
    }

    if (!activeSUITTemplate) return;

    const handleSetupField = (path: SetupUITemplateItem[]): void => {
      const field = path[path.length - 1] as SetupUITemplateFieldItem;
      const key = path.slice(0, -1).map(p => snakeCase(p.label)).join('.');
      const setupProps = getSetupFieldProps(field.setup_field, setupFields, field.label);
      setupProps.forEach(setupProp => {
        // Find direct match for the path
        let sourceOp = sourceJsonPathOps.find(o => {
          return jsonOpPathToDot(removeDataPathPrefix(o.path)) === setupProp.path;
        });

        // If no direct match, check for ._source or ._exp suffixed paths
        if (!sourceOp) {
          sourceOp = sourceJsonPathOps.find(o => {
            const path = jsonOpPathToDot(removeDataPathPrefix(o.path));
            return (path === `${setupProp.path}._source` || path === `${setupProp.path}._exp`);
          });
        }

        if (sourceOp) {
          const targetVal = getSetupDataValue(target.data, setupProp.path, setupProp.type);
          const sourceVal = getSetupDataValue(source.data, setupProp.path, setupProp.type);
          const areValuesEqual = compareSetupValues(targetVal, sourceVal);

          if (!areValuesEqual) {
            const diffItem: VisibleFieldItemWithMergeData = {
              ...setupProp,
              op: sourceOp,
            };

            if (common) {
              // Checks for conflicting target op
              const targetOp = targetJsonPatchOps.find(o => jsonOpPathToDot(removeDataPathPrefix(o.path)) === setupProp.path);
              if (targetOp) diffItem.conflictingTargetOp = targetOp;
            }

            if (isNil(differenceList[key])) differenceList[key] = [diffItem];
            else differenceList[key].push(diffItem);
          }
        }
      });
    };

    const traverseTemplateItem = (path: SetupUITemplateItem[]): void => {
      const item = path[path.length - 1];
      if (item.type === SetupUITemplateItemType.CONTAINER || item.type === SetupUITemplateItemType.GRID) {
        item.items.map(i => traverseTemplateItem([...path, i]));
      } else {
        handleSetupField(path);
      }
    };

    activeSUITTemplate.items.forEach(i => traverseTemplateItem([i]));
    setDiffList(differenceList);

    // Helper function to check if a path is already in the diff list
    const isPathInDiffList = (path: string, diffListPaths: string[]): boolean => {
      if (diffListPaths.includes(path)) return true;

      if (path.endsWith('._source') || path.endsWith('._exp')) {
        const basePath = path.replace(/\._source$|\._exp$/, '');
        return diffListPaths.includes(basePath);
      }

      return false;
    };

    // update unsuitedDiffList: list of diffs whose paths are not in the currently selected SUIT
    let diffListPaths: string[] = [];
    Object.keys(differenceList).forEach(key => {
      diffListPaths = diffListPaths.concat(differenceList[key].map(item => item.path));
    });

    // unsuitedSourceOps: Source operations that are:
    // 1) Starts with /data
    // 2) Not found in main merge list (where users could select field to merge)
    // 3) Value in source vs target are different given an operation's path
    const unsuitedSourceOps = sourceJsonPathOps.filter(item => {
      if (!startsWith(item.path, '/data')) return false;

      const path = jsonOpPathToDot(removeDataPathPrefix(item.path));

      // Skip if this path is already in the diff list
      if (isPathInDiffList(path, diffListPaths)) return false;

      const targetRawVal = get(target.data, path);
      const sourceRawVal = get(source.data, path);
      return !compareSetupValues(targetRawVal, sourceRawVal);
    });

    setUnsuitedDiffList(unsuitedSourceOps.map(item => {
      const unsuitedDiffItem: MergeData = { op: item };
      if (common) {
        // Checks for conflicting target op
        const targetOp = targetJsonPatchOps.find(o => jsonOpPathToDot(removeDataPathPrefix(o.path)) === item.path);
        if (targetOp) unsuitedDiffItem.conflictingTargetOp = targetOp;
      }

      return unsuitedDiffItem;
    }));
  };

  const onContainerCollapse = (id: string | undefined, newOpenState: boolean) => {
    if (id && templateMeta) {
      const newMeta = cloneDeep(templateMeta);
      newMeta.containers[id].open = newOpenState;
      setTemplateMeta(newMeta);
    }
  };

  const renderMergeSourceSelector = () => {
    if (sourceType === SourceType.Branch) {
      return (
        <Select
          items={branchItems}
          noSelectionText="source branch"
          value={branchItems.find(i => i.value === sourceBranch)}
          onChange={onBranchChange}
        />
      );
    }

    const onSetupSelectionDone = async (selections: SetupSelection[]) => {
      setIsSetupSelectorOpen(false);
      getBranch({
        variables: {
          id: selections[0].branch.id,
        },
        onCompleted: ({ branch }) => {
          setSourceNonCommonRootBranch(branch as SetupBranch);
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Failed to get source branch: ${e.message}`,
          });
        },
      });
    };

    return (
      <>
        <div className={styles.chooseSetupButton}>
          <Button
            className={styles.addSetupsContainer}
            text={sourceNonCommonRootBranch ? sourceSetup?.name : 'Choose Setup...'}
            onClick={() => setIsSetupSelectorOpen(true)}
          />
        </div>
        <AddSetupsModal
          isOpen={isSetupSelectorOpen}
          onClose={() => setIsSetupSelectorOpen(false)}
          onSuccess={onSetupSelectionDone}
          singleSelection
        />
      </>
    );
  };

  const renderUnsuitedDiffList = () => {
    if (unsuitedDiffList.length === 0) return null;

    return (
      <div className={styles.unsuited}>
        <Card>
          <H4>
            Additional differences not displayed by SUIT
            <IconTooltip
              className={styles.addlDiffTooltipIcon}
              content={(
                <p className={styles.addlDiffTooltipContent}>
                  List of paths in your setup document that contains differences but aren't displayed
                  above because the selected SUIT was not configured to show these fields.
                  <br />
                  <br />
                  In order to merge them, edit your SUIT to include these fields and refresh this view.
                </p>
              )}
              position={Position.RIGHT}
            />
          </H4>
          <UL>
            {unsuitedDiffList.map(item => (
              <li>
                <code className="bp4-code">{jsonOpPathToDot(removeDataPathPrefix(item.op.path))}</code>
              </li>
            ))}
          </UL>
        </Card>
      </div>
    );
  };

  const renderContent = () => {
    let content = null;

    // TODO: Show loading
    if (!targetSetup) return null;

    if (!activeSUITTemplate) {
      content = (
        <NonIdealState
          description="Select a SUIT prior to merging."
          icon="git-merge"
          title="No SUIT selected"
        />
      );
    } else if (!isSourceAvailable()) {
      const sourceTxt = sourceType === SourceType.Branch ? 'branch' : 'setup';
      content = (
        <NonIdealState
          description={`Select a source ${sourceTxt} from which to merge`}
          icon="git-merge"
          title={`No source ${sourceTxt} selected`}
        />
      );
    } else if (isEmpty(diffList)) {
      content = (
        <NonIdealState
          description="There are no differences between the target and source branches"
          icon="git-merge"
          title="No differences detected"
        />
      );
    } else {
      content = (
        <div className={styles.mainContent}>
          <div className={styles.cardsContainer}>
            <SetupCard
              isBaseline
              isMergeTarget
              onContainerCollapse={onContainerCollapse}
              setup={targetSetup as Setup}
              branchName={targetBranch?.name}
              setupFields={setupFields}
              template={activeSUITTemplate}
              templateMeta={templateMeta}
            />
            <Icon icon="arrow-left" size={40} />
            <SetupCard
              enableSelections
              onContainerCollapse={onContainerCollapse}
              onSelectionsChange={setSelections}
              setup={sourceSetup as Setup}
              branchName={sourceBranch?.name}
              setupFields={setupFields}
              template={activeSUITTemplate}
              templateMeta={templateMeta}
            />
          </div>
          <div className={styles.mergeButtonContainer}>
            <Button
              disabled={!selections}
              intent={Intent.PRIMARY}
              onClick={onMerge}
            >
              Merge Selected
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.mainContainer}>
        <div className={styles.compareAndMerge}>
          {content}
        </div>
        {renderUnsuitedDiffList()}
      </div>
    );
  };

  return (
    <div>
      <div className={styles.mergeHeading}>
        <H3>Merging to "{targetBranch?.name}" from </H3>
        <Select
          items={sourceTypeItems}
          noSelectionText="source type"
          value={sourceTypeItems.find(i => i.value === sourceType)}
          onChange={onSourceTypeChange}
        />
        {renderMergeSourceSelector()}

      </div>
      {renderContent()}
    </div>
  );
};
