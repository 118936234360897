import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { DeepPartial, Required } from 'utility-types';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Dialog, DialogBody, DialogFooter, FormGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import RHFTextInput from 'components/RHFInputs/TextInput';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import { SetupBranch, useUpdateBranchMutation, useSetupBranchesByRootIdQuery } from 'graphql/generated/graphql';
import { IconNames } from '@blueprintjs/icons';

interface Props {
  isOpen: boolean,
  onBranchRenamed?: (newBranchName: string) => void,
  onClose: () => void,
  sourceBranch: Required<DeepPartial<SetupBranch>, 'id' | 'name' | 'root'>,
}

export default (props: Props) => {
  const defaultValues = {
    name: props.sourceBranch.name,
  };

  const branchNameInputRef = useRef<HTMLInputElement>(null);
  const [rootBranchNames, setRootBranchNames] = useState<string[]>([]);
  const { control, handleSubmit, formState: { errors }, reset } = useForm<{ name: string }>({ defaultValues });
  const darkMode = useSelector(selectDarkMode);

  const { data: rootBranches } = useSetupBranchesByRootIdQuery({
    variables: { rootId: props.sourceBranch.root.id ?? 0 },
  });
  const [renameBranch] = useUpdateBranchMutation();

  useEffect(() => {
    reset({ name: props.sourceBranch.name });
  }, [props.sourceBranch.name, reset]);

  useEffect(() => {
    if (rootBranches?.branches) setRootBranchNames(rootBranches.branches.map(b => b.name));
  }, [rootBranches]);

  const onSubmit = (formValues: { name: string }) => {
    renameBranch({
      variables: {
        input: {
          id: props.sourceBranch.id,
          name: formValues.name,
          root_id: props.sourceBranch.root?.id ?? 0,
          head_id: props.sourceBranch.head?.id ?? 0,
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: `Renamed branch to ${formValues.name}`,
        });
        props.onBranchRenamed?.(formValues.name);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to rename branch: ${e.message}`,
        });
      },
    });
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      icon={IconNames.EDIT}
      isCloseButtonShown
      isOpen={props.isOpen}
      onClose={() => {
        reset(defaultValues);
        props.onClose();
      }}
      onOpened={() => branchNameInputRef.current?.select()}
      title={`Renaming "${props.sourceBranch.name}" branch`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="name" />}
          >
            <RHFTextInput
              controllerProps={{
                // There's a typing issue with RHF's `Control` type not inheriting the proper
                // type of the form so it's being typed as `any`
                control: control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                name: 'name',
                rules: {
                  required: 'Branch name is required',
                  pattern: {
                    value: /^(?!.*main).*$/,
                    message: 'Branch name cannot be "main"',
                  },
                  validate: {
                    dupeName: v => !rootBranchNames.includes(v) || 'Cannot use duplicate branch name',
                  },
                },
              }}
              inputProps={{
                intent: errors.name && Intent.DANGER,
                placeholder: 'new branch name',
                inputRef: branchNameInputRef,
              }}
            />
          </FormGroup>
        </DialogBody>
        <DialogFooter
          actions={(
            <>
              <Button
                onClick={props.onClose}
                text="Cancel"
              />
              <Button
                intent="primary"
                text="Save"
                type="submit"
              />
            </>
          )}
        />
      </form>
    </Dialog>
  );
};
