import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BlockerFunction,
  useBlocker,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';
import {
  chain,
  cloneDeep,
  filter,
  find,
  findIndex,
  has,
  includes,
  isEqual,
  map,
  partition,
  set,
  snakeCase,
} from 'lodash';
import {
  Button,
  Callout,
  ControlGroup, Dialog, DialogBody, DialogFooter,
  FormGroup, InputGroup,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import {
  IconNames,
} from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import classNames from 'classnames';

import CreateBranchModal from 'components/CreateBranchModal';
import RenameBranchModal from 'components/RenameBranchModal';
import Select from 'components/Select';
import SetupHistoryModal from 'components/SetupHistoryModal';
import SUITForm, { SetupFieldHandler, SetupFieldBlurHandler } from 'components/SUITForm';
import SUITNavigationMenu, { TemplateItemNodeInfo } from 'components/SUITNavigationMenu';
import {
  Part,
  Run,
  SetupBranch,
  SetupBranchesByRootIdDocument,
  SetupByBranchIdDocument,
  SetupByIdDocument,
  SetupField,
  SetupFieldType,
  SUIT,
  useCloneSetupMutation,
  useCommitSetupMutation,
  useRunsByRootIdQuery,
  useSetupBranchByIdQuery,
  useSetupBranchesByRootIdQuery,
  useSetupByBranchIdLazyQuery,
  useSetupByBranchIdQuery,
  useSetupFieldsQuery,
  useSUITByIdQuery,
  useSUITsQuery,
} from 'graphql/generated/graphql';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import { SetupToSetupInput } from 'helpers/converter';
import {
  deleteProp,
  excludeSetupMeta,
  setSetupDataValue,
  updateTireSetDependencies,
} from 'helpers/setup';
import AppToaster from 'helpers/toaster';
import { selectActiveSUITId, SUITSlice } from 'reducers/suit';
import { selectDarkMode } from 'reducers/ui';
import { GQLSetup, SelectItem, SUITNoTemplate } from 'types';
import TitleBar from './TitleBar';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';
import { getTeamPermissions, hasPermission } from '../../helpers/permissions';
import {
  organizationSelectItems,
  OrganizationsList,
  PermissionName,
  seriesItems,
  teamSelectItems,
} from '../../constants';

const SetupIndexPage = () => {
  /**
   * Misc. hooks
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const branchId = Number(params.branchId);

  /**
   * Selectors
   */
  const darkMode = useSelector(selectDarkMode);
  const activeSUITId = useSelector(selectActiveSUITId);

  /**
   * Local state
   */
  const [setup, setSetup] = useState<GQLSetup>();
  const [cleanSetup, setCleanSetup] = useState<GQLSetup>();
  const [branch, setBranch] = useState<SetupBranch>();
  const [availableRuns, setAvailableRuns] = useState<Run[]>([]);
  const [availableBranches, setAvailableBranches] = useState<SetupBranch[]>([]);
  const [setupFields, setSetupFields] = useState<SetupField[]>([]);
  const [suitItems, setSUITItems] = useState<SelectItem<SUITNoTemplate>[]>([]);
  const [createBranchModalOpen, setCreateBranchModalOpen] = useState(false);
  const [renameBranchModalOpen, setRenameBranchModalOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [activeSUIT, setActiveSUIT] = useState<SUIT>();
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const cloneName = useRef<HTMLInputElement>(null);
  const [cloneSource, setCloneSource] = useState<GQLSetup>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let { getUser: { teams, permissions, organizations } } = useRouteLoaderData('root') as any;

  permissions = getTeamPermissions(
    setup?.team_id,
    teams,
    organizations,
    permissions,
    OrganizationsList.TRD
  );

  useDocumentTitle(
    setup && setup.name ? `${setup.name}` : 'Apex Setup'
  );

  /**
   * Immediate queries
   */
  useSetupFieldsQuery({
    onCompleted: data => setSetupFields(data.setupFields.rows as SetupField[]),
  });
  useSUITsQuery({
    onCompleted: data => {
      const items: SelectItem<SUITNoTemplate>[] = data.suits.rows.map(s => ({ label: s.name, value: s }));
      setSUITItems(items);
    },
  });
  const [cloneSetup] = useCloneSetupMutation();
  // Fetch setup data for the branchId
  const { refetch: refetchSetup } = useSetupByBranchIdQuery({
    variables: { branchId },
    onCompleted: data => {
      setSetup(data.setup as GQLSetup);
      setCleanSetup(data.setup as GQLSetup);
      setCloneSource(data.setup as GQLSetup);
    },
    fetchPolicy: 'cache-and-network',
  });

  // Fetch branch data for the branchId
  const { refetch: refetchBranch } = useSetupBranchByIdQuery({
    variables: { id: branchId },
    onCompleted: data => {
      setBranch(data.branch as SetupBranch);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { refetch: refetchBranches } = useSetupBranchesByRootIdQuery({
    variables: { rootId: branch?.root.id ?? -1 },
    skip: !branch,
    onCompleted: data => {
      const thisBranch = data.branches.find(b => b.id === branchId);
      if (thisBranch) setBranch(thisBranch as SetupBranch);
      setAvailableBranches(data.branches as SetupBranch[]);
    },
    fetchPolicy: 'cache-and-network',
  });

  useRunsByRootIdQuery({
    variables: { rootId: branch?.root.id ?? -1 },
    skip: !branch,
    onCompleted: data => {
      setAvailableRuns(data.runs as Run[]);
    },
  });

  useSUITByIdQuery({
    variables: { id: activeSUITId ?? -1 },
    skip: !activeSUITId,
    onCompleted: data => setActiveSUIT(data.suit as SUIT),
  });

  const handleClone = () => {
    if (!cloneSource) return;
    cloneSetup({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
        orgName: cloneSource.organization_name,
        orgId: cloneSource.organization_id,
        teamName: cloneSource.team_name,
        teamId: cloneSource.team_id,
        series: cloneSource.series,
      },
      onCompleted: (result) => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned setup',
        });
        // Load newly cloned setup.
        if (result) {
          const newBranchId = result.cloneSetup.branch.id;

          // Update the URL with the new branchId
          navigate(`/setup/${newBranchId}`);

          // Refetch the queries with the new branchId
          refetchSetup({ branchId: newBranchId });
          refetchBranch({ id: newBranchId });
        }
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone setup: ${e.message}`,
        });
      },
      refetchQueries: ['SetupSummary'],
    });
    setCloneModalOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectItem = (item: any, name: string) => {
    const clone = cloneDeep(cloneSource);

    if (clone) {
      if (name === 'series') {
        clone.series = item.value;
      }

      if (name === 'org') {
        clone.organization_name = item.value;
        clone.organization_id = item.id;

        // Reset team selections if org changes.
        const findTeam = find(teams, team => team.name === cloneSource?.team_name);
        if (findTeam && findTeam.organization.name !== item.value) {
          clone.team_name = null;
          clone.team_id = null;
        }
      }

      if (name === 'team') {
        clone.team_name = item.value;
        clone.team_id = item.id;
      }

      setCloneSource(clone);
    }
  };

  /**
   * Lazy queries
   */
  const [commitSetup] = useCommitSetupMutation();
  const [getSetupByBranchId] = useSetupByBranchIdLazyQuery();

  const branchItems = useMemo(() => {
    const items = [];
    const runBranchIds: number[] = map(availableRuns, (ar) => ar.branch.id);

    const mainBranch = find(availableBranches, (ab) => (ab.name === 'main'));
    if (mainBranch) items.push({ label: mainBranch.name, value: mainBranch });

    const partitionedBranches = partition(availableBranches, (ab) => (!includes(runBranchIds, ab.id)));

    const sortedBranches = chain(partitionedBranches[0])
      .filter((b) => b.name !== 'main')
      .sortBy('created_at')
      .map((b) => ({ label: b.name, value: b }))
      .value();

    items.push(...sortedBranches);

    const sortedRuns = chain(partitionedBranches[1])
      .sortBy('created_at')
      .map((b) => ({
        label: b.name,
        value: b,
        labelElement: (<span className={styles.runLabel}>{b.name}</span>),
      }))
      .value();

    items.push(...sortedRuns);

    return items;
  }, [availableBranches, availableRuns]);

  /**
   * Effects
   */
  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleClone();
      }
    };

    if (isCloneModalOpen) {
      document.addEventListener('keydown', onEnter);
    }

    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [isCloneModalOpen]);

  useEffect(() => {
    const newBranch = availableBranches?.find(b => b.id === branchId);
    if (!newBranch) return;

    setBranch(newBranch);
    getSetupByBranchId({
      variables: { branchId: newBranch.id },
      onCompleted: data => {
        setSetup(data.setup as GQLSetup);
        setCleanSetup(data.setup as GQLSetup);
        setCloneSource(data.setup as GQLSetup);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error refreshing setup: ${e.message}`,
        });
      },
    });
  }, [branchId]);

  useEffect(() => {
    if (!setup || !cleanSetup) return;

    const isDirtyCheck = !isEqual(excludeSetupMeta(cleanSetup), excludeSetupMeta(setup));
    const prevIsDirty = isDirty;
    setIsDirty(isDirtyCheck);
    if (isDirtyCheck !== prevIsDirty) {
      if (isDirtyCheck) addWarningListener();
      else removeWarningListener();
    }
  }, [setup, cleanSetup]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  useBlocker(blockerFunc);

  const onBranchChange = async (item: SelectItem<SetupBranch>) => {
    const newBranch = item.value;
    navigate(`/setup/${newBranch.id}`);
  };

  const onSUITChange = (item: SelectItem<SUITNoTemplate>) => {
    dispatch(SUITSlice.actions.setActiveSUITId(item.value.id));
  };

  const onSetupMetaChange = (updates: { [key: string]: string | number }) => {
    if (!setup) return;

    const newSetup = cloneDeep(setup);

    Object.entries(updates).forEach(([key, value]) => {
      if (key === 'year') {
        if (Number(setup.year) !== Number(value)) {
          set(newSetup, key, Number(value));
          set(newSetup, 'event', null);
        }
      } else if (key === 'series') {
        if (setup.series !== value) {
          set(newSetup, key, value);
          set(newSetup, 'event', null);
        }
      } else {
        set(newSetup, key, value);
      }
    });

    setSetup(newSetup);
    setCloneSource(newSetup);
  };

  const onSetupBlurDataChange: SetupFieldBlurHandler = (field, value) => {
    const { path } = field;
    if (!setup) return;

    const newSetup = cloneDeep(setup);
    const setupPath = `data.${path}`;

    if (value === '' && !has(cleanSetup, setupPath)) {
      deleteProp(newSetup, setupPath.split('.'));
    } else {
      setSetupDataValue(newSetup.data, path, value, field.type);
    }

    setSetup(newSetup);
    setCloneSource(newSetup);
  };

  const onSetupDataChange: SetupFieldHandler = (field, value) => {
    const { path, type } = field;
    if (!setup) return;

    const newSetup = cloneDeep(setup);
    const setupPath = `data.${path}`;

    switch (type) {
      case SetupFieldType.PART:
        if (field.name === 'tire_set') {
          updateTireSetDependencies(newSetup.data, newSetup.spec, value as Part, field);
        } else {
          set(newSetup, setupPath, value);
        }
        break;
      case SetupFieldType.EXPRESSION: {
        const numericValue = Number(value);
        set(newSetup, setupPath, numericValue);
        break;
      }
      default: {
        if (value === '' && !has(cleanSetup, setupPath)) {
          // If the original setup *did not have at all (not defined vs undefined)*,
          // remove it again so dirty checking works properly
          deleteProp(newSetup, setupPath.split('.'));
        } else {
          setSetupDataValue(newSetup.data, path, value, field.type);
        }
      }
    }
    setSetup(newSetup);
    setCloneSource(newSetup);
  };

  const onSave = async () => {
    if (!setup || !cleanSetup) return;

    // return if no diff
    if (isEqual(excludeSetupMeta(cleanSetup), excludeSetupMeta(setup))) {
      return;
    }

    if (!branch) return;

    await commitSetup({
      variables: {
        branchId: branch.id,
        setup: SetupToSetupInput(setup),
      },
      onCompleted: () => {
        setCleanSetup(setup);
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully saved setup',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error saving setup: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }, { variables }) => {
        if (mutationData?.commitedSetup) {
          cache.writeQuery({
            query: SetupByBranchIdDocument,
            variables: { branchId: variables?.branchId },
            data: {
              setup: { ...mutationData.commitedSetup },
            },
          });

          cache.writeQuery({
            query: SetupByIdDocument,
            variables: { id: mutationData.commitedSetup.id },
            data: {
              setup: mutationData.commitedSetup,
            },
          });

          cache.updateQuery({
            query: SetupBranchesByRootIdDocument,
            variables: { rootId: branch.root.id },
          }, queryData => {
            if (!queryData) return undefined;
            return {
              branches: queryData.branches.map((b: SetupBranch) => {
                if (b.id !== branchId) return b;
                return {
                  ...b,
                  head: {
                    ...b.head,
                    id: mutationData.commitedSetup?.id,
                  },
                };
              }),
            };
          });
        }
      },
    });
  };

  const onBranch = (newBranch: SetupBranch) => {
    setAvailableBranches([...availableBranches, newBranch]);
    setCreateBranchModalOpen(false);
    navigate(`/setup/${newBranch.id}`);
  };

  const onBranchRenamed = (newBranchName: string) => {
    if (branch && newBranchName) {
      const updatedBranch = { ...branch, name: newBranchName };
      setBranch(updatedBranch);
      refetchBranches();
    }
    setRenameBranchModalOpen(false);
  };

  const onSUITNavItemClick = (path: TemplateItemNodeInfo[]) => {
    const id = path.map(i => snakeCase(i.nodeData?.name)).join('.');
    const sectionElement = document.getElementById(id);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const filterSuitNav = (template: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const newTemplate = cloneDeep(template);
    if (hasPermission(PermissionName.SETUP_WRITE, permissions) || hasPermission(PermissionName.SETUP_READ, permissions)) return newTemplate;
    const carIndex = findIndex(template.items, (i: any) => i.name.toLowerCase() === 'car'); // eslint-disable-line @typescript-eslint/no-explicit-any
    if (carIndex !== -1) {
      newTemplate.items[carIndex].items = filter(newTemplate.items[carIndex].items, (item) => {
        return (hasPermission(`setup_${item.name.toLowerCase()}_read`, permissions) || hasPermission(`setup_${item.name.toLowerCase()}_write`, permissions));
      });
    }
    return newTemplate;
  };

  if (!setup) return null;

  const isDesignerMode = false;
  const containerClasses = classNames(styles.mainContainer, { [styles.dark]: darkMode });
  return (
    <>
      <div className={containerClasses}>
        <TitleBar setup={setup} onChange={onSetupMetaChange} branch={branch?.name}>
          <div className={styles.controlsContainer}>
            <FormGroup
              className={styles.suitSelectContainer}
              inline
            >
              <Select
                value={suitItems.find(si => si.value.id === activeSUITId)}
                items={suitItems}
                noSelectionText="Select SUIT"
                onChange={onSUITChange}
                popoverProps={{ className: styles.setupFieldSelectPopover }}
              />
            </FormGroup>
          </div>
        </TitleBar>
        {!activeSUIT && (
          <div>
            <Callout
              intent={Intent.WARNING}
              title="No active SUIT selected!"
            />
          </div>
        )}
        {(branch && activeSUIT) && (
          <div className={styles.bodyContainer}>
            <div className={styles.nav}>
              <Button
                icon="git-commit"
                intent={Intent.PRIMARY}
                onClick={onSave}
                text="Save"
                fill
                disabled={!isDirty}
              />
              <div>
                <ControlGroup className={styles.branchSelectContainer}>
                  <Select
                    fill
                    items={branchItems}
                    noSelectionText="Select Branch"
                    ignoreSelection
                    onChange={onBranchChange}
                    popoverProps={{ className: styles.branchSelectPopover }}
                    value={branchItems.find(i => i.value.name === branch.name)}
                  />
                  <Popover2
                    position={Position.BOTTOM}
                    content={(
                      <Menu>
                        <MenuItem
                          icon="git-branch"
                          onClick={() => setCreateBranchModalOpen(!createBranchModalOpen)}
                          text="Branch"
                        />
                        <MenuItem
                          icon="git-merge"
                          onClick={() => navigate('merge')}
                          text="Merge"
                        />
                        <MenuDivider />
                        {branch.name !== 'main' && (
                          <MenuItem
                            icon={IconNames.EDIT}
                            onClick={() => setRenameBranchModalOpen(!renameBranchModalOpen)}
                            text="Rename Branch"
                          />
                        )}
                        <MenuItem
                          icon={IconNames.FORK}
                          onClick={() => {
                            setCloneModalOpen(true);
                            cloneName.current?.focus();
                          }}
                          text="Clone Setup"
                        />
                        <MenuDivider />
                        <MenuItem
                          icon="git-repo"
                          onClick={() => setHistoryModalOpen(true)}
                          text="History"
                        />
                      </Menu>
                    )}
                  >
                    <Button icon="chevron-down" />
                  </Popover2>
                </ControlGroup>
              </div>
              <div className={styles.sideNavContainer}>
                <SUITNavigationMenu
                  template={filterSuitNav(activeSUIT.template)}
                  onClick={onSUITNavItemClick}
                />
              </div>
            </div>
            <div className={styles.setupContainer}>
              <SUITForm
                template={activeSUIT.template}
                setupFields={setupFields}
                initialSetup={setup.data}
                team={setup.team_id || undefined}
                organization={setup.organization_id || undefined}
                onChange={onSetupDataChange}
                onBlur={onSetupBlurDataChange}
                isDesignerMode={isDesignerMode}
                partsInSetup={(setup.parts || []) as Part[]}
              />
            </div>
            <CreateBranchModal
              isOpen={createBranchModalOpen}
              onBranchCreated={onBranch}
              onClose={() => setCreateBranchModalOpen(false)}
              sourceBranch={branch}
            />
            <RenameBranchModal
              isOpen={renameBranchModalOpen}
              onBranchRenamed={onBranchRenamed}
              onClose={() => setRenameBranchModalOpen(false)}
              sourceBranch={branch}
            />
          </div>
        )}
      </div>
      {setup.root && (
        <SetupHistoryModal
          isOpen={historyModalOpen}
          onClose={() => setHistoryModalOpen(false)}
          rootId={setup.root!.id} // eslint-disable-line
        />
      )}
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <div>
            <div style={{ paddingBottom: '5px' }}>Organization</div>
            <Select
              initialItem={{ label: cloneSource?.organization_name || 'None', value: cloneSource?.organization_name || null }}
              items={organizationSelectItems(organizations)}
              noSelectionText="Organization"
              onChange={item => setSelectItem(item, 'org')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Team</div>
            <Select
              initialItem={{ label: cloneSource?.team_name || 'None', value: cloneSource?.team_name || null }}
              items={teamSelectItems(teams, cloneSource?.organization_name)}
              noSelectionText="Team"
              onChange={item => setSelectItem(item, 'team')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Series</div>
            <Select
              initialItem={seriesItems.find(i => cloneSource?.series === i.value)}
              items={seriesItems}
              noSelectionText="Series"
              onChange={item => setSelectItem(item, 'series')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Name</div>
            <InputGroup
              placeholder="Enter new setup name"
              inputRef={cloneName}
              defaultValue={`${cloneSource?.name} CLONE`}
            />
          </div>
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => handleClone()}
            />
          )}
        />
      </Dialog>
    </>
  );
};

export default SetupIndexPage;
