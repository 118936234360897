import { ReactNode, useEffect, useMemo, useState, FocusEvent } from 'react';
import {
  Card,
  InputGroup,
  H4,
  NonIdealState,
  Checkbox,
  Alignment,
  ControlGroup,
  Label,
  Button,
  Intent,
} from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import _, { startCase, cloneDeep, includes, get } from 'lodash';
import { useRouteLoaderData } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloError } from '@apollo/client';

import Accordion from 'components/Accordion';
import SelectPartModel from 'components/SelectorModal/part';
import {
  Setup,
  SetupFieldType,
  SetupField,
  Part,
  useSUITFormPartsLazyQuery,
} from 'graphql/generated/graphql';
import { suitPathToKey } from 'helpers/suit';
import getSetupFieldProps from 'helpers/setupField';
import {
  SetupUITemplate,
  SetupUITemplateContainerItem,
  SetupUITemplateFieldItem,
  SetupUITemplateItem,
  SetupUITemplateItemType,
} from 'types';
import { hasPermission, getOrgIds, getTeamPermissions } from '../../helpers/permissions';
import { SetupFieldProps } from '../../helpers/setupField';
import { OrganizationsList, PermissionName } from '../../constants';
import { getSetupDataValue, setSetupDataValue, updateTireSetDependencies } from 'helpers/setup';
import AppToaster from 'helpers/toaster';
import styles from './index.module.css';

export interface TemplateFieldMeta {
  inputClassName?: string;
  rightElement?: ReactNode;
  visible?: boolean;
  fieldType?: SetupFieldType;
}
export interface TemplateContainerMeta {
  open?: boolean;
  visible?: boolean;
}
export interface TemplateMeta {
  containers: {
    [path: string]: TemplateContainerMeta;
  };
  fields: {
    [path: string]: TemplateFieldMeta;
  };
}

export interface Selections {
  [path: string]: boolean;
}

export type SetupChangeHandler = (setup: Setup) => void;

interface Props {
  isBaseline?: boolean;
  isMergeTarget?: boolean;
  enableSelections?: boolean;
  onContainerCollapse?: (id: string | undefined, newOpenState: boolean) => void;
  onSelectionsChange?: (selections: Selections) => void;
  setup: Setup;
  branchName?: string;
  setupFields: SetupField[];
  template: SetupUITemplate;
  templateMeta?: TemplateMeta;
  onSetupChange?: SetupChangeHandler;
  color?: string,
  customHeader?: ReactNode;
}

export default (props: Props) => {
  const [selections, setSelections] = useState<Selections>({});
  const [isPartSelectorOpen, setIsPartSelectorOpen] = useState(false);
  const [selectedPartId, setSelectedPartId] = useState<number>();
  const [selectorPartSF, setSelectorPartSF] = useState<SetupField>();
  const [parts, setParts] = useState<Part[]>(props.setup?.parts || []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let { getUser: { teams, permissions, organizations } } = useRouteLoaderData('root') as any;

  permissions = getTeamPermissions(
    props.setup.team_id,
    teams,
    organizations,
    permissions,
    OrganizationsList.TRD
  );

  const [getParts] = useSUITFormPartsLazyQuery();

  const allFieldsSelected = useMemo(() => {
    const visibleFields = Object.keys(props.templateMeta?.fields ?? []).reduce((acc, path) => {
      const field = props.templateMeta?.fields[path];
      if (field?.visible) acc.push(path);
      return acc;
    }, [] as string[]);
    return !visibleFields.some(f => !selections[f]);
  }, [props.templateMeta, selections]);

  const someFieldsSelected = useMemo(() => {
    return Object.values(selections).includes(true) && !allFieldsSelected;
  }, [props.template, allFieldsSelected, selections]);

  // Unselects fields which have been toggled to not visible
  useEffect(() => {
    const newSelections = { ...selections };
    Object.entries(selections).forEach(([path]) => {
      if (props.templateMeta?.fields[path]?.visible === false) {
        newSelections[path] = false;
      }
    });
    setSelections(newSelections);
  }, [props.templateMeta]);

  useEffect(() => {
    props.onSelectionsChange?.(selections);
  }, [selections]);

  const handleToggleSelection = (path: string) => {
    const newSelections = {
      ...selections,
      [path]: !selections[path],
    };
    setSelections(newSelections);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => e.target.select();

  const selectAllFields = (select: boolean) => {
    const visibleFields = Object.keys(props.templateMeta?.fields ?? []).reduce((acc, path) => {
      const field = props.templateMeta?.fields[path];
      if (field?.visible) acc.push(path);
      return acc;
    }, [] as string[]);

    const newSelections: Selections = {};
    visibleFields.forEach(f => { newSelections[f] = select; });
    setSelections(newSelections);
  };

  const onSetupDataChange = (fieldPath: string, value: boolean | number | string, setupFieldType: SetupFieldType) => {
    if (!props.setup) return;

    const newSetup = cloneDeep(props.setup);
    setSetupDataValue(newSetup.data, fieldPath, value, setupFieldType);

    props.onSetupChange?.(newSetup);
  };

  const handleTireSetSelection = (tireSetPart: Part, setupField: SetupField) => {
    // Extract tire IDs from the tire set
    const ids: number[] = [];
    const lfSourceId = get(tireSetPart, 'data.lf._source', null);
    const rfSourceId = get(tireSetPart, 'data.rf._source', null);
    const lrSourceId = get(tireSetPart, 'data.lr._source', null);
    const rrSourceId = get(tireSetPart, 'data.rr._source', null);

    // Add non-null values to the array
    [lfSourceId, rfSourceId, lrSourceId, rrSourceId].forEach(id => {
      if (id !== null) {
        ids.push(Number(id));
      }
    });

    const newSetup = cloneDeep(props.setup);
    updateTireSetDependencies(newSetup.data, newSetup.spec, tireSetPart as Part, setupField);
    props.onSetupChange?.(newSetup);

    if (ids.length > 0) {
      getParts({
        variables: {
          input: {
            ids,
          },
        },
        onCompleted: (data) => {
          if (data?.parts?.rows) {
            // Add the individual tires to the parts state
            setParts(prevParts => {
              const newParts = [...prevParts];

              // Add each tire part if it doesn't already exist in the array
              data.parts.rows.forEach((tirePart) => {
                if (!newParts.some(part => part.id === tirePart.id)) {
                  newParts.push(tirePart as Part);
                }
              });

              return newParts;
            });
          }
        },
        onError: (error: ApolloError) => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error fetching tire parts: ${error.message}`,
          });
        },
      });
    }
  };

  const onPartSelection = (selectedPart: Part, currentSF: SetupField) => {
    setIsPartSelectorOpen(false);

    if (selectedPart) {
      setParts(prevParts => {
        if (!prevParts.some(part => part.id === selectedPart.id)) {
          return [...prevParts, selectedPart];
        }
        return prevParts;
      });

      if (currentSF.name === 'tire_set') {
        handleTireSetSelection(selectedPart, currentSF);
      } else {
        onSetupDataChange(currentSF.path, selectedPart.id, currentSF.type);
      }
    }
  };

  const handleOpenPartSelectorClick = (field: SetupField, partId?: number) => {
    setSelectorPartSF(field);
    setIsPartSelectorOpen(true);
    setSelectedPartId(partId);
  };

  const headerStyle = {
    color: props.color || '#9E9E9E',
  };

  const renderTemplateFieldItem = (path: SetupUITemplateItem[], readOnly: boolean) => {
    const item = path[path.length - 1] as SetupUITemplateFieldItem;
    const setupFieldProps = getSetupFieldProps(item.setup_field, props.setupFields);
    return _.map(setupFieldProps, (setupFieldProp: SetupFieldProps) => {
      const fieldMeta = props.templateMeta?.fields[setupFieldProp.path];

      // Hide expression fields when comparing setups. May change down the road.
      if (setupFieldProp.setupField.type === 'EXPRESSION') return null;

      if (fieldMeta && fieldMeta.visible === false) return null;

      let fieldValue;
      let field = (
        <InputGroup
          className={classNames(styles.fieldValue, styles.notSet, fieldMeta?.inputClassName)}
          disabled
          fill
          value="Not Set"
          inputClassName={styles.customDisabled}
        />
      );

      if (setupFieldProp.type === SetupFieldType.COLLECTION) {
        fieldValue = getSetupDataValue(props.setup.data, `${setupFieldProp.path}`, setupFieldProp.type);
        if (fieldValue) {
          const numberOfTopLevelObjects = Object.keys(fieldValue).length;
          const tempCollectionValue = `${numberOfTopLevelObjects} Field${numberOfTopLevelObjects > 1 ? 's' : ''} in Collection`;

          field = (
            <InputGroup
              className={classNames(styles.fieldValue, styles.notSet, fieldMeta?.inputClassName)}
              disabled
              fill
              value={tempCollectionValue}
              inputClassName={styles.customDisabled}
            />
          );
        }
      } else if (setupFieldProp.type === SetupFieldType.PART) {
        const partId = getSetupDataValue(props.setup.data, `${setupFieldProp.path}`, setupFieldProp.type);
        const part = _.find(parts, (o: Part) => o.id === partId);

        let partDescription = '';

        if (part) {
          partDescription += `${get(part, 'description', '')}`;
          const partNumber = get(part, 'part_number', '');
          const partSerialNumber = get(part, 'serial_number', '');
          if (partNumber) {
            if (partDescription) partDescription += ' / ';
            partDescription += `PN ${partNumber}`;
          }
          if (partSerialNumber) {
            if (partDescription) partDescription += ' / ';
            partDescription += `SN ${partSerialNumber}`;
          }
        } else {
          partDescription = 'Select Part';
        }

        if (!props.isBaseline && part) {
          field = (
            <Tooltip2
              content={partDescription}
              className={styles.fieldValue}
            >
              <InputGroup
                className={classNames(styles.fieldValue, styles.notSet, fieldMeta?.inputClassName)}
                disabled
                fill
                value={partDescription}
                inputClassName={styles.customDisabled}
              />
            </Tooltip2>
          );
        } else if (props.isBaseline) {
          field = (
            <div className={styles.partSelector}>
              <Tooltip2 content={partDescription}>
                <InputGroup
                  fill
                  value={partDescription}
                  onFocus={handleFocus}
                  readOnly={readOnly || props.isMergeTarget}
                  inputClassName={(readOnly || props.isMergeTarget) ? styles.customDisabled : undefined}
                />
              </Tooltip2>
              <Button
                onClick={() => handleOpenPartSelectorClick(setupFieldProp.setupField, (part ? part.id : -1))}
              >
                <FontAwesomeIcon
                  icon="ellipsis-h"
                />
                <i
                  style={{ pointerEvents: 'none' }}
                  className="fa fa-fw fa-ellipsis-h"
                />
              </Button>
            </div>
          );
        }
      } else if (setupFieldProp.type === SetupFieldType.BOOLEAN) {
        fieldValue = get(props.setup, `data.${setupFieldProp.path}`);
        const checkboxValue = fieldValue || false;
        field = (
          <Checkbox
            checked={checkboxValue}
            className={classNames(styles.fieldCompareCheckbox, fieldMeta?.inputClassName)}
            disabled={!props.isBaseline || readOnly || props.isMergeTarget}
            onChange={() => onSetupDataChange(setupFieldProp.path, !checkboxValue, setupFieldProp.type)}
          />
        );
      } else {
        fieldValue = getSetupDataValue(props.setup.data, `${setupFieldProp.path}`, setupFieldProp.type);
        field = (
          <InputGroup
            className={classNames(styles.fieldValue, fieldMeta?.inputClassName)}
            disabled={!props.isBaseline || readOnly || props.isMergeTarget}
            fill
            value={fieldValue ?? ''}
            onChange={e => onSetupDataChange(setupFieldProp.path, e.target.value, setupFieldProp.type)}
            onFocus={handleFocus}
            inputClassName={(!props.isBaseline || readOnly || props.isMergeTarget) ? styles.customDisabled : undefined}
          />
        );
      }

      return (
        <ControlGroup className={styles.fieldInputGroup}>
          {props.isBaseline && (
            <Label
              className={styles.fieldLabel}
              title={setupFieldProp.label}
            >
              {setupFieldProp.label}
            </Label>
          )}
          {field}
          {props.enableSelections && (
            <Checkbox
              checked={selections[setupFieldProp.path] ?? false}
              className={styles.fieldCheckbox}
              onChange={() => handleToggleSelection(setupFieldProp.path)}
            />
          )}
          {fieldMeta?.rightElement ?? null}
        </ControlGroup>
      );
    });
  };

  const renderTemplateItem = (path: SetupUITemplateItem[]) => {
    const item = path[path.length - 1];
    if (item.type === SetupUITemplateItemType.CONTAINER) {
      // Filter template by permissions
      if (!hasPermission(PermissionName.SETUP_WRITE, permissions) && !hasPermission(PermissionName.SETUP_READ, permissions)) {
        if (item.name.toLowerCase() !== 'car') {
          if (!hasPermission(`setup_${item.name.toLowerCase()}_read`, permissions) && !hasPermission(`setup_${item.name.toLowerCase()}_write`, permissions)) return null;
        }
      }
      const key = suitPathToKey(path as SetupUITemplateContainerItem[]);

      const itemMeta = props.templateMeta?.containers[key];
      if (itemMeta?.visible === false) return null;

      const innerItems = _.compact(item.items.map(innerItem => renderTemplateItem([...path, innerItem])).flat());
      if (innerItems.length === 0) return null;

      return (
        <Accordion
          className={styles.itemContainer}
          key={`${props.setup.id}-${key}`}
          id={key}
          isOpen={itemMeta?.open !== false}
          title={item.label ?? startCase(item.name)}
          willToggleCollapse={props.onContainerCollapse}
        >
          <div className={styles.sectionContainer}>
            {innerItems}
          </div>
        </Accordion>
      );
    }

    const parentContainer = path[path.length - 2] as SetupUITemplateContainerItem;

    let readOnly = false;

    // Set read only permissions
    if (hasPermission(PermissionName.SETUP_READ, permissions)) {
      readOnly = true;
    } else {
      readOnly = hasPermission(`setup_${parentContainer.name.toLowerCase()}_read`, permissions);
    }

    // Set to Read Only if the setup is owend by TRD and the user is not in the TRD org
    if (props.setup.organization_id === OrganizationsList.TRD
      && !includes(getOrgIds(organizations), OrganizationsList.TRD)) {
      readOnly = true;
    }

    return renderTemplateFieldItem(path, readOnly);
  };

  const cardClasses = classNames(styles.setupCard, {
    [styles.compareSetup]: !props.isBaseline,
  });
  const items = _.compact(props.template.items.map(i => renderTemplateItem([i])).flat());

  const compareHeader = props.branchName ? `${props.setup.name} - ${props.branchName}` : props.setup.name;

  return (
    <div className={styles.container}>
      {props.enableSelections && (
        <Checkbox
          alignIndicator={Alignment.RIGHT}
          checked={allFieldsSelected}
          className={styles.selectAllCheckbox}
          indeterminate={!allFieldsSelected && someFieldsSelected}
          label="Select All"
          onChange={() => selectAllFields(!allFieldsSelected)}
        />
      )}
      <Card className={cardClasses}>
        {props.customHeader || (
          <Tooltip2 content={compareHeader} className={styles.headerTooltip}>
            <H4 style={headerStyle} className={styles.setupLabel}>
              {compareHeader}
            </H4>
          </Tooltip2>
        )}
        {items.length > 0 && items}
        {items.length === 0 && (
          <NonIdealState
            className={styles.nonIdealState}
            icon="search"
            title="Nothing to display"
            description="Try changing the settings in the sidebar"
          />
        )}
      </Card>
      {(props.isBaseline && !props.isMergeTarget && selectorPartSF) && (
        <SelectPartModel
          isOpen={isPartSelectorOpen}
          onClose={() => setIsPartSelectorOpen(false)}
          onSelected={onPartSelection}
          setupField={selectorPartSF}
          partId={selectedPartId}
        />
      )}
    </div>
  );
};
