import { H3, Button, Intent } from '@blueprintjs/core';
import { useState } from 'react';
import styles from './index.module.css';
import SimTable from '../../components/SimTable';
import AppToaster from 'helpers/toaster';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { CreateSimInput, useCreateSimMutation } from 'graphql/generated/graphql';
import AddSimDialog from './addSimDialog';
import { find, get } from 'lodash';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export default () => {
  useDocumentTitle('Sims');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;
  const [isAddSimOpen, setAddSimOpen] = useState(false);
  const [createSim] = useCreateSimMutation();
  const navigate = useNavigate();

  const onAddSim = (input: CreateSimInput) => {
    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);
    createSim({
      variables: {
        input: {
          ...input,
          organization_id: get(findOrg, 'id', null),
          team_id: get(findTeam, 'id', null),
        },
      },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully created sim',
        });
        navigate(`/sims/${data.createSim?.id}`);
      },
      refetchQueries: ['Sims'],
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to create sim: ${e.message}`,
        });
      },
    });
  };

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Sim Summary</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setAddSimOpen(true)}
          text="Create Sim"
        />
      </div>
      <SimTable />
      <AddSimDialog
        isOpen={isAddSimOpen}
        onClose={() => setAddSimOpen(false)}
        onOk={onAddSim}
      />
    </>
  );
};
