import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ColumnDef, RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button, Overlay, Spinner } from '@blueprintjs/core';
import { format } from 'date-fns';
import classNames from 'classnames';
import { debounce, isEmpty, each, keys, keyBy, mapValues } from 'lodash';
import Table, { ParamsChangeFn } from 'components/Table';
import {
  Environment,
  useEnvironmentFilterOptionsLazyQuery,
  useEnvironmentsLazyQuery,
  useTracksLazyQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import { selectEnvironmentSummaryView, tableViewSlice } from 'reducers/tableView';
import styles from './index.module.css';
import { FilterType, SelectItem } from 'types';
import { globalDebounceTime } from '../../constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (environments: Environment[]) => void;
}

export default (props: Props) => {
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const { tableFilters, tableSorting } = useSelector(selectEnvironmentSummaryView);
  const [tableData, setTableData] = useState<Environment[]>([]);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [ownerSelectItems, setOwnerSelectItems] = useState<SelectItem<string>[]>([]);
  const [trackSelectItems, setTrackSelectItems] = useState<SelectItem<string>[]>([]);

  const [getEnvironments, { data: environmentsData, loading: isLoading }] = useEnvironmentsLazyQuery();

  const [getTracks] = useTracksLazyQuery({
    onCompleted: data => {
      setTrackSelectItems(data.tracks.map(t => { return { label: t.short_name, value: t.short_name }; }));
    },
  });

  const [getEnvironmentFilterOptions] = useEnvironmentFilterOptionsLazyQuery({
    onCompleted: data => {
      setOwnerSelectItems(data.environmentFilterOptions.owners.map(o => { return { label: o, value: o }; }));
    },
  });

  useEffect(() => {
    getTracks();
    getEnvironmentFilterOptions({ fetchPolicy: 'no-cache' });
  }, []);

  const columnHelper = createColumnHelper<Environment>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('track', {
      header: 'Track',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: trackSelectItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: ownerSelectItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<Environment>[];

  useEffect(() => {
    if (!props.isOpen) {
      setIsInitialLoad(true);
    }
  }, [props.isOpen]);

  useEffect(() => {
    setTableData(environmentsData?.environments.rows ?? [] as Environment[]);
  }, [environmentsData]);

  useEffect(() => {
    if (isEmpty(selectedRows)) {
      setIsAddDisabled(true);
    } else {
      setIsAddDisabled(false);
    }
  }, [selectedRows]);

  const onSelectionChange = (selections: RowSelectionState) => {
    setSelectedRows(selections);
  };

  const onSubmit = () => {
    const selectedEnvs: Environment[] = [];
    each(keys(selectedRows), (index: string) => {
      const selectedEnv = tableData[Number(index)];
      selectedEnvs.push(selectedEnv);
    });

    props.onSuccess(selectedEnvs);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    dispatch(tableViewSlice.actions.setEnvironmentSummaryView({ filters, sorting }));

    getEnvironments({
      variables: {
        input: {
          filters: mapValues(keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const debouncedOnTableParamsChange = debounce(onTableParamsChange, globalDebounceTime);

  return (
    <>
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isOpen={props.isOpen}
        onClose={props.onClose}
        title="Add Environment(s)"
        style={{ width: '80%', maxWidth: 'none' }}
      >
        <DialogBody>
          <Table
            id="environment-selector"
            columns={columns}
            data={tableData}
            enableRowSelection
            enableSingleRowSelection
            onRowSelect={onSelectionChange}
            enablePagination
            enableHiding
            manualPagination
            manualSorting
            manualFiltering
            initialColumnFilters={tableFilters}
            initialSorting={tableSorting}
            persistColumnVisibility
            onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
            totalRowCount={environmentsData?.environments.totalCount || 0}
            frozenBgColor="#252a31"
            headerStickyTop="15px"
            footerStickyBottom="-15px"
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="cancel" onClick={props.onClose} text="Cancel" />,
            <Button
              key="add"
              disabled={isAddDisabled}
              intent={Intent.PRIMARY}
              onClick={onSubmit}
              text="Add"
            />,
          ]}
        />
      </Dialog>
      <Overlay
        isOpen={isLoading && isInitialLoad}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </>
  );
};
