import { MenuItem, Tag } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, MultiSelect2, MultiSelect2Props } from '@blueprintjs/select';
import { useEffect, useState } from 'react';

import { SelectItem } from 'types';

interface Props<T> {
  items: SelectItem<T>[];
  onChange?: (selectedItems: SelectItem<T>[]) => void;
  selectedItems?: SelectItem<T>[];
  selectProps?: Partial<MultiSelect2Props<SelectItem<T>>>;
  disableMultipleSelection?: boolean;   // defaults to false
}

export default <T, >(props: Props<T>) => {
  const [selectedItems, setSelectedItems] = useState<SelectItem<T>[]>(props.selectedItems ?? []);
  const disableMultipleSelection = props.disableMultipleSelection ?? false;

  useEffect(() => {
    props.onChange?.(selectedItems);
  }, [selectedItems]);

  const areItemsEqual = (itemA: SelectItem<T>, itemB: SelectItem<T>) => {
    return itemA.label === itemB.label && itemA.value === itemB.value;
  };
  const getSelectedItemIndex = (item: SelectItem<T>) => {
    return selectedItems.findIndex(selectedItem => areItemsEqual(selectedItem, item));
  };
  const isItemSelected = (item: SelectItem<T>) => {
    return selectedItems.some(selectedItem => areItemsEqual(selectedItem, item));
  };

  const onClear = () => setSelectedItems([]);
  const onRemove = (_item: SelectItem<T>, index: number) => {
    const newItems = [...selectedItems];
    newItems.splice(index, 1);
    setSelectedItems(newItems);
  };
  const onItemSelect = (item: SelectItem<T>) => {
    if (disableMultipleSelection) {
      setSelectedItems([item]);
    } else if (!isItemSelected(item)) {
      setSelectedItems([...selectedItems, item]);
    } else {
      const index = getSelectedItemIndex(item);
      const newItems = [...selectedItems];
      newItems.splice(index, 1);
      setSelectedItems(newItems);
    }
  };
  const itemPredicate: ItemPredicate<SelectItem<T>> = (query, item) => {
    return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };
  const itemRenderer: ItemRenderer<SelectItem<T>> = (item, { handleClick, index, modifiers }) => {
    if (!modifiers.matchesPredicate) return null;

    return (
      <MenuItem
        key={index}
        onClick={handleClick}
        selected={isItemSelected(item)}
        text={item.label}
      />
    );
  };
  const tagRenderer = (item: SelectItem<T>) => {
    return <Tag>{item.label}</Tag>;
  };

  return (
    <MultiSelect2
      {...props.selectProps}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      items={props.items}
      onClear={onClear}
      onRemove={onRemove}
      onItemSelect={onItemSelect}
      placeholder="Select..."
      selectedItems={selectedItems}
      tagRenderer={tagRenderer}
    />
  );
};
