import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ColumnDef, RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button, Overlay, Spinner } from '@blueprintjs/core';
import { format } from 'date-fns';
import classNames from 'classnames';
import { debounce, isEmpty, each, keys, keyBy, mapValues } from 'lodash';
import Table, { ParamsChangeFn } from 'components/Table';
import {
  Sweep,
  useSweepsLazyQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import styles from './index.module.css';
import { globalDebounceTime } from '../../constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (sweeps: Sweep[]) => void;
}

export default (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const [tableData, setTableData] = useState<Sweep[]>([]);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [getSweeps, { data: sweepData, loading: isLoading }] = useSweepsLazyQuery();

  const columnHelper = createColumnHelper<Sweep>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('description', {
      header: 'Description',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<Sweep>[];

  useEffect(() => {
    if (!props.isOpen) {
      setIsInitialLoad(true);
    }
  }, [props.isOpen]);

  useEffect(() => {
    setTableData(sweepData?.sweeps.rows ?? [] as Sweep[]);
  }, [sweepData]);

  useEffect(() => {
    if (isEmpty(selectedRows)) {
      setIsAddDisabled(true);
    } else {
      setIsAddDisabled(false);
    }
  }, [selectedRows]);

  const onSelectionChange = (selections: RowSelectionState) => {
    setSelectedRows(selections);
  };

  const onSubmit = () => {
    const selectedSweeps: Sweep[] = [];
    each(keys(selectedRows), (index: string) => {
      const selectedSweep = tableData[Number(index)];
      selectedSweeps.push(selectedSweep);
    });

    props.onSuccess(selectedSweeps);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    getSweeps({
      variables: {
        input: {
          filters: mapValues(keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const debouncedOnTableParamsChange = debounce(onTableParamsChange, globalDebounceTime);

  return (
    <>
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isOpen={props.isOpen}
        onClose={props.onClose}
        title="Add Sweep"
        style={{ width: '80%', maxWidth: 'none' }}
      >
        <DialogBody>
          <Table
            id="sweep-selector"
            columns={columns}
            data={tableData}
            enableRowSelection
            enableSingleRowSelection
            onRowSelect={onSelectionChange}
            enablePagination
            enableHiding
            manualPagination
            manualSorting
            manualFiltering
            persistColumnVisibility
            onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
            totalRowCount={sweepData?.sweeps.totalCount || 0}
            frozenBgColor="#252a31"
            headerStickyTop="15px"
            footerStickyBottom="-15px"
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="cancel" onClick={props.onClose} text="Cancel" />,
            <Button
              key="add"
              disabled={isAddDisabled}
              intent={Intent.PRIMARY}
              onClick={onSubmit}
              text="Add"
            />,
          ]}
        />
      </Dialog>
      <Overlay
        isOpen={isLoading && isInitialLoad}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </>
  );
};
