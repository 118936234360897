import { ReactNode, forwardRef, Ref, useState, useEffect } from 'react';
import { EditableText } from '@blueprintjs/core';
import classNames from 'classnames';
import { map } from 'lodash';
import IconTooltip from 'components/IconTooltip';

import {
  useEventsLazyQuery,
  Event,
} from 'graphql/generated/graphql';
import { GQLSetup, SelectItem } from 'types';
import Select from 'components/Select';
import { specSelectItems, yearItems, seriesItems, teamSelectItems } from '../../constants';

import styles from './index.module.css';
import { useRouteLoaderData } from 'react-router-dom';

interface TitleProps {
  children?: ReactNode;
  forwardedRef?: Ref<HTMLDivElement>;
  onChange?: (updates: { [key: string]: string | number }) => void;
  setup: GQLSetup;
  branch?: string;
}

export default forwardRef<HTMLDivElement, TitleProps>((props: TitleProps, ref: Ref<HTMLDivElement>) => {
  const { setup, children, forwardedRef, branch } = props;
  const [eventItems, setEventItems] = useState<Event[]>([]);
  const [eventSelectItems, setEventSelectItems] = useState<SelectItem<Event>[]>([]);
  const { getUser: { teams } } = useRouteLoaderData('root') as any; // eslint-disable-line @typescript-eslint/no-explicit-any

  const [getEventsData] = useEventsLazyQuery({
    onCompleted: data => setEventItems(data?.events as Event[]),
  });

  useEffect(() => {
    getEventsData({
      variables: {
        year: setup.year,
        series: setup.series,
      },
    });
  }, [setup.year, setup.series, getEventsData]);

  useEffect(() => {
    if (setup.id && setup.name && branch) {
      setEventItems([]);
      setEventSelectItems([]);
    }
  }, [setup.id, branch, setup.name]);

  useEffect(() => {
    setEventSelectItems(map(eventItems, (eventItem: Event) => ({ label: eventItem.name, value: eventItem })));
  }, [eventItems]);

  const onEventChangeHandler = (eventValue: Event) => {
    props.onChange?.({
      event: eventValue.name,
      track: eventValue.track || '',
    });
  };

  const onTeamChangeHandler = (teamName: string, teamId: string, label: string) => {
    const effectiveTeamName = teamName || (label !== 'None' ? label : '');
    props.onChange?.({
      team_name: effectiveTeamName,
      team_id: teamId,
    });
  };

  const disableBranch = true;
  const disableSeries = true;
  return (
    <div className={styles.titleBar} ref={forwardedRef ?? ref}>
      <div className={styles.titleContainer}>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Spec</div>
          <Select
            disabled={!props.onChange}
            initialItem={specSelectItems.find(i => setup.spec === i.value)}
            items={specSelectItems}
            noSelectionText="Spec"
            onChange={item => props.onChange?.({ spec: item.value })}
          />
        </div>
        <div className={styles.titleColumn} style={{ maxWidth: '480px' }}>
          <div className={styles.titleLabel}>Name</div>
          <IconTooltip content={setup.name || ''} position="bottom">
            <EditableText
              className={classNames(styles.titleValue)}
              value={setup.name || ''}
              disabled={!props.onChange}
              onChange={value => props.onChange?.({ name: value })}
              placeholder="Name"
            />
          </IconTooltip>
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Branch</div>
          <EditableText
            className={classNames(styles.titleValue)}
            value={props.branch || ''}
            disabled={disableBranch}
            onChange={value => props.onChange?.({ branch: value })}
            placeholder="Branch"
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Team</div>
          <Select
            disabled={!props.onChange}
            initialItem={{ label: setup?.team_name || 'None', value: setup?.team_name || null }}
            items={teamSelectItems(teams, setup.organization_name)}
            noSelectionText="Team"
            onChange={item => onTeamChangeHandler(item.value, item.id || '', item.label)}
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Year</div>
          <Select
            disabled={!props.onChange}
            initialItem={yearItems.find(i => setup.year === i.value)}
            items={yearItems}
            noSelectionText="Year"
            onChange={item => props.onChange?.({ year: item.value })}
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Series</div>
          <Select
            disabled={disableSeries}
            initialItem={seriesItems.find(i => setup.series === i.value)}
            items={seriesItems}
            noSelectionText="Series"
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Event</div>
          <Select
            disabled={!props.onChange}
            initialItem={eventSelectItems.find(i => setup.event === i.value.name)}
            items={eventSelectItems}
            noSelectionText="Event"
            onChange={item => onEventChangeHandler(item.value)}
          />
        </div>
        <div className={styles.titleColumnDesc}>
          <div className={styles.titleLabel}>Description</div>
          <IconTooltip content={setup.description || ''} position="bottom">
            <EditableText
              className={classNames(styles.titleValue)}
              value={setup.description || ''}
              disabled={!props.onChange}
              onChange={value => props.onChange?.({ description: value })}
              minWidth={2}
              placeholder="Description"
            />
          </IconTooltip>
        </div>
      </div>
      {children}
    </div>
  );
});
