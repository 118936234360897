import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Button, Dialog, DialogBody, DialogFooter, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import {
  CreateRunFieldInput,
  RunField,
  RunFieldPosition,
  RunFieldType,
  RunFieldsDocument,
  UpdateRunFieldInput,
  useCreateRunFieldMutation,
  useUpdateRunFieldMutation,
  useRunFieldsByTypeQuery,
  useSetupFieldsByTypeQuery,
  SetupFieldType,
  SetupField,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import { RunFieldFormInput, RunFieldInput } from 'types';
import RunFieldForm from 'components/RunFieldForm';
import { useRouteLoaderData } from 'react-router-dom';
import { validateExpressionSyntax, validateFieldReferences } from '../../helpers/expressionsValidation';
import { find, get } from 'lodash';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (runField: RunField) => void;
  runField?: RunFieldFormInput | null;
}

const defaultRunField: RunFieldInput = {
  type: RunFieldType.STRING,
  name: '',
  label: '',
  tooltip: '',
  positions: [] as RunFieldPosition[],
  options: [] as string[],
  organization_id: '',
  organization_name: '',
  team_id: null,
  team_name: null,
  series: '',
  expression: '',
};

export default (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  let defaultValues = defaultRunField;
  if (props.runField) {
    defaultValues = { ...props.runField };
  }

  const darkMode = useSelector(selectDarkMode);
  const form = useForm<RunFieldInput>({ defaultValues });
  useEffect(() => {
    form.reset(defaultValues);
  }, [props.runField]);
  const isEdit = Boolean(props.runField?.id);

  const [createRunField] = useCreateRunFieldMutation();
  const [updateRunField] = useUpdateRunFieldMutation();

  const currentType = useWatch({ control: form.control, name: 'type' });

  const { data: allRunFieldsData } = useRunFieldsByTypeQuery({
    skip: currentType !== RunFieldType.EXPRESSION,
    variables: {
      input: {
        filters: {
          type: [RunFieldType.FLOAT],
        },
      },
    },
  });

  const { data: setupFieldsData } = useSetupFieldsByTypeQuery({
    skip: currentType !== RunFieldType.SETUP_FIELD,
    variables: {
      input: {
        filters: {
          type: [SetupFieldType.FLOAT, SetupFieldType.PART],
        },
      },
    },
  });

  const runFields = useMemo(() => {
    return (allRunFieldsData?.runFields.rows || []) as RunField[];
  }, [allRunFieldsData]);

  const setupFields = useMemo(() => {
    return (setupFieldsData?.setupFields.rows || []) as SetupField[];
  }, [setupFieldsData]);

  // Convert expression references from ids to names
  useEffect(() => {
    if (props.runField?.type === RunFieldType.EXPRESSION && props.runField.expression) {
      const expressionWithNames = props.runField.expression.replace(/\{(\d+)\}/g, (match, id) => {
        const field = runFields.find(f => f.id === parseInt(id, 10));
        return field ? `{${field.name}}` : match;
      });
      form.setValue('expression', expressionWithNames);
    }
  }, [props.runField, runFields]);

  const validateExpression = (input: RunFieldInput, runFields: RunField[]): void => {
    const { expression } = input;

    if (!expression) {
      throw new Error('Expression is required');
    }

    const expressionWithNames = expression.replace(/\{(\d+)\}/g, (match, id) => {
      const field = runFields.find(f => f.id === parseInt(id, 10));
      return field ? `{${field.name}}` : match;
    });

    validateExpressionSyntax(expressionWithNames);
    validateFieldReferences({
      fields: runFields,
      expression: expressionWithNames,
      fieldType: 'run',
    });
  };

  const handleCreateRunField = (input: CreateRunFieldInput) => {
    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);
    createRunField({
      variables: {
        input: {
          ...input,
          organization_id: get(findOrg, 'id', ''),
          team_id: get(findTeam, 'id', null),
        },
      },
      onCompleted: ({ runField }) => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Run field successfully created',
        });
        form.reset(defaultRunField);
        props.onSuccess(runField as RunField);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating run field: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }) => {
        if (mutationData?.runField) {
          cache.updateQuery({
            query: RunFieldsDocument,
          }, queryData => {
            if (!queryData) return undefined;
            return {
              runFields: {
                rows: [
                  ...queryData.runFields.rows,
                  mutationData.runField,
                ],
              },
            };
          });
        }
      },
    });
  };
  const handleUpdateRunField = (input: UpdateRunFieldInput) => {
    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);

    updateRunField({
      variables: {
        input: {
          ...input,
          organization_id: get(findOrg, 'id', null),
          team_id: get(findTeam, 'id', null),
        },
      },

      onCompleted: ({ runField }) => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Run field successfully updated',
        });
        form.reset(defaultRunField);
        props.onSuccess(runField as RunField);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating run field: ${e.message}`,
        });
      },
    });
  };

  const onClose = () => {
    form.reset(defaultRunField);
    props.onClose();
  };
  const onSubmit = (input: RunFieldInput) => {
    if (input.type === RunFieldType.EXPRESSION) {
      try {
        if (!input.expression) {
          throw new Error('Expression is required for EXPRESSION type fields');
        }

        validateExpression(input, runFields);

        // Convert expression references from names to ids
        const expressionWithIds = input.expression.replace(/\{([^{}]+)\}/g, (match, name) => {
          const field = runFields.find(f => f.name === name);
          return field ? `{${field.id}}` : match;
        });

        input.expression = expressionWithIds;
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Invalid expression: ${errorMessage}`,
        });
        return;
      }
    }

    if (isEdit) handleUpdateRunField(input as UpdateRunFieldInput);
    else handleCreateRunField(input as CreateRunFieldInput);
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Run Field`}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <RunFieldForm
              runFields={runFields}
              setupFields={setupFields}
            />
          </DialogBody>
        </form>
      </FormProvider>
      <DialogFooter
        actions={(
          <>
            <Button
              onClick={onClose}
              text="Cancel"
            />
            <Button
              intent={Intent.PRIMARY}
              onClick={form.handleSubmit(onSubmit)}
              key="submit"
              text="Save"
            />
          </>
        )}
      />
    </Dialog>
  );
};
