import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button, InputGroup, Overlay, Spinner } from '@blueprintjs/core';
import { format } from 'date-fns';
import classNames from 'classnames';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import { useAlert } from 'components/Alert';
import AppToaster from 'helpers/toaster';
import {
  Sim,
  useSimsLazyQuery,
  useDeleteSimMutation,
  useCloneSimMutation,
  useSimFilterOptionsLazyQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import { selectSimSummaryView, tableViewSlice } from 'reducers/tableView';
import { specSelectItems, simTypeSelectItems, seriesItems, teamSelectItems, organizationSelectItems, globalDebounceTime } from '../../constants';
import { baseName } from 'config';
import { FilterType, SelectItem } from 'types';
import { cloneDeep, debounce, find, keyBy, mapValues } from 'lodash';
import Select from 'components/Select';
import ClickableCell from 'components/ClickableCell';
import styles from './index.module.css';

export const getFormattedSimType = (type: string) => {
  switch (type) {
    case 'KINEMATICS': return 'Kinematics';
    case 'SETUP': return 'Setup';
    case 'RIDEMODEL': return 'Ride Model';
    case 'SIMULATOR': return 'Simulator';
    case 'EIGHTPOST': return 'Eight Post';
    case 'TRD_ELAP': return 'TRD eLap';
    case 'AVL_ELAP': return 'AVL eLap';
    case 'PITSTOP': return 'Pit Stop';
    default: return '';
  }
};

export default () => {
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const { tableFilters, tableSorting } = useSelector(selectSimSummaryView);
  const [tableData, setTableData] = useState<Sim[]>([]);
  const [cloneSource, setCloneSource] = useState<Sim>();
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [ownerFilterItems, setOwnerFilterItems] = useState<SelectItem<string>[]>([]);
  const [organizationFilterItems, setOrganizationFilterItems] = useState<SelectItem<string>[]>([]);
  const [teamFilterItems, setTeamFilterItems] = useState<SelectItem<string>[]>([]);
  const cloneName = useRef<HTMLInputElement>(null);
  const alert = useAlert();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const [getSimFilterOptions] = useSimFilterOptionsLazyQuery({
    onCompleted: data => {
      setOwnerFilterItems(data.simFilterOptions.owners.map(o => { return { label: o, value: o }; }));
      setOrganizationFilterItems(data.simFilterOptions.organizations.map(o => { return { label: o, value: o }; }));
      setTeamFilterItems(data.simFilterOptions.teams.map(t => { return { label: t, value: t }; }));
    },
  });

  useEffect(() => {
    getSimFilterOptions({ fetchPolicy: 'no-cache' });
  }, []);

  const [getSims, { data: simsData, loading: isLoading }] = useSimsLazyQuery({
    onCompleted: data => {
      setTableData(data.sims.rows as Sim[]);
      setIsInitialLoad(false);
    },
    fetchPolicy: 'cache-and-network',
  });
  const [cloneSim] = useCloneSimMutation();
  const [deleteSim] = useDeleteSimMutation();

  const renderNameCell = (id: number, name: string) => (
    <ClickableCell
      value={name}
      onClick={(newTab) => {
        const url = `/sims/${id}`;
        if (newTab) {
          const basePath = baseName === '/' ? '' : baseName;
          window.open(`${basePath}${url}`, '_blank');
        } else {
          navigate(url);
        }
      }}
    />
  );

  const columnHelper = createColumnHelper<Sim>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => renderNameCell(info.row.original.id, info.getValue()),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('type', {
      header: 'Type',
      cell: info => {
        const value = info.getValue() as string;
        return getFormattedSimType(value);
      },
      enableColumnFilter: true,
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: simTypeSelectItems,
          multiSelect: true,
        },
      },
    }),
    columnHelper.accessor('organization_name', {
      header: 'Organization',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: organizationFilterItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
    }),
    columnHelper.accessor('series', {
      header: 'Series',
      cell: info => {
        const getSeries = find(seriesItems, series => series.value === info.getValue());
        if (!getSeries) return '';
        return getSeries.label;
      },
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: seriesItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
      enableSorting: false,
      size: 100,
    }),
    columnHelper.accessor('team_name', {
      header: 'Team',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: teamFilterItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
      size: 100,
    }),
    columnHelper.accessor('spec', {
      header: 'Spec',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: specSelectItems,
          multiSelect: true,
        },
      },
      size: 100,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: ownerFilterItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<Sim>[];

  const rowActions: RowActions<Sim> = [{
    label: 'Edit',
    value: row => {
      navigate(`/sims/${row.original.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      const branchId = row.original.id;
      if (!branchId) return;
      alert.showAlert(`Delete Sim "${row.original.name}?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteSim({
          variables: {
            id: branchId,
          },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Successfully deleted sim',
            });
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Failed to delete sim: ${e.message}`,
            });
          },
          refetchQueries: ['Sims'],
        });
      });
    },
  }];

  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleClone();
      }
    };

    if (isCloneModalOpen) {
      document.addEventListener('keydown', onEnter);
    }

    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [isCloneModalOpen]);

  const handleClone = () => {
    if (!cloneSource) return;
    cloneSim({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
        orgName: cloneSource.organization_name,
        orgId: cloneSource.organization_id,
        teamName: cloneSource.team_name,
        teamId: cloneSource.team_id,
        series: cloneSource.series,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned sim',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone sim: ${e.message}`,
        });
      },
      refetchQueries: ['Sims'],
    });
    setCloneModalOpen(false);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    dispatch(tableViewSlice.actions.setSimSummaryView({ filters, sorting }));
    getSims({
      variables: {
        input: {
          filters: mapValues(keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const debouncedOnTableParamsChange = debounce(onTableParamsChange, globalDebounceTime);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectItem = (item: any, name: string) => {
    const clone = cloneDeep(cloneSource);

    if (clone) {
      if (name === 'series') {
        clone.series = item.value;
      }

      if (name === 'org') {
        clone.organization_name = item.value;
        clone.organization_id = item.id;

        // Reset team selections if org changes.
        const findTeam = find(teams, team => team.name === cloneSource?.team_name);
        if (findTeam && findTeam.organization.name !== item.value) {
          clone.team_name = null;
          clone.team_id = null;
        }
      }

      if (name === 'team') {
        clone.team_name = item.value;
        clone.team_id = item.id;
      }

      setCloneSource(clone);
    }
  };

  return (
    <div>
      <Table
        id="sim_summary"
        columns={columns}
        data={tableData}
        rowActions={rowActions}
        totalRowCount={simsData?.sims.totalCount || 0}
        enableHiding
        enablePagination
        manualFiltering
        manualPagination
        manualSorting
        persistColumnVisibility
        initialColumnFilters={tableFilters}
        initialSorting={tableSorting}
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <div>
            <div style={{ paddingBottom: '5px' }}>Organization</div>
            <Select
              initialItem={{ label: cloneSource?.organization_name || 'None', value: cloneSource?.organization_name || null }}
              items={organizationSelectItems(organizations)}
              noSelectionText="Organization"
              onChange={item => setSelectItem(item, 'org')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Team</div>
            <Select
              initialItem={{ label: cloneSource?.team_name || 'None', value: cloneSource?.team_name || null }}
              items={teamSelectItems(teams, cloneSource?.organization_name)}
              noSelectionText="Team"
              onChange={item => setSelectItem(item, 'team')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Series</div>
            <Select
              initialItem={seriesItems.find(i => cloneSource?.series === i.value)}
              items={seriesItems}
              noSelectionText="Series"
              onChange={item => setSelectItem(item, 'series')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Name</div>
            <InputGroup
              placeholder="Enter new SUIT name"
              inputRef={cloneName}
              defaultValue={`${cloneSource?.name} CLONE`}
            />
          </div>
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => handleClone()}
            />
          )}
        />
      </Dialog>
      <Overlay
        isOpen={isLoading && isInitialLoad}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </div>
  );
};
