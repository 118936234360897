import { Button, Dialog, DialogBody, DialogFooter, InputGroup, Intent, Overlay, Spinner } from '@blueprintjs/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { useAlert } from 'components/Alert';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import { format } from 'date-fns';
import { cloneDeep, debounce, find, keyBy, mapValues } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { selectDarkMode } from 'reducers/ui';
import AppToaster from 'helpers/toaster';
import { seriesItems, teamSelectItems, organizationSelectItems, globalDebounceTime } from '../../constants';
import { baseName } from 'config';
import Select from 'components/Select';
import ClickableCell from 'components/ClickableCell';
import AddMetricDialog from './AddMetricDialog';
import { Metric, useCloneMetricMutation, useDeleteMetricMutation, useMetricsLazyQuery } from 'graphql/generated/graphql';
import styles from './index.module.css';

interface Props {
  isAddMetricDialogOpen: boolean;
  onAddMetricDialogClose: () => void;
}

const MetricsTable = (props: Props) => {
  const { isAddMetricDialogOpen, onAddMetricDialogClose } = props;
  const darkMode = useSelector(selectDarkMode);
  const [cloneSource, setCloneSource] = useState<Metric>();
  const [tableData, setTableData] = useState<Metric[]>([]);
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const cloneName = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const [cloneMetric] = useCloneMetricMutation();
  const [deleteMetric] = useDeleteMetricMutation();

  const [getMetrics, { refetch: refetchMetrics, loading: isLoading }] = useMetricsLazyQuery({
    onCompleted: data => {
      setTableData(data.metrics.rows as Metric[]);
      setIsInitialLoad(true);
    },
    fetchPolicy: 'cache-and-network',
  });

  const alert = useAlert();
  const navigate = useNavigate();

  const handleRefetchMetrics = useCallback(() => {
    refetchMetrics();
  }, [refetchMetrics]);

  const renderNameCell = (id: number, name: string) => (
    <ClickableCell
      value={name}
      onClick={(newTab) => {
        const url = `/sims/metrics/${id}`;
        if (newTab) {
          const basePath = baseName === '/' ? '' : baseName;
          window.open(`${basePath}${url}`, '_blank');
        } else {
          navigate(url);
        }
      }}
    />
  );

  const columnHelper = createColumnHelper<Metric>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => renderNameCell(info.row.original.id, info.getValue()),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('description', {
      header: 'Description',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<Metric>[];

  const rowActions: RowActions<Metric> = [{
    label: 'Edit',
    value: row => {
      navigate(`/sims/metrics/${row.original.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      const MetricId = row.original.id;
      if (!MetricId) return;
      alert.showAlert(`Delete Metric "${row.original.name}?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteMetric({
          variables: { id: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Metric successfully deleted',
            });
            if (row.getIsSelected()) row.toggleSelected();
            refetchMetrics();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting Metric: ${e.message}`,
            });
          },
        });
      });
    },
  }];

  const handleClone = () => {
    if (!cloneSource) return;
    cloneMetric({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
        orgName: cloneSource.organization_name,
        orgId: cloneSource.organization_id,
        teamName: cloneSource.team_name,
        teamId: cloneSource.team_id,
        series: cloneSource.series,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned Metric',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone Metric: ${e.message}`,
        });
      },
      refetchQueries: ['Metrics'],
    });
    setCloneModalOpen(false);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    getMetrics({
      variables: {
        input: {
          filters: mapValues(keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectItem = (item: any, name: string) => {
    const clone = cloneDeep(cloneSource);

    if (clone) {
      if (name === 'series') {
        clone.series = item.value;
      }

      if (name === 'org') {
        clone.organization_name = item.value;
        clone.organization_id = item.id;

        // Reset team selections if org changes.
        const findTeam = find(teams, team => team.name === cloneSource?.team_name);
        if (findTeam && findTeam.organization.name !== item.value) {
          clone.team_name = null;
          clone.team_id = null;
        }
      }

      if (name === 'team') {
        clone.team_name = item.value;
        clone.team_id = item.id;
      }

      setCloneSource(clone);
    }
  };

  const debouncedOnTableParamsChange = debounce(onTableParamsChange, globalDebounceTime);

  return (
    <div>
      <Table
        id="Metrics-summary"
        columns={columns}
        data={tableData}
        rowActions={rowActions}
        totalRowCount={0}
        enableHiding
        enablePagination
        manualFiltering
        manualPagination
        manualSorting
        persistColumnVisibility
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <div>
            <div style={{ paddingBottom: '5px' }}>Name</div>
            <InputGroup
              placeholder="Enter new Metric name"
              inputRef={cloneName}
              defaultValue={`${cloneSource?.name} CLONE`}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Organization</div>
            <Select
              initialItem={{ label: cloneSource?.organization_name || 'None', value: cloneSource?.organization_name || null }}
              items={organizationSelectItems(organizations)}
              noSelectionText="Organization"
              onChange={item => setSelectItem(item, 'org')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Team</div>
            <Select
              initialItem={{ label: cloneSource?.team_name || 'None', value: cloneSource?.team_name || null }}
              items={teamSelectItems(teams, cloneSource?.organization_name)}
              noSelectionText="Team"
              onChange={item => setSelectItem(item, 'team')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Series</div>
            <Select
              initialItem={seriesItems.find(i => cloneSource?.series === i.value)}
              items={seriesItems}
              noSelectionText="Series"
              onChange={item => setSelectItem(item, 'series')}
            />
          </div>
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => handleClone()}
            />
          )}
        />
      </Dialog>
      <AddMetricDialog
        isOpen={isAddMetricDialogOpen}
        onClose={onAddMetricDialogClose}
        handleRefetchMetrics={handleRefetchMetrics}
      />
      <Overlay
        isOpen={isLoading && isInitialLoad}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </div>
  );
};

export default MetricsTable;
