export const hasPermission = (permissionToCheck: string, permissions: string[]) => {
  return permissions && permissions.includes(permissionToCheck);
};

export const getTeamIds = (teams: { id: string; }[]) => {
  const length = teams ? teams.length : 0;
  return length ? teams.map((team: { id: string; }) => team.id) : [];
};

export const getOrgIds = (organizations: { id: string; }[]) => {
  const length = organizations ? organizations.length : 0;
  return length ? organizations.map((org: { id: string; }) => org.id) : [];
};

export const getTeamPermissions = (
  teamId: string | null | undefined,
  teams: { id: string; permissions: { name: string }[] }[],
  organizations: { id: string }[],
  currentPermissions: string[],
  organizationToCheck: string
) => {
  if (teamId && !getOrgIds(organizations).includes(organizationToCheck)) {
    const team = teams.find((t) => t.id === teamId);
    return team ? team.permissions.map((p) => p.name) : [];
  }
  return currentPermissions;
};
