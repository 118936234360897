import { get, has, isEqual, isObject, omit, set } from 'lodash';
import {
  SetupFieldType,
  Spec,
  Part,
  SetupField,
} from '../graphql/generated/graphql';
import { isMathExpression } from './expressionsValidation';

import { GQLSetup } from 'types';

interface DependencyItem {
  [key: string]: string;
}

export const deleteProp = (object: { [k: string]: any }, path: string[]) => { // eslint-disable-line @typescript-eslint/no-explicit-any
  const last = path.pop();
  if (!last) return;
  delete path.reduce((o, k) => o[k] || {}, object)[last];
};

export const excludeSetupMeta = (setup: GQLSetup) => {
  return omit(setup, ['id']);
};

export const excludeMetaForCompare = (setup: GQLSetup) => {
  const omitKeys = [
    'id',
    'name',
    'parts',
    'owner',
    'root',
    'parent',
    'updated_at',
    'created_at',
  ];

  return omit(setup, omitKeys);
};

export const makeSetupDataValue = (value: unknown, setupFieldType: SetupFieldType, forceConversion = false) => {
  switch (setupFieldType) {
    case SetupFieldType.FLOAT:
      if (isMathExpression(value)) {
        return { _exp: value };
      } else if (forceConversion && typeof value === 'string' && value.trim() !== '' && !Number.isNaN(Number(value))) {
        return Number(value);
      } else if (typeof value === 'number') {
        return value;
      }
      return value;
    case SetupFieldType.INT:
      if (isMathExpression(value)) {
        return { _exp: value };
      } else if (typeof value === 'string' && value.trim() !== '' && !Number.isNaN(Number(value))) {
        return Number(value);
      } else if (typeof value === 'number') {
        return value;
      }
      return value;
    case SetupFieldType.PART:
      return { _source: value };
    default:
      return value;
  }
};

export const setSetupDataValue = (data: object, path: string, value: unknown, setupFieldType: SetupFieldType, forceConversion = false) => {
  set(data, path, makeSetupDataValue(value, setupFieldType, forceConversion));
};

export const getSetupDataValue = (data: object | undefined, path: string, setupFieldType: SetupFieldType) => {
  if (!data) return undefined;

  let retVal;
  switch (setupFieldType) {
    case SetupFieldType.FLOAT:
    case SetupFieldType.INT:
      retVal = get(data, path);
      if (isObject(retVal) && has(retVal, '_exp')) return retVal._exp;
      return retVal;
    case SetupFieldType.PART:
      retVal = get(data, path);
      if (isObject(retVal) && has(retVal, '_source')) return retVal._source;
      return retVal;
    default:
      return get(data, path);
  }
};

const updateGen7TireSetDependencies = (data: object, part: Part, setupField: SetupField) => {
  if (!data || !part || !part.data) return data;

  setSetupDataValue(data, setupField.path, part.id, SetupFieldType.PART);

  // Added Static paths as apposed to looking in the SetupFields dependencies as Nate Requested it. Claims the SetupsFields might change.
  const dependencyItems: DependencyItem[] = [
    { 'lf._source': 'vehicle.tires.lf' },
    { 'lr._source': 'vehicle.tires.lr' },
    { 'rf._source': 'vehicle.tires.rf' },
    { 'rr._source': 'vehicle.tires.rr' },
    { pressure_setup_lf: 'vehicle.tires.pressure_setup_lf' },
    { pressure_setup_lr: 'vehicle.tires.pressure_setup_lr' },
    { pressure_setup_rf: 'vehicle.tires.pressure_setup_rf' },
    { pressure_setup_rr: 'vehicle.tires.pressure_setup_rr' },
    { rollout_inburn_lr: 'vehicle.tires.rollout_lr' },
    { rollout_inburn_rr: 'vehicle.tires.rollout_rr' },
    { switch_apply_user_rollouts: 'vehicle.tires.switch_apply_user_rollouts' },
  ];

  dependencyItems.forEach((dependencyItem) => {
    const key = Object.keys(dependencyItem)[0];
    const dependencyItemValue = get(part, `data.${key}`);

    if (dependencyItemValue !== undefined && dependencyItemValue !== null) {
      const dependencyPath = dependencyItem[key];
      if (dependencyPath) {
        let fieldType: SetupFieldType;
        let value: unknown;

        if (key === 'switch_apply_user_rollouts') {
          fieldType = SetupFieldType.BOOLEAN;
          value = Boolean(dependencyItemValue);
        } else if (key.endsWith('._source')) {
          fieldType = SetupFieldType.PART;
          value = Number(dependencyItemValue);
        } else {
          fieldType = SetupFieldType.FLOAT;
          value = Number(dependencyItemValue);
        }

        set(data, dependencyPath, makeSetupDataValue(value, fieldType));
      }
    }
  });

  return data;
};

// In the future, we will need to add a GT3 tire set update routine.
export const updateTireSetDependencies = (data: object, spec: Spec, part: Part, setupField: SetupField) => {
  switch (spec) {
    case Spec.GEN7:
      return updateGen7TireSetDependencies(data, part, setupField);
    default:
      return data;
  }
};

// Helper function for Setup Merge page
export const compareSetupValues = (value1: unknown, value2: unknown): boolean => {
  if (isObject(value1) && has(value1, '_source') && isObject(value2) && has(value2, '_source')) {
    return value1._source === value2._source;
  }

  if (isObject(value1) && has(value1, '_exp') && isObject(value2) && has(value2, '_exp')) {
    return value1._exp === value2._exp;
  }

  return isObject(value1) && isObject(value2) ? isEqual(value1, value2) : value1 === value2;
};
