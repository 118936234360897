import { ApexToolbar } from '@apex/react-toolbar';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { env } from 'config';
import ErrorBoundary from 'components/ErrorBoundary';
import SettingsMenu from 'components/SettingsMenu';
import GlobalNavigationMenu from 'components/GlobalNavigationMenu';
import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';
import SimProgressBar from 'components/SimProgressBar';
import { useState } from 'react';

export default () => {
  const darkMode = useSelector(selectDarkMode);
  const location = useLocation();
  const [navigationIsOpen, setNavigationIsOpen] = useState(false);
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);

  const classes = classNames(
    {
      [styles.dark]: darkMode, // Used for this specific component's styles
      'dark': darkMode,          // Used for local components, since `styles.dark` is scoped to this component
      'bp4-dark': darkMode,    // Used for Blueprint components
      // Contextualized setup views have different padding due to the SetupNavigationMenu being anchored to the left side
      [styles.setupView]: /^\/setup\/\d/.test(location.pathname),
    },
    styles.content,
  );

  return (
    <div>
      <div className={styles.toolbar}>
        <ApexToolbar
          name="Setup"
          href={env === 'local' ? '/apex-setup' : '/'}
          isTesting={env === 'local'}
          onLogOut={() => {
            window.location.href = 'https://www.apex-mp.com/';
          }}
          theme={darkMode ? 'dark' : 'light'}
          iconSource={darkMode ? `${process.env.PUBLIC_URL}/apex-logo-white.png` : `${process.env.PUBLIC_URL}/apex-logo.png`}
          navigationCallback={() => setNavigationIsOpen(!navigationIsOpen)}
          settingsCallback={() => setSettingsIsOpen(!settingsIsOpen)}
        />
      </div>
      <GlobalNavigationMenu
        isOpen={navigationIsOpen}
        setIsOpen={setNavigationIsOpen}
      />
      <SettingsMenu
        isOpen={settingsIsOpen}
        setIsOpen={setSettingsIsOpen}
      />
      <main className={classes}>
        <ErrorBoundary>
          <SimProgressBar />
          <Outlet />
        </ErrorBoundary>
      </main>
    </div>
  );
};
