import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { H3, Intent } from '@blueprintjs/core';
import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table';
import _, { isBoolean, filter } from 'lodash';

import LinkButton from 'components/LinkButton';
import { useAlert } from 'components/Alert';
import PartNavigationMenu from 'components/PartNavigationMenu';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import ClickableCell from 'components/ClickableCell';
import {
  Part,
  PartConfig,
  PartConfigProperty,
  PartsFilters,
  PropertyType,
  usePartConfigByTypeNameQuery,
  usePartConfigPartsLazyQuery,
  useDeletePartMutation,
} from 'graphql/generated/graphql';
import { FilterType } from 'types';
import { baseName } from 'config';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import styles from './index.module.css';
import AppToaster from '../../../helpers/toaster';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartManagementSummaryView, tableViewSlice } from 'reducers/tableView';
import { globalDebounceTime } from '../../../constants';

interface PartPropertyFilter {
  name: string;
  bool?: boolean;
  value?: string;
  values?: string[];
  minMax?: (number | null)[];
}
type StaticPartKeys = 'part_config' | 'description' | 'part_number' | 'serial_number' | 'mileage';
type StaticPartValue = string & string[] & number[];

const STATIC_PART_COLS = ['part_config', 'description', 'part_number', 'serial_number', 'mileage'];

const getPropertyFilter = (property: PartConfigProperty) => {
  switch (property.property.type) {
    case PropertyType.BOOLEAN:
      return { type: FilterType.BOOLEAN };
    case PropertyType.NUMBER:
      return { type: FilterType.NUMBER };
    case PropertyType.STRING: {
      if (_.size(property.values) || _.size(property.property.values)) {
        const values = property.values ?? property.property.values ?? [];
        const selectItems = values.map(v => ({ label: v, value: v }));
        return {
          type: FilterType.SELECT,
          selectItems,
          multiSelect: true,
        };
      }
      return { type: FilterType.TEXT };
    }
    default: return undefined;
  }
};

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const renderDescCell = (id: number, name: string) => (
    <ClickableCell
      value={name}
      onClick={(newTab) => {
        const url = `/parts/${id}`;
        if (newTab) {
          const basePath = baseName === '/' ? '' : baseName;
          window.open(`${basePath}${url}`, '_blank');
        } else {
          navigate(url);
        }
      }}
    />
  );

  const columnHelper = createColumnHelper<Part>();
  const columns = [
    columnHelper.accessor('description', {
      header: 'Description',
      cell: info => renderDescCell(info.row.original.id, info.getValue()),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('part_number', {
      header: 'Part Number',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('serial_number', {
      header: 'Serial Number',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('exp_date', {
      header: 'Expiration Date',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('mileage', {
      header: 'Mileage',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      meta: {
        filter: {
          type: FilterType.NUMBER,
        },
      },
    }),
  ] as AccessorKeyColumnDef<Part>[];

  const [partConfig, setPartConfig] = useState<PartConfig>();
  const [tableData, setTableData] = useState<Part[]>([]);
  const [cols, setCols] = useState<AccessorKeyColumnDef<Part>[]>(columns);
  const { tableFilters, tableSorting } = useSelector(selectPartManagementSummaryView);

  useDocumentTitle(
    partConfig && partConfig.display_name ? `${partConfig.display_name}` : 'Apex Setup'
  );

  const [deletePart] = useDeletePartMutation();
  const alert = useAlert();
  usePartConfigByTypeNameQuery({
    variables: { typeName: params.configName ?? '' },
    skip: !params.configName,
    onCompleted: data => {
      if (!data.partConfig) {
        navigate('/parts/category');
      } else {
        setPartConfig(data.partConfig as PartConfig);
      }
    },
  });
  const [getParts, { data, refetch  }] = usePartConfigPartsLazyQuery({
    onCompleted: data => setTableData(data.parts.rows as Part[]),
  });

  useEffect(() => {
    if (!partConfig) return;

    const partConfigCols = partConfig.expires ? [...columns] : [...filter(columns, (c) => (c.accessorKey !== 'exp_date'))];

    setCols([
      ...partConfigCols,
      ...partConfig?.properties?.map(property => {
        const propertyFilter = getPropertyFilter(property as PartConfigProperty);
        return columnHelper.accessor(
          row => row.properties?.find(p => p.config_property.property.name === property.property.name)?.value,
          {
            id: property.property.name,
            header: property.property.display_name,
            cell: info => {
              const value = info.getValue();

              // All PartProperty values are JSON stringified; for strings, this
              // results in unwanted quotations around the value, so here we
              // parse out the value for display.
              try {
                return JSON.parse(value as string);
              } catch (e) {
                return value;
              }
            },
            enableColumnFilter: Boolean(propertyFilter),
            enableHiding: true,
            meta: {
              initiallyHidden: true,
              filter: propertyFilter,
            },
          },
        );
      }) as unknown as AccessorKeyColumnDef<Part>[] ?? [],
    ]);
  }, [partConfig]);

  useEffect(() => {
    if (data && data.parts.rows) {
      setTableData(data.parts.rows as Part[]);
    }
  }, [data]);

  const rowActions: RowActions<Part> = [
    {
      label: 'Edit',
      value: row => navigate(`/parts/${row.original.id}`),
    }, {
      label: 'Clone',
      value: row => navigate(`/parts/clone/${row.original.id}`),
    },
    {
      intent: Intent.DANGER,
      label: 'Delete',
      value: row => {
        alert.showAlert(`Delete "${row.original.description}"?`, {
          intent: Intent.DANGER,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
        }).then((yes) => {
          if (!yes) return;
          deletePart({
            variables: { id: row.original.id },
            onCompleted: () => {
              AppToaster.show({
                intent: Intent.SUCCESS,
                message: 'Part successfully deleted',
              });
              if (row.getIsSelected()) row.toggleSelected();
              refetch();
            },
            onError: e => {
              AppToaster.show({
                intent: Intent.DANGER,
                message: `Error deleting part: ${e.message}`,
              });
            },
          });
        });
      },
    }];

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    if (!partConfig) return;

    let sorts = {};
    if (sorting.length > 0) {
      const name = sorting[0].id;
      const direction = sorting[0].desc ? 'DESC' : 'ASC';

      if (!STATIC_PART_COLS.includes(sorting[0].id)) sorts = { properties: [{ name, direction }] };
      else sorts = { [name]: direction };
    }

    const getFilters = filters.reduce((acc, f) => {
      if (STATIC_PART_COLS.includes(f.id)) {
        acc[f.id as StaticPartKeys] = f.value as StaticPartValue;
      } else {
        const params: PartPropertyFilter = { name: f.id };
        if (Array.isArray(f.value)) {
          if (typeof f.value[0] === 'string') params.values = f.value;
          else params.minMax = f.value as (number | null)[];
        } else if (isBoolean(f.value)) {
          params.bool = f.value;
        } else {
          params.value = f.value as string;
        }
        acc.properties!.push(params); // eslint-disable-line @typescript-eslint/no-non-null-assertion
      }
      return acc;
    }, {
      part_config: [partConfig.id],
      properties: [],
    } as PartsFilters);

    dispatch(tableViewSlice.actions.setPartManagementSummaryView({ filters, sorting }));

    getParts({
      variables: {
        input: {
          filters: getFilters,
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
      fetchPolicy: 'no-cache',
    });
  };

  const debouncedOnTableParamsChange = _.debounce(onTableParamsChange, globalDebounceTime);

  // TODO: Show loading
  if (!partConfig) return null;

  return (
    <div className={styles.container}>
      <PartNavigationMenu />
      <div className={styles.content}>
        <div className={styles.header}>
          <H3>{partConfig.display_name}</H3>
          <LinkButton
            buttonProps={{
              icon: 'cube-add',
              intent: Intent.PRIMARY,
              text: 'Add Part',
            }}
            to={{
              pathname: '/parts/add',
              search: `?config=${partConfig.id}`,
            }}
          />
        </div>
        <Table
          columns={cols}
          data={tableData}
          enableHiding
          enablePagination
          id={`${partConfig.name}_parts`}
          manualFiltering
          manualPagination
          manualSorting
          initialColumnFilters={tableFilters}
          initialSorting={tableSorting}
          key={partConfig.name}
          onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
          persistColumnVisibility
          rowActions={rowActions}
          totalRowCount={data?.parts.totalCount}
        />
      </div>
    </div>
  );
};
