import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button, InputGroup, Overlay, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import { isNil, debounce, mapValues, keyBy, cloneDeep, find } from 'lodash';
import { format } from 'date-fns';

import { useAlert } from 'components/Alert';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import {
  useSetupSummaryLazyQuery,
  useDeleteBranchMutation,
  useCloneSetupMutation,
  SetupsQueryInput,
  useSetupFilterOptionsLazyQuery,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import { selectSetupSummaryView, tableViewSlice } from 'reducers/tableView';
import { FilterType, GQLSetup, GQLSetupHead, SelectItem, SetupBranchChild } from 'types';
import { globalDebounceTime, organizationSelectItems, seriesItems, teamSelectItems, yearItems } from '../../constants';
import { baseName } from 'config';
import Select from 'components/Select';
import ClickableCell from 'components/ClickableCell';
import styles from './index.module.css';

type SetupTableRow = GQLSetup & {
  branch: {
    id: number,
    name: string,
    created_at?: Date;
    updated_at?: Date;
  },
  numBranches?: number | null;
  subRows?: SetupTableRow[];
  childBranches?: Array<{  // Add this property
    setup: GQLSetup;
    branch: {
      id: number;
      name: string;
      created_at?: Date;
      updated_at?: Date;
    };
  }>;
}

const convertSetupData = (setup: GQLSetup, branch: { id: number; name: string, created_at?: Date, updated_at?: Date }, numBranches?: number | null): SetupTableRow => {
  return {
    ...setup,
    branch: {
      id: branch.id,
      name: branch.name,
      created_at: branch.created_at,
      updated_at: branch.updated_at,
    },
    numBranches,
  };
};

const GQLBranchHeadToSetupTableRow = (item: GQLSetupHead): SetupTableRow => {
  const { numBranches, setup, branch } = item;
  return convertSetupData(setup, branch, numBranches);
};

const GQLBranchChildToSetupTableRow = (child: SetupBranchChild): SetupTableRow => {
  const { setup, branch } = child;
  return convertSetupData(setup, branch);
};

export default () => {
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const { tableFilters, tableSorting } = useSelector(selectSetupSummaryView);
  const [cloneSource, setCloneSource] = useState<GQLSetup>();
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const cloneName = useRef<HTMLInputElement>(null);
  const [setupRows, setSetupRows] = useState<SetupTableRow[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [getSetups, { data: setupsData, loading: isLoading }] = useSetupSummaryLazyQuery();
  const [deleteBranch] = useDeleteBranchMutation();
  const [cloneSetup] = useCloneSetupMutation();
  const navigate = useNavigate();
  const alert = useAlert();
  const columnHelper = createColumnHelper<SetupTableRow>();
  const [eventFilterItems, setEventFilterItems] = useState<SelectItem<string>[]>([]);
  const [ownerFilterItems, setOwnerFilterItems] = useState<SelectItem<string>[]>([]);
  const [teamFilterItems, setTeamFilterItems] = useState<SelectItem<string>[]>([]);
  const [organizationFilterItems, setOrganizationFilterItems] = useState<SelectItem<string>[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const [getSetupFilterOptions] = useSetupFilterOptionsLazyQuery({
    onCompleted: data => {
      setEventFilterItems(data.setupFilterOptions.events.map(e => { return { label: e, value: e }; }));
      setOwnerFilterItems(data.setupFilterOptions.owners.map(o => { return { label: o, value: o }; }));
      setTeamFilterItems(data.setupFilterOptions.teams.map(t => { return { label: t, value: t }; }));
      setOrganizationFilterItems(data.setupFilterOptions.organizations.map(o => { return { label: o, value: o }; }));
    },
  });

  useEffect(() => {
    getSetupFilterOptions({ fetchPolicy: 'no-cache' });
  }, []);

  const renderNameCell = (id: number, name: string) => (
    <ClickableCell
      value={name}
      onClick={(newTab) => {
        const url = `/setup/${id}`;
        if (newTab) {
          const basePath = baseName === '/' ? '' : baseName;
          window.open(`${basePath}${url}`, '_blank');
        } else {
          navigate(url);
        }
      }}
    />
  );

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => renderNameCell(info.row.original.branch.id, info.getValue()),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('branch', {
      header: 'Branch',
      cell: info => info.getValue().name,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('organization_name', {
      header: 'Organization',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: organizationFilterItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
    }),
    columnHelper.accessor('team_name', {
      header: 'Team',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: teamFilterItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
      size: 100,
    }),
    columnHelper.accessor('series', {
      header: 'Series',
      cell: info => {
        const getSeries = find(seriesItems, series => series.value === info.getValue());
        if (!getSeries) return '';
        return getSeries.label;
      },
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: seriesItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
      size: 100,
    }),
    columnHelper.accessor('year', {
      header: 'Year',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: yearItems.map(y => ({
            label: y.label,
            value: y.value.toString(),
          })),
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
      size: 100,
    }),
    columnHelper.accessor('event', {
      header: 'Event',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: eventFilterItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
    }),
    columnHelper.accessor('description', {
      header: 'Description',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: ownerFilterItems,
          multiSelect: true,
        },
      },
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        if (info.row.original.branch.name !== 'main') {
          const { original } = info.row;
          const createdAt = original.branch.created_at ?? original.subRows?.find(b => b.branch.name === original.branch.name)?.branch.created_at;
          const value = (createdAt ?? '') as string;
          return  value ? format(new Date(value), 'MM/dd/yy HH:mm:ss') : '';
        }
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        if (info.row.original.branch.name !== 'main') {
          const { original } = info.row;
          const updatedAt = original.branch.updated_at ?? original.subRows?.find(b => b.branch.name === original.branch.name)?.branch.updated_at;
          const value = (updatedAt ?? '') as string;
          return value ? format(new Date(value), 'MM/dd/yy HH:mm:ss') : '';
        }
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<SetupTableRow>[];

  const rowActions: RowActions<SetupTableRow> = [{
    label: 'Edit',
    value: row => {
      navigate(`/setup/${row.original.branch.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      const branchId = row.original.branch?.id;
      if (!branchId) return;
      alert.showAlert(`Delete branch "${row.original.branch.name}" of "${row.original.name}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteBranch({
          variables: {
            id: branchId,
          },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Successfully deleted setup',
            });
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Failed to delete setup: ${e.message}`,
            });
          },
          refetchQueries: ['SetupSummary'],
        });
      });
    },
  }];

  useEffect(() => {
    if (!setupsData?.setupBranchHeads.rows) return;
    setIsInitialLoad(false);
    const newData = setupsData?.setupBranchHeads.rows as GQLSetupHead[];
    const translatedNewData: SetupTableRow[] = newData.map(data => {
      const row = GQLBranchHeadToSetupTableRow(data);
      if (data.childBranches?.length) {
        row.subRows = data.childBranches.map(child => GQLBranchChildToSetupTableRow(child));
      }
      return row;
    });

    setSetupRows(translatedNewData);
  }, [setupsData]);

  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleClone();
      }
    };

    if (isCloneModalOpen) {
      document.addEventListener('keydown', onEnter);
    }

    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [isCloneModalOpen]);

  const handleClone = () => {
    if (!cloneSource) return;
    cloneSetup({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
        orgName: cloneSource.organization_name,
        orgId: cloneSource.organization_id,
        teamName: cloneSource.team_name,
        teamId: cloneSource.team_id,
        series: cloneSource.series,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned setup',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone setup: ${e.message}`,
        });
      },
      refetchQueries: ['SetupSummary'],
    });
    setCloneModalOpen(false);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    const { pageIndex, pageSize } = pagination;
    const queryInput: SetupsQueryInput = {
      filters: mapValues(keyBy(filters, 'id'), 'value'),
    };
    if (sorting.length > 0) {
      queryInput.sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }
    if (!isNil(pageIndex) && !isNil(pageSize)) {
      queryInput.pagination = {
        offset: pageIndex * pageSize,
        limit: pageSize,
      };
    }

    dispatch(tableViewSlice.actions.setSetupSummaryView({ filters, sorting }));
    getSetups({ variables: { input: queryInput }, fetchPolicy: 'no-cache' });
  };
  const debouncedOnTableParamsChange = debounce(onTableParamsChange, globalDebounceTime);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectItem = (item: any, name: string) => {
    const clone = cloneDeep(cloneSource);

    if (clone) {
      if (name === 'series') {
        clone.series = item.value;
      }

      if (name === 'org') {
        clone.organization_name = item.value;
        clone.organization_id = item.id;

        // Reset team selections if org changes.
        const findTeam = find(teams, team => team.name === cloneSource?.team_name);
        if (findTeam && findTeam.organization.name !== item.value) {
          clone.team_name = null;
          clone.team_id = null;
        }
      }

      if (name === 'team') {
        clone.team_name = item.value;
        clone.team_id = item.id;
      }

      setCloneSource(clone);
    }
  };

  return (
    <div>
      <Table
        id="setup-summary"
        columns={columns}
        data={setupRows}
        rowActions={rowActions}
        enableHiding
        persistColumnVisibility
        enablePagination
        manualPagination
        manualSorting
        manualFiltering
        initialColumnFilters={tableFilters}
        initialSorting={tableSorting}
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        totalRowCount={setupsData?.setupBranchHeads.totalCount || 0}
        getRowCanExpand={(row) => {
          if (!row.original.numBranches) return false;
          return row.original.numBranches > 0;
        }}
        onExpandedChange={(expandedChanges) => {
          expandedChanges.forEach(ec => {
            if (!ec.rowData.childBranches?.length) return;

            const newSetupRows = setupRows.map(row => {
              if (row.id === ec.rowData.id) {
                return {
                  ...row,
                  subRows: row.childBranches?.map(child => GQLBranchChildToSetupTableRow(child)),
                };
              }
              return row;
            });

            setSetupRows([...newSetupRows]);
          });
        }}
        getSubRows={(row) => {
          return row.subRows || [];
        }}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <div>
            <div style={{ paddingBottom: '5px' }}>Organization</div>
            <Select
              initialItem={{ label: cloneSource?.organization_name || 'None', value: cloneSource?.organization_name || null }}
              items={organizationSelectItems(organizations)}
              noSelectionText="Organization"
              onChange={item => setSelectItem(item, 'org')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Team</div>
            <Select
              initialItem={{ label: cloneSource?.team_name || 'None', value: cloneSource?.team_name || null }}
              items={teamSelectItems(teams, cloneSource?.organization_name)}
              noSelectionText="Team"
              onChange={item => setSelectItem(item, 'team')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Series</div>
            <Select
              initialItem={seriesItems.find(i => cloneSource?.series === i.value)}
              items={seriesItems}
              noSelectionText="Series"
              onChange={item => setSelectItem(item, 'series')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Name</div>
            <InputGroup
              placeholder="Enter new setup name"
              inputRef={cloneName}
              defaultValue={`${cloneSource?.name} CLONE`}
            />
          </div>
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => handleClone()}
            />
          )}
        />
      </Dialog>
      <Overlay
        isOpen={isLoading && isInitialLoad}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </div>
  );
};
