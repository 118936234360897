import React, { useRef, useState } from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, H3, Intent } from '@blueprintjs/core';

import PartForm from 'components/PartForm';
import {
  CreatePartInput,
  PartProperty,
  useCreatePartMutation,
  Spec,
  PartConfig,
  usePartConfigsQuery,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { defaultPart, transformPartPropertyValues } from 'helpers/part';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import styles from './index.module.css';
import { find, get } from 'lodash';

interface PartFormMethods {
  getData: () => any | null; // eslint-disable-line @typescript-eslint/no-explicit-any
  getSpec: () => Spec | null;
  getOrgName: () => string | null;
  getTeamName: () => string | null;
  getSeries: () => string | null;
}

export default () => {
  useDocumentTitle('Add Part');
  const navigate = useNavigate();
  const [partConfigs, setPartConfigs] = useState<PartConfig[]>([]);
  const form = useForm<CreatePartInput>({ defaultValues: defaultPart });
  const formRef = useRef<PartFormMethods>(null);
  const [createPart] = useCreatePartMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  usePartConfigsQuery({
    onCompleted: data => setPartConfigs(data.partConfigs.rows as PartConfig[]),
  });

  const onSubmit = (input: CreatePartInput) => {
    if (!formRef.current) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Form reference is not available',
      });
      return;
    }

    const data = formRef.current.getData(); // Get data from the child form

    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);

    createPart({
      variables: {
        input: {
          ...input,
          data,
          exp_date: input.exp_date ? input.exp_date : null,
          properties: transformPartPropertyValues(input.properties as PartProperty[]),
          organization_id: get(findOrg, 'id', ''),
          team_id: get(findTeam, 'id', null),
        },
      },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Part successfully added',
        });
        if (data.part) navigate(`/parts/${data.part.id}`);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error adding part: ${e.message}`,
        });
      },
    });
  };

  // Function to read the file and parse JSON
  const readFile = (file: File) => {
    const spec = formRef?.current?.getSpec();
    const orgName = formRef?.current?.getOrgName();
    const teamName = formRef?.current?.getTeamName();
    const series = formRef?.current?.getSeries();
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const result = event.target?.result;
        if (typeof result === 'string') {
          const jsonObj = JSON.parse(result);

          if (jsonObj) {
            const partConfigId = get(find(partConfigs, (o) => (o.name === jsonObj.meta?.name)), 'id', undefined);

            form.reset({
              part_config_id: partConfigId,
              spec: spec || undefined,
              organization_name: orgName || undefined,
              team_name: teamName || null,
              series: series || undefined,
              description: jsonObj.meta?.desc || '',
              part_number: jsonObj.meta?.part_number || '',
              serial_number: jsonObj.meta?.serial_number || '',
              data: jsonObj.part,
            });
          } else {
            throw new Error('Invalid file content');
          }
        } else {
          throw new Error('File content is not a string');
        }
      } catch (error) {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'Error parsing JSON!',
        });
      }
    };
    reader.readAsText(file);
  };

  // Function to handle file selection
  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Access the file from the input event
    const file = e.target.files && e.target.files[0];
    if (file) {
      // Check if the file type is JSON
      if (file.type === 'application/json') {
        readFile(file);
      } else {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'Please upload a JSON file.',
        });
      }
    }
  };

  return (
    <div className={styles.partFormContainer}>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className={styles.headerBar}>
            <H3>Add Part</H3>
            <input
              type="file"
              onChange={handleFileInput}
              style={{ display: 'none' }}
              id="file-input"
              accept=".json"
            />
            <Button
              className={styles.saveButton}
              icon="import"
              intent={Intent.PRIMARY}
              large
              text="Import"
              onClick={() => {
                const inputFileElement = document.getElementById('file-input') as HTMLInputElement;
                if (inputFileElement) {
                  inputFileElement.click();
                }
              }}
            />
            <Button
              className={styles.saveButton}
              icon="floppy-disk"
              intent={Intent.PRIMARY}
              large
              text="Save"
              type="submit"
            />
          </div>
          <PartForm ref={formRef} />
        </form>
      </FormProvider>
    </div>
  );
};
