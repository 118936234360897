import React, { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react';
import { useFormContext, Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Alignment, Button, Card, InputGroup } from '@blueprintjs/core';
import classNames from 'classnames';
import { isNil, snakeCase, get, isEmpty, map, cloneDeep } from 'lodash';
import { getSetupDataValue, setSetupDataValue } from 'helpers/setup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectPartModel from 'components/SelectorModal/part';

import {
  SelectItem,
  RunUITemplate,
  RunUITemplateFieldItem,
  RunUITemplateItem,
  RunUITemplateItemType,
  RunUITemplateColumnItem,
  GQLSetup,
} from 'types';
import {
  RHFCheckbox,
  RHFSelect,
  RHFTextArea,
  RHFTextInput,
} from 'components/RHFInputs';
import IconTooltip from 'components/IconTooltip';
import {
  RunField,
  RunFieldPosition,
  RunFieldType,
  Run,
  useSessionsByEventLazyQuery,
  Session,
  SetupFieldType,
  SetupField,
  Part,
} from 'graphql/generated/graphql';

import styles from './index.module.css';
import Select from 'components/Select';
import LapTimeTable from './LapTimeTable';

interface ExpressionWithPositionProps {
  position: RunFieldPosition;
  referencedFields: RunField[];
  field: RunField;
  watchedValues: unknown[];
  highlightClass?: string;
  positionKey: string;
}

interface SetupFieldComponentProps {
  setupPath: string;
  formKey: string;
  fieldType: SetupFieldType;
  fieldValue: string | number;
  runIndex: number;
  control: Control;
  getValues: UseFormGetValues<any>; // eslint-disable-line
  setValue: UseFormSetValue<any>; // eslint-disable-line
  watch: UseFormWatch<any>; // eslint-disable-line
  readOnly?: boolean;
  ltReadOnly?: boolean;
  highlightClass?: string;
  placeholder?: string;
  setupField?: SetupField;
  position?: RunFieldPosition;
  handleOpenPartSelectorClick?: (field: SetupField, position?: RunFieldPosition) => void;
  getPartDescription?: (partId: number) => string;
  onSetupFieldChange?: (updatedSetup: GQLSetup, branchId: number) => void;
}

const SetupFieldComponent = ({
  setupPath,
  formKey,
  fieldType,
  fieldValue,
  runIndex,
  control,
  getValues,
  setValue,
  watch,
  readOnly,
  ltReadOnly,
  highlightClass,
  placeholder,
  setupField,
  position,
  handleOpenPartSelectorClick,
  getPartDescription,
  onSetupFieldChange,
}: SetupFieldComponentProps) => {
  const isReadOnly = readOnly || ltReadOnly;
  const setupData = watch(`runs.${runIndex}.branch.head.data`);

  useEffect(() => {
    if (!setupData) return;

    const currentSetupValue = get(setupData, setupPath, '');
    if (currentSetupValue === '') return;

    if (fieldType === SetupFieldType.FLOAT) {
      const setupStringValue = String(getSetupDataValue({ [setupPath]: currentSetupValue }, setupPath, SetupFieldType.FLOAT));
      const formValue = getValues(formKey);

      if (formValue !== setupStringValue) {
        setValue(formKey, setupStringValue, { shouldDirty: true });
      }
    } else if (fieldType === SetupFieldType.PART) {
      const setupPartId = currentSetupValue ? getSetupDataValue({ value: currentSetupValue }, 'value', SetupFieldType.PART) : -1;
      const formValue = getValues(formKey);
      const formPartId = formValue ? Number(formValue) : -1;

      if (formPartId !== setupPartId) {
        setValue(formKey, setupPartId.toString(), { shouldDirty: true });
      }
    }
  }, [setupData, setupPath, formKey, fieldType, getValues, setValue]);

  if (fieldType === SetupFieldType.FLOAT) {
    const stringValue = fieldValue !== '' ? String(getSetupDataValue({ [setupPath]: fieldValue }, setupPath, SetupFieldType.FLOAT)) : '';

    return (
      <RHFTextInput
        controllerProps={{
          control,
          name: formKey,
          defaultValue: stringValue,
          rules: {
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              const inputValue = e.target.value;
              const run = getValues(`runs.${runIndex}`);
              if (!run || !run.branch || !run.branch.head || !run.branch.head.data) return true;

              const runClone = cloneDeep(run);

              const isNumeric = /^-?\d*\.?\d+$/.test(inputValue);

              const valueToSave = isNumeric ? parseFloat(inputValue) : inputValue;

              setSetupDataValue(runClone.branch.head.data, setupPath, valueToSave, SetupFieldType.FLOAT);

              setValue(`runs.${runIndex}`, runClone, { shouldDirty: true });

              if (onSetupFieldChange && run?.branch?.head && run?.branch?.id) {
                const setupClone = cloneDeep(run.branch.head);
                setSetupDataValue(setupClone.data, setupPath, valueToSave, SetupFieldType.FLOAT);
                onSetupFieldChange(setupClone, run.branch.id);
              }

              return true;
            },
          },
        }}
        inputProps={{
          fill: true,
          placeholder,
          small: true,
          readOnly: isReadOnly,
          className: highlightClass,
        }}
      />
    );
  } else if (fieldType === SetupFieldType.PART && setupField && getPartDescription) {
    const partId = fieldValue ? getSetupDataValue({ value: fieldValue }, 'value', SetupFieldType.PART) : -1;
    const partDescription = getPartDescription(partId);

    const partSelectItem = { label: partDescription, value: partId.toString() };
    const isPositionField = setupPath.includes('_');

    return (
      <div className={styles.partSelector}>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ flex: 1 }}>
            <RHFSelect
              key={`sf_part_${setupPath}_${partId}`}
              controllerProps={{
                control,
                name: formKey,
                defaultValue: partId.toString(),
              }}
              fill
              items={[partSelectItem]}
              noSelectionText="Select Part"
              disabled
              buttonProps={{
                className: classNames(isReadOnly ? styles.customDisabled : undefined, highlightClass),
                rightIcon: undefined,
                text: partDescription,
              }}
            />
          </div>
          <Button
            onClick={() => {
              if (handleOpenPartSelectorClick) {
                if (isPositionField) {
                  const positionSetupField = {
                    ...setupField,
                    path: setupPath,
                  };
                  handleOpenPartSelectorClick(positionSetupField);
                } else {
                  handleOpenPartSelectorClick(setupField, position);
                }
              }
            }}
            disabled={isReadOnly}
            small
          >
            <FontAwesomeIcon
              icon="ellipsis-h"
            />
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

interface SetupViewProps {
  runFields: RunField[];
  runIndex: number;
  template: RunUITemplate;
  baseline?: Run;
  readOnly?: boolean;
  ltReadOnly?: boolean;
  setup?: GQLSetup;
  onSetupFieldChange?: (updatedSetup: GQLSetup, branchId: number) => void;
}

const defaultRunSessionItems = [
  { label: 'Sim', value: 'sim' },
];

const getColItems = (item: RunUITemplate | RunUITemplateColumnItem): RunUITemplateColumnItem[] => {
  return item.items.filter(i => i.type === RunUITemplateItemType.COLUMN) as RunUITemplateColumnItem[];
};

const getColumnGridStyles = (item: RunUITemplate | RunUITemplateColumnItem): CSSProperties => {
  const colItems = getColItems(item);
  return {
    gridTemplateColumns: colItems.map(i => `${i.col_span ?? 1}fr`).join(' '),
  };
};

const ExpressionWithPosition = ({
  position,
  referencedFields,
  field,
  watchedValues,
  highlightClass,
  positionKey,
}: ExpressionWithPositionProps) => {
  const result = useMemo(() => {
    const expressionWithValues = referencedFields.reduce((expr, refField, idx) => {
      const fieldId = refField.id;
      const value = watchedValues[idx]?.toString() || '';
      return expr.replace(
        new RegExp(`\\{${fieldId}\\}`, 'g'),
        value
      );
    }, field.expression || '');

    try {
      // eslint-disable-next-line no-eval
      return eval(expressionWithValues) || '';
    } catch {
      return '';
    }
  }, [watchedValues, field.expression, referencedFields]);

  return (
    <div key={positionKey} className={styles.expressionPositionContainer}>
      <div className={styles.expressionPositionLabel}>{position.label}</div>
      <InputGroup
        value={result}
        fill
        small
        readOnly
        className={classNames(highlightClass, styles.customDisabled)}
      />
    </div>
  );
};

export default (props: SetupViewProps) => {
  const { runFields, runIndex, template, baseline, readOnly, setup, ltReadOnly } = props;
  const { control, getValues, setValue, watch } = useFormContext();
  const [runSessionItems, setRunSessionItems] = useState<SelectItem<string>[]>(defaultRunSessionItems);
  const [sessionSelectLoading, setSessionSelectLoading] = useState<boolean>(false);
  const [isPartSelectorOpen, setPartSelectorOpen] = useState(false);
  const [selectorPartSF, setSelectorPartSF] = useState<SetupField>();
  const [selectedPartId, setSelectedPartId] = useState<number>();
  const [parts, setParts] = useState<Part[]>([]);

  const [getSessionsByEvent] = useSessionsByEventLazyQuery({
    onCompleted: (data) => {
      const newSessionItems = [
        ...defaultRunSessionItems,
        ...map(data.sessionsByEvent, (s: Session) => ({ label: s.name, value: s.id })),
      ];
      setRunSessionItems(newSessionItems);
      setSessionSelectLoading(false);
    },
  });

  const handleOpenPartSelectorClick = (field: SetupField, position?: RunFieldPosition) => {
    setSelectorPartSF(field);
    setPartSelectorOpen(true);

    const run = getValues(`runs.${runIndex}`);
    if (run?.branch?.head?.data) {
      const partPath = position ? getFieldPath(field.path, position) : field.path;
      const partId = partPath !== '' ? get(run.branch.head.data, partPath, -1) : -1;
      setSelectedPartId(partId !== '' ? Number(partId) : -1);
    } else {
      setSelectedPartId(-1);
    }
  };

  const getPartDescription = (partId: number): string => {
    if (partId === -1 || partId === null || partId === undefined) return 'Select Part';

    const part = parts.find(p => p.id === partId);
    if (!part) return 'Select Part';

    let desc = part.description || 'Select Part';

    if (part.part_number) {
      desc += ` / PN ${part.part_number}`;
    }

    if (part.serial_number) {
      desc += ` / SN ${part.serial_number}`;
    }

    return desc;
  };

  const onPartSelection = (selectedPart: Part, setupField: SetupField) => {
    setPartSelectorOpen(false);

    const run = getValues(`runs.${runIndex}`);
    if (!run || !run.branch || !run.branch.head || !run.branch.head.data) return;

    const runClone = cloneDeep(run);

    setSetupDataValue(runClone.branch.head.data, setupField.path, selectedPart.id, SetupFieldType.PART);

    setValue(`runs.${runIndex}`, runClone, { shouldDirty: true });

    const runFieldKey = Object.keys(getValues(`runs.${runIndex}.data`)).find(key => {
      const field = runFields.find(f => f.name === key.split('_')[0]);
      return field?.setup_field?.path === setupField.path;
    });

    if (runFieldKey) {
      const partValue = { _source: selectedPart.id };
      setValue(`runs.${runIndex}.data.${runFieldKey}`, partValue, { shouldDirty: true });
    }

    if (props.onSetupFieldChange && run?.branch?.head && run?.branch?.id) {
      const setupClone = cloneDeep(run.branch.head);
      setSetupDataValue(setupClone.data, setupField.path, selectedPart.id, SetupFieldType.PART);
      props.onSetupFieldChange(setupClone, run.branch.id);
    }

    if (!parts.some(p => p.id === selectedPart.id)) {
      setParts(prevParts => [...prevParts, selectedPart]);
    }

    setSelectedPartId(selectedPart.id);
  };

  interface PositionWithPathPart {
    label: string;
    path_part?: string | null;
    [key: string]: any; // eslint-disable-line
  }

  const getFieldPath = (path: string, position?: RunFieldPosition | PositionWithPathPart): string => {
    if (!position) return '';

    if (position && 'path_part' in position && position.path_part) {
      const pathParts = path.split('.');
      pathParts.pop();
      return [...pathParts, position.path_part].join('.');
    }

    return `${path}_${snakeCase(position.label)}`;
  };

  useEffect(() => {
    const run = getValues(`runs.${runIndex}`);
    if (run?.branch?.head?.parts && run.branch.head.parts.length > 0) {
      setParts(prevParts => {
        const newParts = run.branch.head.parts.filter((p: Part) => !prevParts.some(existingPart => existingPart.id === p.id));
        return [...prevParts, ...newParts];
      });
    }
  }, [getValues, runIndex]);

  useEffect(() => {
    if (setup && setup.event && setup.year && setup.series) {
      setSessionSelectLoading(true);
      getSessionsByEvent({
        variables: {
          year: setup.year,
          series: setup.series,
          name: setup.event,
        },
      });
    }
  }, [setup?.event, setup]);

  const runData = watch(`runs.${runIndex}.branch.head.data`);

  useEffect(() => {
    if (!runFields) return;

    const setupFieldTypes = runFields.filter(field => field.type === RunFieldType.SETUP_FIELD);

    setupFieldTypes.forEach(field => {
      if (!field.setup_field || !field.setup_field.path) return;
      if (field.setup_field.positions && field.setup_field.positions.length > 0) return;

      const key = getFieldKey(field.name, undefined);
      const currentValue = getValues(key);

      if (currentValue !== '') return;

      const run = getValues(`runs.${runIndex}`);
      if (!run || !run.branch || !run.branch.head || !run.branch.head.data) return;

      const setupValue = get(run.branch.head.data, field.setup_field.path, '');
      if (setupValue === '') return;

      setValue(key, setupValue, { shouldDirty: true });
    });
  }, [runFields, runIndex, getValues, setValue, runData]);

  const runSession = watch(`runs.${runIndex}.session`);

  const getFieldKey = (fieldName: string, position?: RunFieldPosition) => {
    const prefix = `runs.${runIndex}.data`;
    if (!position) return `${prefix}.${fieldName}`;
    return `${prefix}.${fieldName}_${snakeCase(position.label)}`;
  };

  const getBaselineValue = (fieldName: string, position?: RunFieldPosition) => {
    let baselineValue = '';
    if (!position) {
      baselineValue = get(baseline, `data.${fieldName}`, '');
    } else {
      baselineValue = get(baseline, `data.${fieldName}_${snakeCase(position.label)}`, '');
    }
    return baselineValue;
  };

  const renderRunField = (field: RunField, position?: RunFieldPosition): ReactNode => {
    const key = getFieldKey(field.name, position);
    const baselineValue: unknown = (baseline && runIndex !== 0) ? getBaselineValue(field.name, position) : undefined;
    const highlightClass = baselineValue !== undefined && baselineValue !== getValues(key) ? styles.highlight : undefined;

    switch (field.type) {
      case RunFieldType.STRING: {
        if (field.options && field.options.length > 0) {
          const options: SelectItem<string>[] = field.options.map(o => {
            return { label: o, value: o };
          });
          return (
            <RHFSelect
              key={key}
              controllerProps={{
                control,
                name: key,
              }}
              fill
              items={options}
              noSelectionText={position && `${`${position.label} `}Option`}
              disabled={readOnly}
              buttonProps={{
                className: highlightClass,
              }}
            />
          );
        }

        return (
          <RHFTextInput
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            inputProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.TEXT: {
        return (
          <RHFTextArea
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            textAreaProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.INT: {
        return (
          <RHFTextInput
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            inputProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.FLOAT: {
        return (
          // Use an InputGroup (Text Input) rather than Numeric Input to support
          // expressions
          <RHFTextInput
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            inputProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.EXPRESSION: {
        const fieldRegex = /\{(\d+)\}/g;
        const referencedIds = Array.from(field.expression?.matchAll(fieldRegex) || [])
          .map(match => parseInt(match[1], 10));

        const referencedFields = referencedIds
          .map(id => runFields.find(f => f.id === id))
          .filter((f): f is RunField => f !== undefined);

        const hasPositions = referencedFields.some(f => f.positions && f.positions.length > 0);

        if (hasPositions) {
          const allPositions = referencedFields
            .flatMap(f => f.positions || [])
            .filter((pos, index, self) => index === self.findIndex(p => p.label === pos.label));

          return (
            <div
              key={key}
              className={classNames(
                styles.expressionContainer,
                allPositions.length > 1 && styles.expressionGrid
              )}
            >
              {allPositions.map(pos => {
                const watchFields = referencedFields.map(refField => {
                  // For each referenced field, we need to watch its value at the current position
                  return `runs.${runIndex}.data.${refField.name}_${snakeCase(pos.label)}`;
                });

                const watchedValues = watch(watchFields);
                const positionKey = `${key}_${snakeCase(pos.label)}`;

                return (
                  <ExpressionWithPosition
                    key={positionKey}
                    position={pos}
                    referencedFields={referencedFields}
                    field={field}
                    watchedValues={watchedValues}
                    highlightClass={highlightClass}
                    positionKey={positionKey}
                  />
                );
              })}
            </div>
          );
        }

        const watchFields = referencedFields.map(refField => `${getFieldKey(refField.name)}`);
        const watchedValues = watch(watchFields);

        const result = useMemo(() => {
          const expressionWithValues = referencedFields.reduce((expr, refField, idx) => {
            const fieldId = refField.id;
            const value = watchedValues[idx]?.toString() || '';
            return expr.replace(
              new RegExp(`\\{${fieldId}\\}`, 'g'),
              value
            );
          }, field.expression || '');

          try {
            // eslint-disable-next-line no-eval
            return eval(expressionWithValues) || '';
          } catch {
            return '';
          }
        }, [watchedValues, field.expression]);

        return (
          <InputGroup
            value={result}
            key={key}
            fill
            small
            readOnly
            className={classNames(highlightClass, styles.customDisabled)}
          />
        );
      }
      case RunFieldType.BOOLEAN: {
        const cbHighlightClass = baselineValue !== undefined && !!baselineValue !== !!getValues(key) ? styles.highlight : undefined;
        return (
          <RHFCheckbox
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            checkboxProps={{
              alignIndicator: Alignment.RIGHT,
              label: position && position.label,
              className: classNames(styles.booleanInput, cbHighlightClass),
              disabled: readOnly,
            }}
          />
        );
      }
      case RunFieldType.SETUP_FIELD: {
        const setupField = field.setup_field;
        if (!setupField || !setupField.path) return null;

        if (setupField.positions && setupField.positions.length > 0) {
          const positionsCount = setupField.positions.length;
          const inputClass = classNames({
            [styles.inputs]: true,
            [styles.grid]: true,
            [styles[`positions${positionsCount}`]]: true,
          });

          const positionInputs = setupField.positions.map(pos => {
            const positionPath = getFieldPath(setupField.path, pos);
            if (positionPath === '') return null;

            const run = getValues(`runs.${runIndex}`);
            const setupValue = run?.branch?.head?.data
              ? get(run.branch.head.data, positionPath, '')
              : '';

            const posKey = `${key}_${snakeCase(pos.label)}`;

            let positionHighlightClass: string | undefined;
            if (baseline && runIndex !== 0) {
              const baselinePositionValue = get(baseline, `branch.head.data.${positionPath}`, '');
              const normalizedBaseline = getSetupDataValue({ value: baselinePositionValue }, 'value', SetupFieldType.PART);
              const normalizedSetup = getSetupDataValue({ value: setupValue }, 'value', SetupFieldType.PART);
              const baselineStr = String(normalizedBaseline);
              const setupStr = String(normalizedSetup);
              positionHighlightClass = baselineStr !== setupStr ? styles.highlight : undefined;
            }

            if (setupField.type === SetupFieldType.FLOAT) {
              return (
                <div key={posKey} className={styles.partInput}>
                  <SetupFieldComponent
                    setupPath={positionPath}
                    formKey={posKey}
                    fieldType={SetupFieldType.FLOAT}
                    fieldValue={setupValue}
                    runIndex={runIndex}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    readOnly={readOnly}
                    ltReadOnly={ltReadOnly}
                    highlightClass={positionHighlightClass}
                    placeholder={pos.label}
                    onSetupFieldChange={props.onSetupFieldChange}
                  />
                </div>
              );
            } else if (setupField.type === SetupFieldType.PART) {
              const partId = setupValue ? getSetupDataValue({ value: setupValue }, 'value', SetupFieldType.PART) : -1;

              if (baseline && runIndex !== 0) {
                const baselinePositionValue = get(baseline, `branch.head.data.${positionPath}`, '');
                const baselinePartId = getSetupDataValue({ value: baselinePositionValue }, 'value', SetupFieldType.PART);

                const isBaselineEmpty = baselinePartId === null || baselinePartId === 0 || baselinePartId === -1;
                const isPartEmpty = partId === null || partId === 0 || partId === -1;

                if (isBaselineEmpty && isPartEmpty) {
                  positionHighlightClass = undefined;
                } else if (isBaselineEmpty !== isPartEmpty) {
                  positionHighlightClass = styles.highlight;
                } else {
                  positionHighlightClass = baselinePartId === partId ? undefined : styles.highlight;
                }
              }

              return (
                <SetupFieldComponent
                  setupPath={positionPath}
                  formKey={posKey}
                  fieldType={SetupFieldType.PART}
                  fieldValue={setupValue}
                  runIndex={runIndex}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  watch={watch}
                  readOnly={readOnly}
                  ltReadOnly={ltReadOnly}
                  highlightClass={positionHighlightClass}
                  setupField={setupField}
                  handleOpenPartSelectorClick={handleOpenPartSelectorClick}
                  getPartDescription={getPartDescription}
                />
              );
            }

            return null;
          });

          return <div className={inputClass}>{positionInputs}</div>;
        }

        const run = getValues(`runs.${runIndex}`);
        const setupValue = run?.branch?.head?.data
          ? get(run.branch.head.data, setupField.path, '')
          : '';

        let setupHighlightClass: string | undefined;
        if (baseline && runIndex !== 0) {
          const baselineSetupValue = get(baseline, `branch.head.data.${setupField.path}`, '');
          const normalizedBaseline = getSetupDataValue({ value: baselineSetupValue }, 'value', SetupFieldType.PART);
          const normalizedSetup = getSetupDataValue({ value: setupValue }, 'value', SetupFieldType.PART);
          const baselineStr = String(normalizedBaseline);
          const setupStr = String(normalizedSetup);
          setupHighlightClass = baselineStr !== setupStr ? styles.highlight : undefined;
        }

        if (setupField.type === SetupFieldType.FLOAT) {
          return (
            <SetupFieldComponent
              setupPath={setupField.path}
              formKey={key}
              fieldType={SetupFieldType.FLOAT}
              fieldValue={setupValue}
              runIndex={runIndex}
              control={control}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              readOnly={readOnly}
              ltReadOnly={ltReadOnly}
              highlightClass={setupHighlightClass}
              placeholder={position?.label || setupField.label}
              onSetupFieldChange={props.onSetupFieldChange}
            />
          );
        } else if (setupField.type === SetupFieldType.PART) {
          const partId = setupValue ? getSetupDataValue({ value: setupValue }, 'value', SetupFieldType.PART) : -1;

          if (baseline && runIndex !== 0) {
            const baselineSetupValue = get(baseline, `branch.head.data.${setupField.path}`, '');
            const baselinePartId = getSetupDataValue({ value: baselineSetupValue }, 'value', SetupFieldType.PART);

            const isBaselineEmpty = baselinePartId === null || baselinePartId === 0 || baselinePartId === -1;
            const isPartEmpty = partId === null || partId === 0 || partId === -1;

            if (isBaselineEmpty && isPartEmpty) {
              setupHighlightClass = undefined;
            } else if (isBaselineEmpty !== isPartEmpty) {
              setupHighlightClass = styles.highlight;
            } else {
              setupHighlightClass = baselinePartId === partId ? undefined : styles.highlight;
            }
          }

          return (
            <SetupFieldComponent
              setupPath={setupField.path}
              formKey={key}
              fieldType={SetupFieldType.PART}
              fieldValue={setupValue}
              runIndex={runIndex}
              control={control}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              readOnly={readOnly}
              ltReadOnly={ltReadOnly}
              highlightClass={setupHighlightClass}
              setupField={setupField}
              position={position}
              handleOpenPartSelectorClick={handleOpenPartSelectorClick}
              getPartDescription={getPartDescription}
            />
          );
        }

        return null;
      }
      default:
        return null;
    }
  };

  const renderTemplateFieldItem = (
    item: RunUITemplateFieldItem,
    runFields: RunField[],
  ) => {
    if (item.run_field === 'lap_time_table') {
      // handle case for Lap Time Table
      return (
        <LapTimeTable
          runIndex={props.runIndex}
          setup={props.setup}
          readOnly={ltReadOnly || readOnly}
        />
      );
    }

    const field = runFields.find(f => f.name === item.run_field);
    if (!field) return null;

    let inputs: ReactNode[];
    let inputClass;
    let inputStyles: CSSProperties = {};
    // If this field has `positions`, generates one field for each, passing the
    // position to the field render function and modifies the label for relevant
    // display
    if (field.positions && field.positions.length > 0) {
      inputs = field.positions.map(pos => {
        const expandedField = {
          ...field,
          path: getFieldKey(field.name, pos),
        };
        if (field.label) expandedField.label += ` ${pos}`;
        return renderRunField(expandedField, pos);
      });
      inputClass = classNames({
        [styles.grid]: true,
        [styles[`positions${field.positions.length}`]]: isNil(item.num_columns),
      });

      if (!isNil(item.num_columns)) {
        inputStyles = {
          gridTemplateColumns: `repeat(${item.num_columns}, 1fr)`,
        };
      }
    } else {
      inputs = [renderRunField(field, undefined)];
    }

    return (
      <div key={field.name} className={styles.inputRow}>
        <div>
          <span className="bp4-ui-text">{item.label ?? field.label}</span>
          {field.tooltip && (
            <IconTooltip
              className={styles.tooltipIcon}
              content={field.tooltip}
            />
          )}
        </div>
        <div className={classNames(styles.inputs, inputClass)} style={inputStyles}>{inputs}</div>
      </div>
    );
  };

  const renderTemplateItem = (
    path: RunUITemplateItem[],
    runFields: RunField[],
  ): ReactNode => {
    const item = path[path.length - 1];
    if (item.type === RunUITemplateItemType.COLUMN) {
      return (
        <div
          className={styles.sectionContainer}
          key={JSON.stringify(path)} // TODO: Fix this junk?
          style={getColumnGridStyles(item)}
        >
          {item.items.map(innerItem => renderTemplateItem([...path, innerItem], runFields))}
        </div>
      );
    }
    return renderTemplateFieldItem(item, runFields);
  };

  return (
    <Card>
      {!isEmpty(control._defaultValues.runs)
        ? (
          <div className={styles.runHeaderRow}>
            <div className={styles.runNameInput}>
              <span>Run Name</span>
              <RHFTextInput
                key={runIndex}
                controllerProps={{
                  control,
                  name: `runs.${runIndex}.branch.name`,
                }}
                inputProps={{
                  fill: true,
                  placeholder: 'Run Name',
                  small: true,
                  readOnly,
                }}
              />
            </div>
            <div className={styles.runSessionInput}>
              <span>Session</span>
              <Select
                initialItem={runSessionItems.find(i => i.label === runSession)}
                items={runSessionItems}
                onChange={item => setValue(`runs.${runIndex}.session`, item.label, { shouldDirty: true })}
                disableTooltip
                buttonProps={{
                  className: styles.runSessionSelect,
                  fill: true,
                  loading: sessionSelectLoading,
                }}
                disabled={readOnly}
              />
            </div>
          </div>
        ) : undefined}
      <div
        className={styles.innerContainer}
        style={getColumnGridStyles(template)}
      >
        {template?.items.map(item => renderTemplateItem([item], runFields))}
      </div>

      {selectorPartSF && (
        <SelectPartModel
          key={`selected_part_${selectorPartSF?.path || ''}`}
          isOpen={isPartSelectorOpen}
          onClose={() => setPartSelectorOpen(false)}
          onSelected={onPartSelection}
          setupField={selectorPartSF}
          partId={selectedPartId}
        />
      )}
    </Card>
  );
};
