import { useState, useEffect } from 'react';
import { Button, ButtonProps, Intent, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select2, Select2Props } from '@blueprintjs/select';
import { Popover2Props, Tooltip2 } from '@blueprintjs/popover2';

import { SelectItem } from 'types';

export interface Props<T> {
  buttonProps?: ButtonProps;
  disabled?: boolean;
  // Runs the `onChange` handler but does not update the selected item
  ignoreSelection?: boolean;
  initialItem?: SelectItem<T>;
  intent?: Intent;
  items: SelectItem<T>[];
  noSelectionText?: string;
  onChange?: ((item: SelectItem<T>) => void);
  popoverProps?: Popover2Props;
  selectProps?: Partial<Select2Props<SelectItem<T>>>;
  value?: SelectItem<T>;
  fill?: boolean;
  disableTooltip?: boolean;
}

const Select = <T, >(props: Props<T>) => {
  const [selectedItem, setSelectedItem] = useState(props.initialItem);
  useEffect(() => {
    setSelectedItem(props.value);
  }, [props.value]);

  const filterItem: ItemPredicate<SelectItem<T>> = (query, item) => {
    const normalizedQuery = query.toLowerCase();
    const normalizedTitle = item.label.toLowerCase();
    return normalizedTitle.indexOf(normalizedQuery) >= 0;
  };

  const getButtonText = () => {
    return props.initialItem?.label ?? selectedItem?.label ?? props.noSelectionText ?? 'Select';
  };

  const renderItem: ItemRenderer<SelectItem<T>> = (item, { handleClick, index, modifiers }) => {
    if (!modifiers.matchesPredicate) return null;

    return (
      <MenuItem
        intent={item.intent}
        key={index}
        onClick={handleClick}
        text={item.labelElement || item.label}
      />
    );
  };

  const onItemSelect = (item: SelectItem<T>) => {
    if (!props.ignoreSelection) setSelectedItem(item);
    props.onChange?.(item);
  };

  return (
    <div>
      <Tooltip2
        content={getButtonText()}
        disabled={props.disableTooltip}
      >
        <Select2
          disabled={props.disabled}
          itemPredicate={filterItem}
          itemRenderer={renderItem}
          items={props.items}
          onItemSelect={onItemSelect}
          popoverProps={props.popoverProps}
          {...props.selectProps}
          fill={props.fill}
        >
          <Button
            disabled={props.disabled}
            intent={props.intent}
            rightIcon="double-caret-vertical"
            text={getButtonText()}
            {...props.buttonProps}
            fill={props.fill}
            style={{
              maxWidth: '155px', // Adjust this value as needed
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          />
        </Select2>
      </Tooltip2>
    </div>
  );
};

export default Select;
