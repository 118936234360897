import { useEffect, useState, useRef } from 'react';
import { Button, Checkbox, ControlGroup, FormGroup, Icon, InputGroup, Intent, NumericInput } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Column } from '@tanstack/react-table';
import classNames from 'classnames';

import MultiSelect from 'components/MultiSelect';
import Select from 'components/Select';
import { FilterType, SelectItem } from 'types';

import styles from './ColumnFilterPopover.module.css';

interface Props<TData> {
  column: Column<TData>;
}

export default <TData, TValue>(props: Props<TData>) => {
  const { column } = props;
  const { selectItems, multiSelect, type } = column.columnDef.meta?.filter ?? { type: FilterType.TEXT };
  const inputRef = useRef<HTMLInputElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [filterVal, setFilterVal] = useState<unknown>(column.getFilterValue());
  useEffect(() => {
    if (type === FilterType.NUMBER) {
      const [min, max] = filterVal as number[] ?? [];
      if (min === undefined && max === undefined) {
        column.setFilterValue(undefined);
        return;
      }
    }
    column.setFilterValue(filterVal);
  }, [filterVal]);

  // State change for acting when a filter popover is open
  useEffect(() => {
    if (isPopoverOpen) {
      // Use setTimeout to ensure input is focused after the popover content is rendered
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isPopoverOpen]);

  const getIsColumnFiltered = () => {
    switch (type) {
      case FilterType.NUMBER: {
        const [min, max] = filterVal as number[] ?? [];
        return min !== undefined || max !== undefined;
      }
      case FilterType.TEXT:
        return filterVal !== '' && filterVal !== undefined;
      case FilterType.SELECT:
        return filterVal !== undefined && (filterVal as unknown[]).length > 0;
      default:
        return filterVal !== undefined;
    }
  };

  const renderCheckbox = () => {
    return (
      <div className={styles.checkbox}>
        <FormGroup className={classNames(styles.formGroup, styles.checkboxGroup)}>
          <Checkbox
            checked={filterVal as boolean}
            className={styles.checkbox}
            onChange={() => setFilterVal(!filterVal as boolean)}
          />
        </FormGroup>
        <Button
          icon="cross"
          minimal
          onClick={() => setFilterVal(undefined)}
          title="Clear filter"
        />
      </div>
    );
  };
  const renderMultiSelect = (items: SelectItem<TValue>[]) => {
    // Existing columns could have been changed from non-multiSelect to a multiSelect and
    // there could be existing filter values from old, non-multiSelect that are not arrays.
    // Make those vals into array before processing
    let filterValArr = [];
    if (filterVal) filterValArr = Array.isArray(filterVal) ? filterVal : [filterVal];
    let selectedItems = ((filterValArr as string[])?.map(v => {
      const matchingItem = selectItems?.find(i => i.value === v);
      return matchingItem;
    }) ?? []) as SelectItem<TValue>[];
    selectedItems = selectedItems.filter(Boolean);
    return (
      <FormGroup className={classNames(styles.formGroup, styles.multiSelectGroup)}>
        <MultiSelect
          items={items}
          onChange={updatedItems => {
            setFilterVal(updatedItems.map(i => i.value));
          }}
          selectedItems={selectedItems}
          selectProps={{
            // Prevents selecting an item from dismissing this popover as well
            popoverProps: { captureDismiss: true },
          }}
        />
      </FormGroup>
    );
  };
  const renderMinMax = () => {
    const [min, max] = filterVal as number[] ?? [];
    return (
      <FormGroup
        className={classNames(styles.formGroup, styles.numberGroup)}
      >
        <ControlGroup>
          <NumericInput
            className={styles.minMaxInput}
            leftIcon="greater-than-or-equal-to"
            onValueChange={(numVal, strVal) => {
              setFilterVal([strVal === '' ? undefined : numVal, max]);
            }}
            placeholder="Min"
            rightElement={min !== undefined ? (
              <Button
                icon="cross"
                minimal
                onClick={() => setFilterVal([undefined, max])}
              />
            ) : undefined}
            value={min ?? ''}
          />
          <NumericInput
            className={styles.minMaxInput}
            leftIcon="less-than-or-equal-to"
            onValueChange={(numVal, strVal) => {
              setFilterVal([min, strVal === '' ? undefined : numVal]);
            }}
            placeholder="Max"
            rightElement={max !== undefined ? (
              <Button
                icon="cross"
                minimal
                onClick={() => setFilterVal([min, undefined])}
              />
            ) : undefined}
            value={max ?? ''}
          />
        </ControlGroup>
      </FormGroup>
    );
  };
  const renderSelect = (items: SelectItem<TValue>[]) => {
    if (multiSelect) return renderMultiSelect(items);
    return (
      <FormGroup className={classNames(styles.formGroup, styles.selectGroup)}>
        <Select
          items={items}
          onChange={item => setFilterVal(() => item.value)}
        />
      </FormGroup>
    );
  };
  const renderTextInput = () => {
    return (
      <FormGroup className={classNames(styles.formGroup, styles.textGroup)}>
        <InputGroup
          inputRef={inputRef} // Removed autoFocus, causes issue with main page lost focus routine in autoFocus
          leftIcon="search"
          onChange={evt => setFilterVal(evt.target.value)}
          placeholder={column.columnDef.header?.toString()}
          rightElement={filterVal ? (
            <Button
              icon="cross"
              minimal
              onClick={() => setFilterVal('')}
            />
          ) : undefined}
          value={filterVal as string}
        />
      </FormGroup>
    );
  };

  const renderFilterType = () => {
    switch (type) {
      case FilterType.BOOLEAN:
        return renderCheckbox();
      case FilterType.NUMBER:
        return renderMinMax();
      case FilterType.SELECT:
        return renderSelect(selectItems as SelectItem<TValue>[]);
      default:
        return renderTextInput();
    }
  };

  return (
    <Popover2
      content={renderFilterType()}
      isOpen={isPopoverOpen}
      onInteraction={(nextOpenState) => setIsPopoverOpen(nextOpenState)} // Track popover open/close state
    >
      <Button
        icon={<Icon icon="filter" size={14} />}
        intent={getIsColumnFiltered() ? Intent.SUCCESS : Intent.NONE}
        minimal
        small
        onClick={() => setIsPopoverOpen(true)}
      />
    </Popover2>
  );
};
